import React, { useEffect, useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Box,
  Tab,
  Tabs,
  Typography,
  CircularProgress
} from "@mui/material";
import IssueDetailHeader from "./IssueDetailHeader/IssueDetailHeader";
import CustomTabPanel from '../../components/CustomTabPanel';
import IssueDetailProfile from "./IssueDetailProfile/IssueDetailProfile";
import IssueDetailService from "./IssueDetailService/IssueDetailService";
import { useRole } from '../../context/RoleContext';
import { hasPageAccess } from '../../utils/permissions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AlertDetailHistory from "./AlertsDetailHistory/AlertsDetailHistory";
import { track } from "@amplitude/analytics-browser";
import customerService from "../../services/customerService";
import { GlobalStateContext } from '../../context/GlobalStateContext';

//styles
import { styles } from "./DrawerStyles.style";
import { useLocation } from "react-router-dom";

const IssuesDrawer = ({
  state,
  handleDrawerClose,
  handleStatusChange,
  setState,
  getAllJobStatus,
  isCustomers,
  setIsApiError,
  selectedFilters,
  customerName,
  vehModelYear,
  vin,
  vehMake,
  vehModel,
  vehicleProfileId
}) => {
  const [value, setValue] = useState(1);
  const [profileData, setProfileData] = useState(null);
  const [connectedVehicleData, setConnectedVehicleData] = useState(null);
  const [rewardsData, setRewardsData] = useState(null);
  const [isProfileDataLoaded, setIsProfileDataLoaded] = useState(false);
  const [isLoadingAlerts, setIsLoadingAlerts] = useState(false);
  const { roleConfig } = useRole();
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setCustomerDetails, customerDetails, setCustomers } = useContext(GlobalStateContext);

  const fetchAlertsData = useCallback(async () => {
    const { alertId, vehicleProfileId, isFromCustomerList } = state;
    if (!alertId && !vehicleProfileId) return;
  
    const updateCustomerAlerts = (customers, alertsData, alertId) => {
      return customers.map(customer => ({
        ...customer,
        alerts: updateAlerts(customer.alerts, alertsData, alertId)
      }));
    };
  
    const updateAlerts = (alerts, alertsData, alertId) => {
      return alerts.map(alert => {
        if (alert.id === alertId) {
          const updatedAlert = findUpdatedAlert(alertsData, alertId);
          return { ...alert, status: updatedAlert ? updatedAlert.status : alert.status };
        }
        return alert;
      });
    };
  
    const findUpdatedAlert = (alertsData, alertId) => {
      return (
        alertsData.activeAlerts.find(a => a.id === alertId) ||
        alertsData.inActiveAlerts.find(a => a.id === alertId)
      );
    };
  
    const handleApiError = (error) => {
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response ? error.response.status : null,
      });
    };
  
    try {
      setIsLoadingAlerts(true); // start loading
      const id = isFromCustomerList ? alertId : vehicleProfileId;
      const alerts = await customerService.getAlertsDetails(id, isFromCustomerList);
      setCustomerDetails(alerts.data);
  
      setCustomers(prevCustomers => updateCustomerAlerts(prevCustomers, alerts.data, alertId));
  
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoadingAlerts(false); // End loading
    }
  }, [state.alertId, state.vehicleProfileId, state.isFromCustomerList]);  
  
  const fetchProfileData = useCallback(async () => {
    if (!vehicleProfileId || isProfileDataLoaded) return; // Check if data is already loaded
    try {
      const [profile, connectedVehicle, rewards] = await Promise.all([
        customerService.getProfileDetails(vehicleProfileId),
        customerService.getConnectedVehicleInfo(vehicleProfileId),
        customerService.getFordPassRewards(vehicleProfileId),
      ]);

      setProfileData(profile.data);
      setConnectedVehicleData(connectedVehicle.data);
      setRewardsData(rewards.data);
      setIsProfileDataLoaded(true); // Set flag to true after data is loaded
    } catch (error) {
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response ? error.response.status : null,
      });
    }
  }, [vehicleProfileId, isProfileDataLoaded, setIsApiError]);

  useEffect(() => {
    let defaultValue;
    if (location.pathname === '/') {
      defaultValue = 1;
    } else if (location.pathname === '/all-customers') {
      defaultValue = 0;
    } else {
      defaultValue = 1; // This handles any other paths
    }

    if (state.drawerOpen && !state.isIssuesLoaded) {
      const page = getPageNameFromPageIndex(defaultValue);
      track('User Page Viewed', { page: `Customer Detail - ${page}` });
      setValue(defaultValue);

      fetchAlertsData();
      if(defaultValue === 0) {
        fetchProfileData();
      }
      setIsProfileDataLoaded(false); // Reset flag when drawer is opened
      setState(prev => ({ ...prev, isIssuesLoaded: true }));
    }
  }, [state.drawerOpen, state.isIssuesLoaded, fetchAlertsData, setState]);

  const handleDrawerCloseWithReset = () => {
    setCustomerDetails(null);
    setProfileData(null);
    setConnectedVehicleData(null);
    setRewardsData(null);
    setIsProfileDataLoaded(false); // Reset flag when drawer is closed

    // Update the state to close the drawer
    setState(prev => ({
      ...prev,
      drawerOpen: false, // Ensure this is set to false to close the drawer
      isIssuesLoaded: false // Reset any other relevant state
    }));

    handleDrawerClose();
  };

  const getPageNameFromPageIndex = (newValue) => {
    switch (newValue) {
      case 0: return "Profile";
      case 1: return "Active Alerts";
      case 2: return "Alert History";
      default: return "Active Alerts";
    }
  };

  const handleChange = (event, newValue) => {
    const page = getPageNameFromPageIndex(newValue);
    track(`Customer Detail ${page} Viewed`);
    setValue(newValue);
    if (newValue === 0) { // Profile tab
      fetchProfileData();
    }
  };

  if (!hasPageAccess(roleConfig, 'alert_detail', 'read')) {
    return (
      <Drawer
        anchor="right"
        open={state?.drawerOpen}
        onClose={handleDrawerCloseWithReset}
        data-testid="issues-drawer"
      >
        <Box sx={{ width: isMobile ? '100vw' : 800, padding: 2 }}>
          <Typography variant="h6">Permission Denied</Typography>
        </Box>
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="right"
      open={state?.drawerOpen}
      onClose={handleDrawerCloseWithReset}
      data-testid="issues-drawer"
    >
      <Box sx={{ width: isMobile ? '100vw' : 800, marginTop: "10px" }} role="presentation">
        <IssueDetailHeader
          headingStyle={styles.subHeading}
          handleDrawerClose={handleDrawerCloseWithReset}
          customerName={customerName}
          vehModelYear={vehModelYear}
          vehMake={vehMake}
          vin={vin}
          vehModel={vehModel}
        />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              TabIndicatorProps={{ sx: { backgroundColor: "#0562D2", border: "2px solid #0562D2" } }}
              sx={{ marginLeft: "40px" }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab iconPosition="start" sx={styles.tabHighlight} label="Profile" />
              <Tab iconPosition="start" sx={styles.tabHighlight} label="Active Alerts" />
              <Tab iconPosition="start" sx={styles.tabHighlight} label="Alert History" />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <IssueDetailProfile
              setIsApiError={setIsApiError}
              customerDetails={profileData}
              rewardsData={rewardsData}
              connectedVehicleData={connectedVehicleData}
              vehMake={vehMake}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            {state.isIssuesLoaded ? (
              <IssueDetailService
                handleStatusChange={handleStatusChange}
                state={state}
                setState={setState}
                getAllJobStatus={getAllJobStatus}
                isCustomers={isCustomers}
                setIsApiError={setIsApiError}
                selectedFilters={selectedFilters}
                alertsData={customerDetails}
                vehicleProfileId={vehicleProfileId}
                isLoading={isLoadingAlerts}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                mt={10}
              >
                <CircularProgress />
              </Box>
            )}
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            {state.isIssuesLoaded ? (
              <AlertDetailHistory
                handleStatusChange={handleStatusChange}
                state={state}
                setState={setState}
                getAllJobStatus={getAllJobStatus}
                isCustomers={isCustomers}
                setIsApiError={setIsApiError}
                selectedFilters={selectedFilters}
                alertsData={customerDetails}
                vehicleProfileId={vehicleProfileId}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                mt={10}
              >
                <CircularProgress />
              </Box>
            )}
          </CustomTabPanel>
        </Box>
      </Box>
    </Drawer>
  );
}

IssuesDrawer.propTypes = {
  state: PropTypes.object,
  handleDrawerClose: PropTypes.func,
  handleStatusChange: PropTypes.func,
  setState: PropTypes.func,
  getAllJobStatus: PropTypes.func,
  isCustomers: PropTypes.bool,
  setIsApiError: PropTypes.func,
  selectedFilters: PropTypes.object,
  customerDetails: PropTypes.object,
  customerName: PropTypes.string,
  vehMake: PropTypes.string,
  vehModelYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vin: PropTypes.string,
  vehModel: PropTypes.string,
  vehicleProfileId: PropTypes.number
};

export default IssuesDrawer;
