// src/utils/roleUtils.js

import { getToken, parseJwt } from './auth';
import { getImpersonateData } from './impersonateUtils';

export const getRoleAndSiteCode = () => {
  const token = getToken();
  if (!token) {
    console.warn('Token is not available');
    return { role: '', siteCode: '', currentRole: '', currentSiteCode: '' }; // Default values if token is missing
  }

  const parsedToken = parseJwt(token);
  if (!parsedToken) {
    console.warn('Failed to parse token');
    return { role: '', siteCode: '', currentRole: '', currentSiteCode: '' }; // Default values if parsing fails
  }

  const fordUserType = parsedToken['fordUserType'];
  const fordSiteCode = parsedToken['fordSiteCode'];
  /** first need to check for ford user type not equal to dealer, and then dls-entitlement is {} not (fordUserType === 'Dealer' && (!dlsEntitlements || dlsEntitlements === '{}')) */
  const dlsEntitlements = parsedToken['DLS-Entitlements'];

  // Check if fordUserType is not 'Dealer' and dlsEntitlements is '{}'
  if (fordUserType !== 'Dealer' && dlsEntitlements === '{}' && window.location.pathname !== '/no-access') {
    console.warn('Not authorised as you do not have access to the application');
    /* Not authorised as you do not have access to the application */
    window.location.href = '/no-access'; // Default values if no access
  }

  if (isDealerWithEmptyEntitlements(fordUserType, dlsEntitlements)) {
    return handleDealerWithEmptyEntitlements(fordSiteCode);
  }

  let role = '';
  let siteCode = ''; // Default value
  let currentRole = '';
  let currentSiteCode = '';

  const entitlements = JSON.parse(dlsEntitlements);
  const roleAssignments = entitlements.RoleAssignments || [];

  const carePlusRoles = roleAssignments
    .filter((assignment) => assignment.EntityName === 'CarePlus')
    .map((assignment) => {
      const dataAttributes = assignment.DataAttributes || [];
      dataAttributes.forEach((attribute) => {
        if (attribute.AttributeName === 'SiteCode') {
          siteCode = attribute.AttributeValues[0] ;
          currentSiteCode = siteCode;
        }
      });
      return assignment.RoleName;
    });

  if (carePlusRoles.length > 0) {
    role = carePlusRoles[0];
    currentRole = roleAssignments[0].RoleName;
  }

  const impersonateData = JSON.parse(getImpersonateData());
  if (impersonateData && impersonateData.isDealerImpersonate) {
    role = impersonateData.role;
    siteCode = impersonateData.siteCode;
    currentRole = roleAssignments[0].RoleName;
  }

  return { role, siteCode, currentRole, currentSiteCode };
};

const isDealerWithEmptyEntitlements = (fordUserType, dlsEntitlements) => 
  fordUserType === 'Dealer' && (!dlsEntitlements || dlsEntitlements === '{}');


const handleDealerWithEmptyEntitlements = (fordSiteCode) => {
  if (!fordSiteCode || fordSiteCode.split(',').length > 1) {
    console.warn("Can't determine dealership due to multiple or missing fordSiteCode");
    /* Not authorised as multiple site codes are mapped to you */
    window.location.href = '/cannot-determine-your-dealership';
  }
  return { 
    role: 'Remote Care Assistant Global', 
    siteCode: fordSiteCode, 
    currentRole: 'Remote Care Assistant Global', 
    currentSiteCode: fordSiteCode 
  };
};