// src/containers/Auth/UnauthorizedPage.jsx
import React from 'react';
import Cookies from 'js-cookie';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getIdToken, clearTokens, isValidUrl } from '../../utils/auth';
import DOMPurify from 'dompurify';
import { ACCESS_TOKEN, EXPIRES_IN, ID_TOKEN } from '../../utils/constants';

function UnauthorizedPage() {
  const navigate = useNavigate();
  const logoutURL = process.env.REACT_APP_SIGNOUT_URL;
  // Validate window.location.origin
  const logoutParams = new URLSearchParams({
    id_token_hint: encodeURIComponent(getIdToken())
}).toString();

  const handleLogout = () => {
    clearTokens();
    localStorage.clear();
    Cookies.remove(ACCESS_TOKEN);
    Cookies.remove(ID_TOKEN);
    Cookies.remove(EXPIRES_IN);

    // Redirect to ADFS front-channel logout endpoint
    if (isValidUrl(logoutURL)) {
      // Sanitize the URL using DOMPurify
      const sanitizedUrl = DOMPurify.sanitize(`${logoutURL}?${logoutParams}`);
      window.location.href = sanitizedUrl;
    } else {
      console.error('Invalid OAuth issuer Logout URL');
    }
  };

  const handleGoBack = () => {
    navigate('/login');
  };

  return (
    <Container component="main" sx={styles.container}>
      <Typography component="h1" variant="h5" sx={styles.title}>
        401 Unauthorized
      </Typography>
      <Typography variant="body1" sx={styles.message}>
        You do not have the necessary permissions to access this page.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
        sx={styles.button}
      >
        Go Back to Login
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleLogout}
        sx={styles.button}
      >
        Logout
      </Button>
    </Container>
  );
}

/** @type {import("@mui/material").SxProps} */
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  title: {
    marginBottom: 2,
  },
  message: {
    marginBottom: 4,
  },
  button: {
    marginTop: 2,
    color: '#fff'
  },
};

export default UnauthorizedPage;
