import React, { useEffect, useState } from 'react';
import { Box, Grid, Autocomplete, TextField, InputAdornment } from '@mui/material';

import DonutChart from './charts/DonutChart';
import issueService from '../../services/issueService';
import ErrorPage from '../../components/ErrorPage';
import NumberMetricsCard from './NumberMetricsCard/NumberMetricsCard';
import ChartMetricsCard from './ChartMetricsCard/ChartMetricsCard';

import { Icon, Typography } from '@ford/ford-ui-components'; // Adjust the import path as necessary
import { getRoleAndSiteCode } from '../../utils/roleUtils';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { track } from '@amplitude/analytics-browser';

const Metrics = () => {
  const { role, siteCode } = getRoleAndSiteCode();

  const [alerts, setAlerts] = useState({});
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [loadingType, setLoadingType] = useState(true);
  const [open, setOpen] = useState(false);
  const [siteCodes, setSiteCodes] = useState((role === 'RCAuser' || role === 'Remote Care Assistant' || siteCode !== "") ? [siteCode] : []);
  const [siteCodeOptions, setSiteCodeOptions] = useState([]);
  const [isApiError, setIsApiError] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    fetchSiteCodes();
  }, []);

  useEffect(() => {
    track('User Page Viewed', { page: 'Metrics' });
  }, []);

  useEffect(() => {
    fetchMetrics();
  }, [siteCodes]);

  const fetchSiteCodes = async () => {
    try {
      const siteCodeData = await issueService.getAllSiteCodes();
      const options = siteCodeData.data[1]?.siteCodeList.map((code) => ({
        label: code,
        value: code,
      }));
      setSiteCodeOptions(options || []);
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchMetrics = () => {
    setLoadingStatus(true);
    setLoadingType(true);

    issueService.getAllMetrics(siteCodes).then((d) => {
      setAlerts(d?.data);
      setLoadingStatus(false);
      setLoadingType(false);
    }).catch(handleApiError);
  };

  const handleApiError = (error) => {
    console.error('Error:', error);
    setIsApiError({
      isError: true,
      message: error.message,
      code: error.response.status
    });
  };

  const handleFilterChange = (event, value) => {
    if (!siteCodes.includes(value.value)) {
      setSiteCodes((prev) => [...prev, value.value]);
    }
    setInputValue('');
  };

  const handleRemoveSiteCode = (code) => {
    setSiteCodes((prev) => prev.filter((siteCode) => siteCode !== code));
  };

  const toggleDropdown = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  if (isApiError) {
    return (
      <ErrorPage isApiError={isApiError} />
    );
  }

  return (
    <div style={{ marginTop: "20px", marginBottom: "50px" }}>
      <Box sx={{ flex: 1, alignItems: "self-start", width: "320px", padding: "0 30px" }}>
        <Box sx={{ padding: "20px 0" }}>
          <Typography displayStyle='display-3-small-semibold' displayColor='text-onlight-moderate-default'>
            Metrics
          </Typography>
        </Box>
        {(role === "InternalAdmin" || role === "CarePlus Internal Admin" || siteCode === "") && (
          <Autocomplete
            sx={{
              "& .MuiInputBase-root": { height: "40px", backgroundColor: 'white', borderRadius: "8px", width: "280px" }, //update the styles here
            }}
            options={siteCodeOptions}
            getOptionLabel={(option) => option.label}
            onChange={handleFilterChange}
            value={null} // Ensure the selected value isn't displayed
            inputValue={inputValue} // Bind the input value
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            open={open}
            onOpen={() => setOpen(true)} // Set open to true when dropdown opens
            onClose={() => setOpen(false)}
            renderInput={(params) => <TextField {...params} placeholder="Select Site Code"
              InputProps={{
                ...params.InputProps, endAdornment: (
                  <InputAdornment position="end" style={{ marginRight: "-30px", cursor: "pointer" }} onClick={toggleDropdown}>
                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </InputAdornment>
                )
              }}
            />}
          />
        )}

      </Box>
      <Box sx={{ padding: "0 30px" }}>
        {(role === "InternalAdmin" || role === "CarePlus Internal Admin" || siteCode === "") && (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px', marginTop: "12px" }}>
            {siteCodes.map((code) => (
              <div
                key={code}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#e0e0e0',
                  padding: '5px 10px',
                  borderRadius: '16px',
                }}
              >
                <span>{code}</span>
                <span
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    border: '2px solid white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    marginLeft: "6px"
                  }}
                  onClick={() => handleRemoveSiteCode(code)}
                >
                  <Icon name="close" />
                </span>
              </div>
            ))}
          </div>
        )}
      </Box>
      <Grid container spacing={2} sx={{ padding: "0 30px" }}>
        <Grid item xs={12} sm={6}>
          <NumberMetricsCard
            title="Went from Open to first status change"
            loading={loadingType}
            time={alerts?.firstStatusChangeMetrics}
            isReverse={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberMetricsCard
            title="Alerts were completed"
            loading={loadingType}
            time={alerts?.alertCompletionMetrics}
            isReverse={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberMetricsCard
            title="Average time of Open to first status change"
            loading={loadingType}
            time={alerts?.firstStatusChangeMetrics}
            isReverse={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberMetricsCard
            title="Average time to complete alert"
            loading={loadingType}
            time={alerts?.alertCompletionMetrics}
            isReverse={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ChartMetricsCard
            title="Active Alerts by Status"
            loading={loadingStatus}
            data={alerts?.statusMetrics}
            chartComponent={DonutChart}
            totalAlerts={alerts?.totalActiveAlertsByStatus}
            colorMapping={{
              Open: '#0562D2',
              Unreachable: '#B16201',
              Contacted: '#044EA7',
              Scheduled: '#00095B',
              'In Progress': '#E5F3E6',
              'On Hold': '#E5E5E5',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ChartMetricsCard
            title="Active Alerts by Type"
            loading={loadingType}
            data={alerts?.alertCategoryMetrics}
            chartComponent={DonutChart}
            totalAlerts={alerts?.totalActiveAlertsByAlertCategory}
            colorMapping={{
              VHA: '#0562D2',
              Prognostics: '#044EA7',
              FSA: '#00095B',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Metrics;
