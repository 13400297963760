import React, { useState, useEffect, useCallback, useMemo } from 'react';
import CustomPagination from '../../components/CustomPagination';
import allCustomerService from '../../services/allCustomerService';
import IssuesDrawer from '../IssueDetail/Drawer';
import ErrorPage from '../../components/ErrorPage';
import { hasActionAccess } from '../../utils/permissions'
import { Typography } from '@ford/ford-ui-components';
import { Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@mui/material';
import { ALL_CUSTOMER_TITLE, CUSTOMER_COLUMN_HEADERS } from './constants';
import { track } from '@amplitude/analytics-browser';
import { useRole } from '../../context/RoleContext';
import AllCustomersExport from './AllCustomersExport';
import {styles} from './AllCustomers.style';

const AllCustomers = () => {
    const [allCustomers, setAllCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isApiError, setIsApiError] = useState(null);
    const [page, setPage] = useState(() => {
        const savedPage = localStorage.getItem('allCustomerPage');
        return savedPage !== null ? JSON.parse(savedPage) : 0;
    });
    const [rowsPerPage, setRowsPerPage] = useState(() => {
        const savedRowsPerPage = localStorage.getItem('allCustomerRowsPerPage');
        return savedRowsPerPage !== null ? JSON.parse(savedRowsPerPage) : 10;
    });
    const [totalItems, setTotalItems] = useState(0);
    const [state, setState] = useState({
        drawerOpen: false,
        isIssuesLoaded: false,
        statusUpdatePopUp: false,
        snackbarMessage: '',
        autoHideStatus: null,
        snackbarSeverity: 'success',
        originalStatus: ''
    });

    const { roleConfig } = useRole();

    useEffect(() => {
        const fetchAllCustomers = async () => {
            try {
                setLoading(true);
                setTotalItems(0);
                const response = await allCustomerService.getAllCustomers(page + 1, rowsPerPage, [], {
                    defaultSort: true
                });
                const totalItems = response.data.pagination.totalCustomers;
                const totalPages = Math.ceil(totalItems / rowsPerPage);

                if (page >= totalPages) {
                    setPage(0);
                } else {
                    setAllCustomers(response.data);
                    setTotalItems(totalItems);
                }
            } catch (error) {
                setIsApiError({
                    isError: true,
                    message: error?.message,
                    code: error?.response?.status
                })
            } finally {
                setLoading(false);
            }
        }
        fetchAllCustomers();
    }, [page, rowsPerPage, setPage]);

    useEffect(() => {
        track('User Page Viewed', { page: 'All Customers' });
    }, [])

    const columnHeaders = [
        { label: CUSTOMER_COLUMN_HEADERS.customer, width: '20%' },
        { label: CUSTOMER_COLUMN_HEADERS.email, width: '20%' },
        { label: CUSTOMER_COLUMN_HEADERS.vin, width: '20%' },
        { label: CUSTOMER_COLUMN_HEADERS.make, width: '15%' },
        { label: CUSTOMER_COLUMN_HEADERS.model, width: '15%' },
        { label: CUSTOMER_COLUMN_HEADERS.year, width: '15%' }
    ]

    useEffect(() => {
        localStorage.setItem('allCustomerPage', JSON.stringify(page));
        localStorage.setItem('allCustomerRowsPerPage', JSON.stringify(rowsPerPage));
    }, [page, rowsPerPage]);

    const handlePageChange = useCallback((_, newPage) => {
        track('All Customers Pagination Arrow Clicked', { direction: newPage > page ? '>' : '<', pageNumber: newPage + 1 });
        setPage(newPage);
    }, [page]);

    const handleDrawerClose = useCallback(() => {
        setState(prev => ({ ...prev, drawerOpen: false, isIssuesLoaded: false }));
    }, []);

    const handleRowsPerPageChange = useCallback((event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        track('All Customer Per Page Clicked', { selectedEntries: newRowsPerPage });
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    }, []);

    const handleDetailModel = useCallback((customer) => {
        if (!hasActionAccess(roleConfig, 'list_alert', 'view_alert_detail')) {
            alert('Permission Denied');
            return;
        }
        // Pass alertId directly to the drawer
        setState(prev => ({
            ...prev,
            drawerOpen: true,
            isIssuesLoaded: false,
            customerName: customer?.customerName,
            vehModelYear: customer?.vehicleYear,
            vin: customer.vin,
            vehMake: customer.vehicleMake,
            vehModel: customer.vehicleModel,
            vehicleProfileId: customer.vehicleProfileId,
            isFromCustomerList: false
        }));
    }, [roleConfig]);

    const renderLoader = useMemo(() => (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
                position: 'fixed',
                top: "50%",
                left: "50%",
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                zIndex: 1000
            }}
        >
            <CircularProgress />
        </Box>
    ), []);

    if (isApiError) {
        return <ErrorPage isApiError={isApiError} />;
    }

    return (
        <div className='all-customers-container'>
            {/** Header */}
            <div className='all-customers-header'>
                <Typography
                    displayColor="text-onlight-moderate-default"
                    displayStyle="display-3-small-semibold"
                >
                    {ALL_CUSTOMER_TITLE}
                </Typography>
                <span className='all-customers-header-count'>
                    <Typography
                        displayColor="text-onlight-moderate-default"
                        displayStyle="display-3-small-regular"
                    >
                        {totalItems > 0 && `(${totalItems})`}
                    </Typography>
                </span>
            </div>
            {/** Header */}
            <div className='all-customers-filters' style={styles.allCustomersFilters}>
                <div className='all-customers-search'></div>
                <AllCustomersExport loading={loading} />
            </div>

            {/** Table */}
            <TableContainer
                component={Paper}
                sx={{
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderTop: "1px solid #f6f3f3",
                    marginTop: '2rem'
                }}
            >
                {loading && renderLoader}
                <Table>
                    <TableHead>
                        <TableRow>
                            {columnHeaders.map((column) => (
                                <TableCell sx={{
                                    width: column.width,
                                    fontSize: "14px",
                                    fontFamily: "ford f1 bold",
                                }} key={column.label}>
                                    <Typography
                                        displayColor="text-onlight-subtle"
                                        displayStyle="body-2-bold"
                                    >
                                        {column.label}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && allCustomers?.customers.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={columnHeaders.length} sx={{ padding: "20px", textAlign: "center" }}>
                                    <Typography variant="body1" sx={{ color: "#666666" }}>
                                        No customer found.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {!loading && allCustomers?.customers?.map((customer, index) => {
                            const isOdd = index % 2 == 0;
                            return (
                                <TableRow sx={{ cursor: 'pointer', '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)'
                                }}} onClick={() => handleDetailModel(customer)} key={customer?.vehicleProfileId} className={isOdd ? 'odd-all-customer-row' : ''}>
                                    <TableCell>{customer?.customerName}</TableCell>
                                    <TableCell>{customer?.emailAddress}</TableCell>
                                    <TableCell>{customer?.vin}</TableCell>
                                    <TableCell>{customer?.vehicleMake}</TableCell>
                                    <TableCell>{customer?.vehicleModel}</TableCell>
                                    <TableCell>{customer?.vehicleYear}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                {/** Footer */}
                {!loading && (
                    <CustomPagination
                        count={allCustomers?.pagination?.totalItems}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                )}
                {/** Footer */}
            </TableContainer>
            <IssuesDrawer
                state={state}
                setState={setState}
                handleDrawerClose={handleDrawerClose}
                isCustomers={true}
                setIsApiError={setIsApiError}
                customerName={state.customerName}
                vehModelYear={state.vehModelYear}
                vehMake={state.vehMake}
                vin={state.vin}
                vehModel={state.vehModel}
                vehicleProfileId={state.vehicleProfileId}
            />
        </div>
    );
}

export default AllCustomers;