import React, { useState, useEffect } from 'react';
import { AppBar, Box, IconButton, Toolbar, Button, Typography, Drawer, List, ListItem, ListItemText } from "@mui/material";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import DOMPurify from 'dompurify';
import PopupDialog from './PopupDialog';
import { getToken, getIdToken, clearTokens, parseJwt, isValidUrl, isTokenValid } from '../utils/auth';
import { getUserName } from '../config/AuthenticatedRoute';
import { useRole } from '../context/RoleContext';
import { hasPageAccess } from '../utils/permissions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function AppHeader() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [localTimeZone, setLocalTimeZone] = useState('');
    const [utcOffset, setUtcOffset] = useState('');
    const [username, setUsername] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const logoutURL = process.env.REACT_APP_SIGNOUT_URL;
    const logoutParams = new URLSearchParams({
        id_token_hint: encodeURIComponent(getIdToken())
    }).toString();
    const { roleConfig } = useRole();

    const handlePopupSelect = (option) => {
        setPopupOpen(false);
    };

    useEffect(() => {
        const handleUnauthorized = () => {
            navigate('/unauthorized');
        };

        const getTokensAndUser = () => {
            const token = getIdToken();
            if (!token) return null;
            const username = getUserName();
            setUsername(username);
            const accessToken = getToken();
            const parsedToken = parseJwt(accessToken);
            return parsedToken['DLS-Entitlements'];
        };

        const checkRoleAssignments = (dlsEntitlements) => {
            if (!dlsEntitlements) {
                handleUnauthorized();
            }
            const entitlements = JSON.parse(dlsEntitlements);
            const roleAssignments = entitlements.RoleAssignments || [];
            const carePlusRoles = roleAssignments
                .filter(assignment => assignment.EntityName === 'CarePlus')
                .map(assignment => assignment.RoleName);

            if (carePlusRoles.length < 1) {
                handleUnauthorized();
            }
        };

        const validateUser = async () => {
            const valid = await isTokenValid();
            if (!valid) {
                handleUnauthorized();
                return;
            }

            const dlsEntitlements = getTokensAndUser();
            if (dlsEntitlements !== null) {
                checkRoleAssignments(dlsEntitlements);
            } else {
                handleUnauthorized();
            }
        };

        validateUser();
    }, [navigate]);

    useEffect(() => {
        const fetchTimeZone = () => {
            const timeZone = moment.tz.guess();
            localStorage.setItem('timezone', timeZone);
            const offset = moment().tz(timeZone).format('Z');
            const offsetHours = offset.includes(':') ? offset.replace(':', 'h ') + 'm' : offset + 'h';

            setLocalTimeZone(timeZone);
            setUtcOffset(offsetHours);
        };

        fetchTimeZone();
    }, []);

    const handleLogout = () => {
        clearTokens();
        localStorage.clear();
        setUsername('');

        if (isValidUrl(logoutURL)) {
            const sanitizedUrl = DOMPurify.sanitize(`${logoutURL}?${logoutParams}`);
            window.location.href = sanitizedUrl;
        } else {
            console.error('Invalid OAuth issuer Logout URL');
        }
    };

    const isActive = (path) => location.pathname === path;

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const drawerContent = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <Typography variant="h6" component="div" sx={{ textAlign: 'center', mt: 2 }}>Hello, {username}</Typography>
            <Box sx={{ textAlign: 'center', mt: 1 }}>{localTimeZone} (UTC {utcOffset})</Box>
            <Button sx={styles.logoutButton} onClick={handleLogout}>Log Out</Button>
            <List>
                {hasPageAccess(roleConfig, 'list_customer', 'read') ? (
                    <ListItem button onClick={() => navigate('/customers')}>
                        <ListItemText primary="Vehicle Care Portal" sx={isActive('/customers') ? styles.selectedMenuItem : styles.menuItem} />
                    </ListItem>
                ) : <ListItem button>
                    <ListItemText primary="Vehicle Care Portal" sx={isActive('/customers') ? styles.selectedMenuItem : {
                        fontWeight: 700,
                        fontSize: '1rem',
                        lineHeight: '24px',
                        color: '#00095B',
                        position: 'relative',
                        marginTop: '10px',
                        whiteSpace: 'nowrap',
                        width: 'auto',
                        textTransform: 'uppercase',
                        cursor: "default"
                    }} />
                </ListItem>}
                {hasPageAccess(roleConfig, 'list_alert', 'read') && (
                    <ListItem button onClick={() => navigate('/')}>
                        <ListItemText primary="Alerts" sx={isActive('/') ? styles.selectedMenuItem : styles.menuItem} />
                    </ListItem>
                )}
                {hasPageAccess(roleConfig, 'list_job', 'read') && (
                    <ListItem button onClick={() => navigate('/jobs')}>
                        <ListItemText primary="Jobs" sx={isActive('/jobs') ? styles.selectedMenuItem : styles.menuItem} />
                    </ListItem>
                )}
                {hasPageAccess(roleConfig, 'metrics', 'read') && (
                    <ListItem button onClick={() => navigate('/metrics')}>
                        <ListItemText primary="metrics" sx={isActive('/metrics') ? styles.selectedMenuItem : styles.menuItem} />
                    </ListItem>
                )}
            </List>
        </Box>
    );

    // Determine if the PeopleOutlineIcon should be shown
    const showPeopleOutlineIcon = () => {
        const accessToken = getToken();
        if (!accessToken) return false;
        const parsedToken = parseJwt(accessToken);
        const dlsEntitlements = parsedToken['DLS-Entitlements'];
        if (!dlsEntitlements) return false;

        const entitlements = JSON.parse(dlsEntitlements);
        const roleAssignments = entitlements.RoleAssignments || [];
        const carePlusRoles = roleAssignments
            .filter(assignment => assignment.EntityName === 'CarePlus')
            .map(assignment => assignment.RoleName);

        const siteCodeList = roleAssignments
            .filter(assignment => assignment.EntityName === 'CarePlus')
            .flatMap(assignment => {
                const dataAttributes = assignment.DataAttributes || [];
                return dataAttributes
                    .filter(attr => attr.AttributeName === 'SiteCode')
                    .flatMap(attr => attr.AttributeValues || []);
            });

        return carePlusRoles.includes('InternalAdmin') || siteCodeList.length === 0;
    };

    return (
        <AppBar position="sticky" sx={styles.appBar}>
            <Toolbar sx={styles.toolbar}>
                <Box component='img'
                    src="/Ford.svg"
                    alt="logo"
                    sx={styles.appLogo}
                />
                <Box sx={styles.verticalLine}></Box>
                {isMobile ? (
                    <>
                        <Box sx={{ flexGrow: 1 }} />
                        {showPeopleOutlineIcon() && (
                            <IconButton onClick={() => setPopupOpen(true)} sx={styles.icon}>
                                <PeopleOutlineIcon />
                            </IconButton>
                        )}
                        <IconButton onClick={toggleDrawer(true)} sx={styles.icon}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor='right' open={drawerOpen} onClose={toggleDrawer(false)}>
                            {drawerContent}
                        </Drawer>
                    </>
                ) : (
                    <>
                        <Box sx={styles.menuContainer}>
                            {hasPageAccess(roleConfig, 'list_customer', 'read') ? (
                                <Typography
                                    variant="body1"
                                    sx={isActive('/customers') ? { ...styles.menuItem, ...styles.selectedMenuItem } : styles.menuItem}
                                    onClick={() => navigate('/customers')}
                                >
                                    Vehicle Care Portal
                                </Typography>
                            ) : <Typography
                                variant="body1"
                                sx={isActive('/customers') ? { ...styles.menuItem, ...styles.selectedMenuItem } : {
                                    fontWeight: 700,
                                    fontSize: '1rem',
                                    lineHeight: '24px',
                                    color: '#00095B',
                                    position: 'relative',
                                    marginTop: '10px',
                                    whiteSpace: 'nowrap',
                                    width: 'auto',
                                    textTransform: 'uppercase',
                                    cursor: "default"
                                }}
                            >
                                Vehicle Care Portal
                            </Typography>}
                            {hasPageAccess(roleConfig, 'list_alert', 'read') && (
                                <Typography
                                    variant="body1"
                                    sx={isActive('/') ? { ...styles.menuItem, ...styles.selectedMenuItem } : styles.menuItem}
                                    onClick={() => navigate('/')}
                                >
                                    Alerts
                                </Typography>
                            )}
                            {hasPageAccess(roleConfig, 'metrics', 'read') && (
                                <Typography
                                    variant="body1"
                                    sx={isActive('/metrics') ? { ...styles.menuItem, ...styles.selectedMenuItem } : styles.menuItem}
                                    onClick={() => navigate('/metrics')}
                                >
                                    Metrics
                                </Typography>
                            )}
                            {hasPageAccess(roleConfig, 'list_job', 'read') && (
                                <Typography
                                    variant="body1"
                                    sx={isActive('/jobs') ? { ...styles.menuItem, ...styles.selectedMenuItem } : styles.menuItem}
                                    onClick={() => navigate('/jobs')}
                                >
                                    Jobs
                                </Typography>
                            )}

                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={styles.iconContainer}>
                            {/* <Box sx={styles.timeZone}>{localTimeZone} (UTC {utcOffset})</Box> */}
                            {showPeopleOutlineIcon() && (
                                <IconButton onClick={() => setPopupOpen(true)} sx={styles.icon}>
                                    <PeopleOutlineIcon />
                                </IconButton>
                            )}
                            <Box sx={styles.verticalLine}></Box>
                            {/* <Typography variant="body1" component="span" sx={styles.otherText}>Hello, {username}</Typography>
                            <Box sx={styles.verticalLine}></Box> */}
                            <Button sx={styles.logoutButton} onClick={handleLogout}>Log Out</Button>
                        </Box>
                    </>
                )}
            </Toolbar>
            <PopupDialog open={popupOpen} onClose={() => setPopupOpen(false)} onSelect={handlePopupSelect} />
        </AppBar>
    );
}

const styles = {
    appBar: {
        bgcolor: '#FFFFFF',
        borderBottom: '3px solid #F9F9F9',
        boxShadow: 'none'
    },
    toolbar: {
        minHeight: '64px',
        paddingY: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    appLogo: {
        cursor: 'pointer',
        marginLeft: '10px',
    },
    menuContainer: {
        display: 'flex',
        gap: '10%',
        marginLeft: '1%',
    },
    menuItem: {
        cursor: 'pointer',
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '22px',
        color: '#00142E',
        position: 'relative',
        marginTop: '10px',
        whiteSpace: 'nowrap',
        width: 'auto',
    },
    selectedMenuItem: {
        fontWeight: 700,
        color: '#00095B',
        '&::after': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '3px',
            backgroundColor: 'transparent', // Removed the line
            position: 'absolute',
            left: 0,
            bottom: -22
        }
    },
    timeZone: {
        fontWeight: 500,
        fontSize: '1rem',
        color: '#00142E',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px'  // Reduced gap
    },
    icon: {
        color: 'black',
        fontSize: '24px'
    },
    verticalLine: {
        width: '1px',
        height: '30px',
        backgroundColor: '#D1D5D9',
        marginX: '12px'
    },
    otherText: {
        fontWeight: 600,
        fontSize: '18px',
        color: 'black',
        marginX: 1
    },
    logoutButton: {
        fontFamily: "ford f1 regular",
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        color: "#00095B",
        textTransform: 'none'
    }
};

export default AppHeader;