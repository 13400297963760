import { Box, Skeleton } from '@mui/material';
import React from 'react';

const SkeletonBox = () => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
      <Skeleton animation='wave' height={50} />
    </Box>
  );
};

export default SkeletonBox;
