import React from "react";
import PropTypes from 'prop-types';
import { Typography, Icon } from "@ford/ford-ui-components";

const NumberMetricsCard = ({ title, loading, time, isReverse }) => {
    const containerClassName = isReverse ? "number-metrics-heading-container-reverse" : "number-metrics-heading-container";
    const paddingStyle = isReverse ? "20px 40px 50px 40px" : "50px 40px 20px 40px";
    const marginTopStyle = isReverse ? { marginTop: "30px" } : {};

    return (
        <div className="number-metrics-card-style" style={{ ...cardStyle, padding: paddingStyle }}>
            <div style={{ height: '100%', width: '100%' }}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <Icon name="loading" />
                    </div>
                ) : (
                    <>
                        <div className={containerClassName}>
                            <div style={marginTopStyle}>
                                <Typography displayStyle="display-3-large-semibold">
                                    {isReverse ? time[0]?.averageTime : time[0]?.alertsCount}
                                </Typography>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <Typography spanProps={{
                                    className: "number-metrics-subheading"
                                }} displayColor="text-onlight-moderate-default">
                                    {title + " "}
                                </Typography>
                                <Typography spanProps={{
                                }} displayColor="text-onlight-moderate-default" displayStyle="subtitle-regular">
                                    (last 7 days)
                                </Typography>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

NumberMetricsCard.propTypes = {
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    time: PropTypes.arrayOf(
        PropTypes.shape({
            averageTime: PropTypes.string,
            alertsCount: PropTypes.number,
        })
    ).isRequired,
    isReverse: PropTypes.bool.isRequired,
};

const cardStyle = {
    border: '1px solid #E5E5E5',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    height: '185px',
    zIndex: 0,
    marginTop: '10px',
};

export default NumberMetricsCard;
