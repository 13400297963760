import { Box } from '@mui/material';
import React from 'react';

const AnswerBox = (answer) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '30px' }}>
      <div dangerouslySetInnerHTML={{ __html: answer.answer }} />
    </Box>
  );
};

export default AnswerBox;
