import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Autocomplete, InputAdornment, IconButton, TextField } from '@mui/material';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import customerService from '../../services/customerService';
import debounce from 'lodash.debounce';
import { track } from '@amplitude/analytics-browser';

const CustomerSearch = ({ onVinSelect, selectedFilters, setSelectedFilters, setIsApiError }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const globalCount = useRef(parseInt(localStorage.getItem('globalCount')) || 1);

  const fetchSuggestions = useCallback(async (search) => {
    try {
      setIsFetching(true);
      const filterParams = Object.entries(selectedFilters)
        .filter(([_, values]) => values.length > 0)
        .map(([filterKey, filterValues]) => ({ filterKey, filterValues: filterValues.map(item => item.value) }));

      const response = await customerService.getCustomerSearch(search, filterParams);
      const formattedSuggestions = response.data.map(item => {
        if (item.searchBy === 'vin') {
          return {
            ...item,
            display: `${item.value} - ${item.vin}`
          };
        }
        return {
          ...item,
          display: item.value
        };
      });
      setSuggestions(formattedSuggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response.status
      });
    } finally {
      setIsFetching(false);
    }
  }, [selectedFilters]);

  const debouncedFetchSuggestions = useCallback(
    debounce((search) => {
      if (search.length > 2) {
        fetchSuggestions(search);
      } else {
        setSuggestions([]);
      }
    }, 300),
    [fetchSuggestions]
  );

  useEffect(() => {
    debouncedFetchSuggestions(searchTerm);
    return () => {
      debouncedFetchSuggestions.cancel();
    };
  }, [searchTerm, debouncedFetchSuggestions]);

  const handleSuggestionSelect = async (event, value) => {
    if (!value) return;

    // Track the search selection
    track('Search Selected', {
      searchTerm: searchTerm,
      searchBy: value.searchBy,
      value: value.value,
    });

    if (value.searchBy === 'vin') {
      const updatedFilters = {
        ...selectedFilters,
        vin: [...(selectedFilters.vin || []), { value: value.vin, position: globalCount.current++ }]
      };

      localStorage.setItem('selectedFilters', JSON.stringify(updatedFilters));
      onVinSelect(value.vin);
    } else if (value.searchBy === 'alertTitle') {
      const updatedFilters = {
        ...selectedFilters,
        alertTitle: [
          ...(selectedFilters.alertTitle || []),
          { value: value.value, position: globalCount.current++ }
        ]
      };

      localStorage.setItem('selectedFilters', JSON.stringify(updatedFilters));
      setSelectedFilters(updatedFilters); // Update filters in parent component
    }

    setSearchTerm('');
    setSuggestions([]);
  };

  const handleClear = () => {
    setSearchTerm('');
    setSuggestions([]);
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) => 
      part.toLowerCase() === highlight.toLowerCase() ? 
        <span key={index} style={{ fontWeight: 'bold' }}>{part}</span> : 
        part
    );
  };

  const truncateStyle = {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  };

  return (
    <Autocomplete
      options={suggestions?.length > 0 ? suggestions : []}
      getOptionLabel={(option) => option.display || ''}
      onChange={handleSuggestionSelect}
      inputValue={searchTerm}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setSearchTerm(value);
        }
      }}
      renderOption={(props, option) => (
        <li {...props} style={truncateStyle}>
          {getHighlightedText(option.display, searchTerm)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search by Customer, VIN or Alert"
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start" style={{ marginLeft: 10, marginRight: 0 }}>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm && (
                  <IconButton onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
      noOptionsText={
        searchTerm.length === 0
          ? "Enter 3 characters to search"
          : searchTerm.length < 3
          ? "Enter 3 characters to search"
          : isFetching
          ? "Fetching results..."
          : "No search results found"
      }
      disableClearable={true}
      style={{ width: 400 }}
    />
  );
};

export default CustomerSearch;