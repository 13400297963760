import React from "react";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import { useRole } from '../../../context/RoleContext';
import { hasActionAccess } from '../../../utils/permissions';
import { titleCase } from '../../../utils/stringUtils';
import DetailItem from '../../../components/DetailItem';

const IssueDetailProfile = ({ issue }) => {
  const { roleConfig } = useRole();
  const hasProfileVehiclePermission = hasActionAccess(roleConfig, 'alert_detail', 'profile_vehicle');

  function getFullName(customerInfo) {
    const firstName = customerInfo?.firstName ?? '';
    const lastName = customerInfo?.lastName ?? '';
    return firstName || lastName ? `${titleCase(firstName)} ${titleCase(lastName)}` : 'N/A';
  }
  
  const engineOilPercentDisplay = issue?.connectedVehicleInfo?.engineoilPercent ?? 'N/A';
  const displayEngineOilValue = engineOilPercentDisplay !== 'N/A' ? `${engineOilPercentDisplay}%` : 'N/A';

  const fuelOrChargeDisplay = issue?.connectedVehicleInfo?.fuelOrCharge ?? 'N/A';
  const displayFuelOrCharge = fuelOrChargeDisplay !== 'N/A' ? `${fuelOrChargeDisplay}% full` : 'N/A';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mx: 'auto', background: 'linear-gradient(to right, #f9f9f9 33%, white 30%)' }}>

      {/* Customer Section */}
      <Box sx={{ marginTop: "50px" }}>
        <Grid container spacing={2}>
          <DetailItem label="CUSTOMER" fontWeight={"600"} value={issue?.customerInfo?.lastUpdatedDate ? `(last updated at ${moment(issue?.customerInfo?.lastUpdatedDate).format('MM-DD-YYYY')})` : '(last updated at timestamp not available)'} />
          <DetailItem label="Name" value={getFullName(issue?.customerInfo)} />
          <DetailItem label="Email" value={((issue?.customerInfo?.emailAddress === null || issue?.customerInfo?.emailAddress === "" || issue?.customerInfo?.emailAddress === undefined) ? "N/A" : issue?.customerInfo?.emailAddress)} />
          <DetailItem label="FordPass Rewards" value={issue?.customerInfo?.fordPassRewardsInfo?.fordPassRewardsPoints ?? 'N/A'} />
        </Grid>
      </Box>

      {/* Vehicle Section */}
      {hasProfileVehiclePermission && (
        <Box sx={{ marginTop: "50px" }}>
          <Grid container spacing={2}>
            <DetailItem label="VEHICLE" fontWeight={"600"} value={issue?.connectedVehicleInfo?.sourceDataUpdateTime ? `(last updated at ${moment(issue?.connectedVehicleInfo?.sourceDataUpdateTime).format('MM-DD-YYYY')})` : '(last updated at timestamp not available)'} />
            <DetailItem label="VIN" value={issue?.vehicleInfo?.vin || 'N/A'} />
            <DetailItem label="Trim" value={issue?.vehicleInfo?.vehicleTrim ? titleCase(issue?.vehicleInfo?.vehicleTrim) : 'N/A'} />
            <DetailItem label="Color" value={issue?.vehicleInfo?.vehicleColor ? titleCase(issue?.vehicleInfo?.vehicleColor) : 'N/A'} />
            <DetailItem label="Odometer" value={issue?.connectedVehicleInfo?.odometer ? `${issue?.connectedVehicleInfo?.odometer} miles` : 'N/A'} />
            <DetailItem label="Fuel Level" value={displayFuelOrCharge} />
            <DetailItem label="Engine Oil Life" value={displayEngineOilValue} />
            <DetailItem label="Vehicle Data" value="Yes, Connected" />
          </Grid>
        </Box>
      )}

      {/* FordPass Section */}
      <Box sx={{ marginTop: "50px", marginBottom: "50px" }}>
        <Grid container spacing={2} sx={{ visibility: "hidden" }}>
          <DetailItem label="FORDPASS" fontWeight={"600"} value={`(last updated at ${moment(issue?.connectedVehicleInfo?.sourceDataUpdateTime).format('MM-DD-YYYY')})`} />
          <DetailItem label="FordPass Rewards" value="5,896 points" />
        </Grid>
      </Box>
    </Box>
  );
};

IssueDetailProfile.propTypes = {
  issue: PropTypes.object,
};

export default IssueDetailProfile;