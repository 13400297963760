// src/services/roleService.js
import axiosInstance from './axiosInstance';
import apiEndpoints from './apiEndpoints';
import { getImpersonateData } from '../utils/impersonateUtils';

const getRoleConfig = async (role, siteCode) => {
  // Check for impersonate data
  const impersonateData = getImpersonateData();
  if (impersonateData && impersonateData.isDealerImpersonate) {
    role = impersonateData.role;
    siteCode = impersonateData.siteCode;
  }
  const payload = {
    role: role,
    siteCode: siteCode
  };
  const response = await axiosInstance.post(`${apiEndpoints.ROLECONFIG}`, payload);
  return response.data;
};

const roleService = {
  getRoleConfig
};

export default roleService;
