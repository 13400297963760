//src/utils/auth.js
import { jwtVerify } from 'jose';
import { getJwks } from './jwks';

export function getToken() {
  return window.sessionStorage.getItem('access_token');
}

export function getIdToken() {
  return window.sessionStorage.getItem('id_token');
}

export function getExpiresIn() {
  return window.sessionStorage.getItem('expires_in');
}

export async function isTokenValid() {
  const token = getToken();
  if (!token) return false;

  const parsedToken = parseJwt(token);
  const expiresIn = getExpiresIn();

  if (!expiresIn) return false;
  const expiryTime = new Date(parseInt(expiresIn));

  // Validate expiry time
  if (new Date() >= expiryTime) return false;

  // Validate issuer
  const expectedIssuer = process.env.REACT_APP_EXPECTED_ISSUER;
  if (parsedToken?.iss !== expectedIssuer) {
    console.error(`Invalid token issuer: ${parsedToken?.iss}`);
    return false;
  }

  // Validate audience
  const expectedAudience = process.env.REACT_APP_RESOURCE;
  if (parsedToken?.aud !== expectedAudience) {
    console.error(`Invalid token audience: ${parsedToken?.aud}`);
    return false;
  }

  // Verify signature
  try {
    const key = await getJwks();
    if (!key) {
      return false;
    }

    const { payload } = await jwtVerify(token, key, {
      issuer: expectedIssuer,
      audience: expectedAudience
    });

    return !!payload;
  } catch (error) {
    console.error('Token verification failed:', error);
    return false;
  }
}

export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

export function storeTokens(accessToken, idToken, expiresIn) {
  const expiryTime = new Date().getTime() + expiresIn * 1000;
  window.sessionStorage.setItem('access_token', accessToken);
  window.sessionStorage.setItem('id_token', idToken);
  window.sessionStorage.setItem('expires_in', expiryTime.toString());
}

export function clearTokens() {
  window.sessionStorage.removeItem('access_token');
  window.sessionStorage.removeItem('id_token');
  window.sessionStorage.removeItem('expires_in');
}

export function parseJwt(token) {
  if (!token) {
    console.error('Token is null or undefined');
    return null;
  }
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Failed to parse JWT:', error);
    return null;
  }
}

// Add this function to get the user role
export function getUserRole() {
  const token = getToken();
  const parsedToken = parseJwt(token);
  const dlsEntitlements = parsedToken['DLS-Entitlements'];
  let role = '';

  if (dlsEntitlements) {
    const entitlements = JSON.parse(dlsEntitlements);
    const roleAssignments = entitlements.RoleAssignments || [];

    const carePlusRoles = roleAssignments
      .filter((assignment) => assignment.EntityName === 'CarePlus')
      .map((assignment) => assignment.RoleName);

    if (carePlusRoles.length > 0) {
      role = carePlusRoles[0];
    }
  }

  return role;
}
