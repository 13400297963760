import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Cookies from 'js-cookie';
import { Typography, Button } from "@ford/ford-ui-components";
import { ACCESS_TOKEN, EXPIRES_IN, ID_TOKEN } from "../utils/constants";
import { track } from '@amplitude/analytics-browser';

const AccessDenied = ({ title, description }) => {

    useEffect(() => {
        track('User Page Viewed', { page: title });
    }, [title])

    const handleGoBack = () => {
        localStorage.clear();
        sessionStorage.clear();
        Cookies.remove(ACCESS_TOKEN);
        Cookies.remove(ID_TOKEN);
        Cookies.remove(EXPIRES_IN);
        window.location.href = '/login'
    };

    return (
        <div className="unauthorized-screen-container">
            <div className="unauthorized-screen-content">
                <div className="unauthorized-screen-ford-logo">
                    <div>
                        <img src="/Ford.svg" alt="Ford Logo" />
                    </div>
                </div>
                <div className="unauthorized-screen-text">
                    <div className="unauthorized-screen-content-title">
                        <Typography displayStyle="display-3-small-semibold" displayColor="#000000">
                            {title}
                        </Typography>
                    </div>
                    <div className="unauthorized-screen-content-description">
                        {description}
                    </div>
                </div>
                <div className="unauthorized-screen-back-login">
                    <Button
                        variant="filled"
                        onClick={handleGoBack}
                    >
                        Go Back to Login
                    </Button>
                </div>
            </div>
        </div>
    )
}

AccessDenied.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired
}

export default AccessDenied;