import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { OAuthRedirect, TokenExpiryCheck, AuthenticatedRoute } from '../config/AuthenticatedRoute';
import LoginPage from '../containers/Auth/LoginPage';
import LogoutCallback from '../components/LogoutCallback';
import Loader from '../components/Loader';
import AllCustomers from '../containers/AllCustomers/AllCustomers'
import JobList from '../containers/Jobs/JobList';
import Metrics from '../containers/Reports/Metrics';
import CustomerList from '../containers/Customers/CustomerList';
import ImpersonateRole from '../containers/Impersonate/ImpersonateRole';
import { getRoleAndSiteCode } from '../utils/roleUtils';
import { isImpersonationComplete } from '../utils/impersonationUtils';
import AIGuide from "../containers/AIGuide/AIGuide";

/* istanbul ignore next */
const IssueList = React.lazy(() => import('../containers/Issues/IssueList'));

function AppRoutes() {
  const { currentRole } = getRoleAndSiteCode();
  const impersonationComplete = isImpersonationComplete();

  /* istanbul ignore next */
  const handleImpersonationRedirect = (element) => {
    if ((currentRole === 'InternalAdmin' || currentRole === 'CarePlus Internal Admin') && !impersonationComplete) {
      return <Navigate to='/ImpersonateRole' />;
    }
    return element;
  };

  return (
    <TokenExpiryCheck>
      <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route
          path='/alerts'
          element={
            <AuthenticatedRoute>
              <Suspense fallback={<Loader />}>
                {handleImpersonationRedirect(<IssueList />)}
              </Suspense>
            </AuthenticatedRoute>
          }
        />
        <Route
          path='/'
          element={
            <AuthenticatedRoute>
              <Suspense fallback={<Loader />}>
                {handleImpersonationRedirect(<CustomerList />)}
              </Suspense>
            </AuthenticatedRoute>
          }
        />
        <Route
          path='/all-customers'
          element={
            <AuthenticatedRoute>
              {handleImpersonationRedirect(<AllCustomers />)}
            </AuthenticatedRoute>
          }
        />
        <Route
          path='/jobs'
          element={
            <AuthenticatedRoute>
              {handleImpersonationRedirect(<JobList />)}
            </AuthenticatedRoute>
          }
        />
        <Route
          path='/metrics'
          element={
            <AuthenticatedRoute>
              {handleImpersonationRedirect(<Metrics />)}
            </AuthenticatedRoute>
          }
        />
      <Route
          path='/ai-guide'
          element={
              <AuthenticatedRoute>
                  {handleImpersonationRedirect(<AIGuide />)}
              </AuthenticatedRoute>
          }
      />
        <Route
          path='/ImpersonateRole'
          element={
            (currentRole === 'InternalAdmin' || currentRole === 'CarePlus Internal Admin') ? <ImpersonateRole /> : <Navigate to='/' />
          }
        />
        <Route path='/oauth/redirect' element={<OAuthRedirect />} />
        <Route path='/logout-callback' element={<LogoutCallback />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </TokenExpiryCheck>
  );
}

export default AppRoutes;
