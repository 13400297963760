import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Button,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import moment from 'moment-timezone';
import DOMPurify from 'dompurify';
import PopupDialog from './PopupDialog';
import {
  getToken,
  getIdToken,
  clearTokens,
  parseJwt,
  isValidUrl,
  isTokenValid
} from '../utils/auth';
import { getUserName } from '../config/AuthenticatedRoute';
import { useRole } from '../context/RoleContext';
import { hasPageAccess } from '../utils/permissions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as Feedback } from '../assets/icons/feedback.svg';
import { getImpersonateData } from '../utils/impersonateUtils';
import { ACCESS_TOKEN, ID_TOKEN, EXPIRES_IN } from '../utils/constants';
import account_icon from '../assets/icons/account_circle.svg';
import { getRoleAndSiteCode } from '../utils/roleUtils';
import { track } from '@amplitude/analytics-browser';

function AppHeader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [localTimeZone, setLocalTimeZone] = useState('');
  const [utcOffset, setUtcOffset] = useState('');
  const [username, setUsername] = useState('');
  const [impersonateRole, setImpersonateRole] = useState('');
  const [impersonateSiteCode, setImpersonateSiteCode] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const logoutURL = process.env.REACT_APP_SIGNOUT_URL;
  const logoutParams = new URLSearchParams({
    id_token_hint: encodeURIComponent(getIdToken())
  }).toString();
  const { roleConfig } = useRole();
  const { currentRole } = getRoleAndSiteCode();

  const handlePopupSelect = (option) => {
    setPopupOpen(false);
  };

  useEffect(() => {
    const impersonateData = JSON.parse(getImpersonateData());
    if (impersonateData) {
      setImpersonateRole(impersonateData.role);
      setImpersonateSiteCode(impersonateData.siteCode);
    }

    const handleUnauthorized = () => {
      navigate('/unauthorized');
    };

    const getTokensAndUser = () => {
      const token = getIdToken();
      if (!token) return null;
      const username = getUserName();
      setUsername(username);
      const accessToken = getToken();
      const parsedToken = parseJwt(accessToken);
      return parsedToken;
    };

    const checkRoleAssignments = (parsedToken) => {
      const dlsEntitlements = parsedToken['DLS-Entitlements'];
      const fordUserType = parsedToken['fordUserType'];
      const fordSiteCode = parsedToken['fordSiteCode'];

      if (!dlsEntitlements) {
        console.warn('No DLS-Entitlements found');
        handleUnauthorized();
        return;
      }

      const entitlements = JSON.parse(dlsEntitlements);
      const roleAssignments = entitlements.RoleAssignments || [];

      // Check for default role for Dealers
      if (fordUserType === 'Dealer' && roleAssignments.length === 0 && fordSiteCode) {
        return; // Consider this a valid scenario
      }

      const carePlusRoles = roleAssignments
        .filter((assignment) => assignment.EntityName === 'CarePlus')
        .map((assignment) => assignment.RoleName);

      if (carePlusRoles.length < 1) {
        console.warn('No valid role assignments found');
        handleUnauthorized();
      }
    };

    const validateUser = async () => {
      const valid = await isTokenValid();
      if (!valid) {
        handleUnauthorized();
        return;
      }

      const parsedToken = getTokensAndUser();
      if (parsedToken !== null) {
        checkRoleAssignments(parsedToken);
      } else {
        handleUnauthorized();
      }
    };

    validateUser();
  }, [navigate]);

  useEffect(() => {
    const fetchTimeZone = () => {
      const timeZone = moment.tz.guess();
      localStorage.setItem('timezone', timeZone);
      const offset = moment().tz(timeZone).format('Z');
      const offsetHours = offset.includes(':') ? offset.replace(':', 'h ') + 'm' : offset + 'h';
      localStorage.setItem('utcOffset', offsetHours);

      setLocalTimeZone(timeZone);
      setUtcOffset(offsetHours);
    };

    fetchTimeZone();
  }, []);

  const handleLogout = () => {
    clearTokens();
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove(ACCESS_TOKEN);
    Cookies.remove(ID_TOKEN);
    Cookies.remove(EXPIRES_IN);
    setUsername('');

    if (isValidUrl(logoutURL)) {
      const sanitizedUrl = DOMPurify.sanitize(`${logoutURL}?${logoutParams}`);
      window.location.href = sanitizedUrl;
    } else {
      console.error('Invalid OAuth issuer Logout URL');
    }
  };

  const isActive = (path) => location.pathname === path;

  /* istanbul ignore next */
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  /* istanbul ignore next */
  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role='presentation'
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Typography variant='h6' component='div' sx={{ textAlign: 'center', mt: 2 }}>
        {username}
      </Typography>

      <List>
        <ListItem button>
          <ListItemText
            primary='Vehicle Care Portal'
            sx={{
              fontWeight: 800,
              fontSize: '1rem',
              lineHeight: '24px',
              color: '#00095B',
              position: 'relative',
              marginTop: '10px',
              whiteSpace: 'nowrap',
              width: 'auto',
              textTransform: 'uppercase',
              cursor: 'default'
            }}
          />
        </ListItem>
        {hasPageAccess(roleConfig, 'list_customer', 'read') && (
          <Link to='/'>
            <ListItem button onClick={() => navigate('/')}>
              <ListItemText
                primary='Active Alerts'
                sx={isActive('/') ? styles.selectedMenuItem : styles.menuItem}
              />
            </ListItem>
          </Link>
        )}
        {hasPageAccess(roleConfig, 'allcustomer', 'read') && (
          <Link to='/all-customers'>
            <ListItem button onClick={() => navigate('/all-customers')}>
              <ListItemText
                primary='All Customers'
                sx={isActive('/') ? styles.selectedMenuItem : styles.menuItem}
              />
            </ListItem>
          </Link>
        )}
        {hasPageAccess(roleConfig, 'list_alert', 'read') && (
          <Link to='/alerts'>
            <ListItem button onClick={() => navigate('/alerts')}>
              <ListItemText
                primary='Alerts'
                sx={isActive('/') ? styles.selectedMenuItem : styles.menuItem}
              />
            </ListItem>
          </Link>
        )}
        {hasPageAccess(roleConfig, 'list_job', 'read') && (
          <Link to='/jobs'>
            <ListItem button onClick={() => navigate('/jobs')}>
              <ListItemText
                primary='Jobs'
                sx={isActive('/jobs') ? styles.selectedMenuItem : styles.menuItem}
              />
            </ListItem>
          </Link>
        )}
        {hasPageAccess(roleConfig, 'metrics', 'read') && (
          <Link to='/metrics'>
            <ListItem button onClick={() => navigate('/metrics')}>
              <ListItemText
                primary='metrics'
                sx={isActive('/metrics') ? styles.selectedMenuItem : styles.menuItem}
              />
            </ListItem>
          </Link>
        )}
        {hasPageAccess(roleConfig, 'ai-guide', 'read') && (
          <Link to='/ai-guide'>
            <ListItem button onClick={() => navigate('/ai-guide')}>
              <ListItemText
                primary='ai-guide'
                sx={isActive('ai-guide') ? styles.selectedMenuItem : styles.menuItem}
              />
            </ListItem>
          </Link>
        )}
      </List>
      <Button sx={styles.logoutButton} onClick={handleLogout}>
        {' '}
        Log Out
      </Button>
    </Box>
  );

  // Determine if the PeopleOutlineIcon should be shown
  const showPeopleOutlineIcon = () => {
    const accessToken = getToken();
    if (!accessToken) return false;
    const parsedToken = parseJwt(accessToken);
    const dlsEntitlements = parsedToken['DLS-Entitlements'];
    if (!dlsEntitlements || dlsEntitlements === '{}') return false;

    const entitlements = JSON.parse(dlsEntitlements);
    const roleAssignments = entitlements.RoleAssignments || [];
    const carePlusRoles = roleAssignments
      .filter((assignment) => assignment.EntityName === 'CarePlus')
      .map((assignment) => assignment.RoleName);

    return (
      carePlusRoles.includes('InternalAdmin') || carePlusRoles.includes('CarePlus Internal Admin')
    );
  };

  return (
    <AppBar position='sticky' sx={styles.appBar}>
      {isMobile ? (
        <>
          <div className='header-mobile-container'>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '1rem',
                lineHeight: '24px',
                color: '#00095B',
                position: 'relative',
                marginTop: '10px',
                whiteSpace: 'nowrap',
                width: 'auto',
                textTransform: 'uppercase',
                cursor: 'default'
              }}
            >
              VEHICLE CARE PORTAL
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            {showPeopleOutlineIcon() && (
              <IconButton onClick={() => setPopupOpen(true)} sx={styles.icon}>
                <img src={account_icon} alt='People Icon' />
              </IconButton>
            )}
            <IconButton onClick={toggleDrawer(true)} sx={styles.icon}>
              <MenuIcon />
            </IconButton>
          </div>
          <Drawer anchor='right' open={drawerOpen} onClose={toggleDrawer(false)}>
            {drawerContent}
          </Drawer>
        </>
      ) :
        (
          <div className='header-container'>
            <div className='header-logo-container'>
              <Box component='img' src='/Ford.svg' alt='logo' sx={styles.appLogo} />
              <Box sx={styles.verticalLine}>line</Box>
              <Box sx={styles.menuContainer}>
                <Typography variant='body1' sx={{ ...styles.menuItem, ...styles.selectedMenuItem }}>
                  VEHICLE CARE PORTAL
                </Typography>
              </Box>
            </div>
            <Box sx={styles.menuContainer}>
              {hasPageAccess(roleConfig, 'list_alert', 'read') && (
                <Link to='/alerts'>
                  <Typography
                    variant='body1'
                    sx={
                      isActive('/alerts')
                        ? { ...styles.menuItem, ...styles.selectedMenuItemLink }
                        : styles.menuItem
                    }
                    onClick={() => navigate('/alerts')}
                  >
                    Alerts
                  </Typography>
                </Link>
              )}
              {hasPageAccess(roleConfig, 'list_customer', 'read') && (
                <Link to={'/'}>
                  <Typography
                    variant='body1'
                    sx={
                      isActive('/')
                        ? { ...styles.menuItem, ...styles.selectedMenuItemLink }
                        : styles.menuItem
                    }
                    onClick={() => navigate('/')}
                  >
                    Active Alerts
                  </Typography>
                </Link>
              )}
              {hasPageAccess(roleConfig, 'allcustomer', 'read') && (
                <Link to={'/all-customers'}>
                  <Typography
                    variant='body1'
                    sx={
                      isActive('/all-customers')
                        ? { ...styles.menuItem, ...styles.selectedMenuItemLink }
                        : styles.menuItem
                    }
                  >
                    All Customers
                  </Typography>
                </Link>
              )}
              {hasPageAccess(roleConfig, 'metrics', 'read') && (
                <Link to={'/metrics'}>
                  <Typography
                    variant='body1'
                    sx={
                      isActive('/metrics')
                        ? { ...styles.menuItem, ...styles.selectedMenuItemLink }
                        : styles.menuItem
                    }
                    onClick={() => navigate('/metrics')}
                  >
                    Metrics
                  </Typography>
                </Link>
              )}
              {hasPageAccess(roleConfig, 'list_job', 'read') && (
                <Link to='/jobs'>
                  <Typography
                    variant='body1'
                    sx={
                      isActive('/jobs')
                        ? { ...styles.menuItem, ...styles.selectedMenuItemLink }
                        : styles.menuItem
                    }
                    onClick={() => navigate('/jobs')}
                  >
                    Jobs
                  </Typography>
                </Link>
              )}
              {hasPageAccess(roleConfig, 'ai-guide', 'read') && (
                <Link to='/ai-guide'>
                  <Typography
                    variant='body1'
                    sx={
                      isActive('/ai-guide')
                        ? { ...styles.menuItem, ...styles.selectedMenuItem }
                        : styles.menuItem
                    }
                    onClick={() => navigate('/ai-guide')}
                  >
                    AI Guide
                  </Typography>
                </Link>
              )}
            </Box>
            {/* <Box sx={{ flexGrow: 1 }} /> */}
            <Box sx={styles.iconContainer}>

              <Box sx={styles.feedbackButton} onClick={(event) => {
                event.stopPropagation();
                track("Feedback Clicked");
                window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=eruQyfRRm0O9NpwH-xBBwC9fUDMqhCRAssKbGY8VdmhUQzNQS05LRjBKOTFFT1QyU1QxTUdTS1hKWC4u', '_blank', 'noopener');
              }}>
                <Box>
                  <Feedback />
                </Box>
                <Box sx={styles.feedbackText}>Feedback</Box>
              </Box>
              <Box onClick={() => {
                  if (currentRole === 'InternalAdmin' || currentRole === 'CarePlus Internal Admin') {
                    setPopupOpen(true);
                  }
                }} sx={styles.iconContainer}>

                <div>
                  {(
                    <IconButton disabled={!showPeopleOutlineIcon()} sx={styles.icon}>
                      <img src={account_icon} alt='People Icon' />
                    </IconButton>
                  )}
                </div>
                <Box component='span' sx={styles.nameText}>
                  <Typography variant='body2' sx={{ fontWeight: "700", marginRight: "10px" }}>{username}</Typography>
                  <Typography variant='body2' sx={styles.otherText}>
                    {(currentRole === 'InternalAdmin' || currentRole === 'CarePlus Internal Admin') && `${impersonateRole}, ${impersonateSiteCode}`}
                  </Typography>
                </Box>
              </Box>
              <div style={{ width: '1px', height: '32px', backgroundColor: '#B2B2B2' }}></div>
              <Button sx={styles.logoutButton} onClick={handleLogout}>
                <Typography variant='body2' sx={{ fontWeight: "700", marginRight: "10px", color: "#0a0a0a" }}>Log Out</Typography>
              </Button>
            </Box>
          </div>
        )}
      <PopupDialog
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        onSelect={handlePopupSelect}
      />
    </AppBar>
  );
}

const styles = {
  appBar: {
    bgcolor: '#FFFFFF',
    borderBottom: 'none',
    boxShadow: 'none'
  },
  toolbar: {
    minHeight: '64px',
    paddingY: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  appLogo: {
    marginLeft: '10px'
  },
  menuContainer: {
    display: 'flex',
    marginLeft: '1%',
    gap: '20px'
  },
  menuItem: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '22px',
    color: '#00142E',
    position: 'relative',
    marginTop: '10px',
    whiteSpace: 'nowrap',
    width: 'auto'
  },
  selectedMenuItem: {
    fontWeight: 700,
    color: '#00095B'
  },
  selectedMenuItemLink: {
    fontWeight: 700,
    color: '#00095B',
    borderBottom: '1px solid #000000',
    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '3px',
      backgroundColor: 'transparent', // Removed the line
      position: 'absolute',
      left: 0,
      bottom: -22
    }
  },
  timeZone: {
    fontWeight: 500,
    fontSize: '1rem',
    color: '#00142E'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: '8px',
    cursor: 'pointer',
    padding: '0 20px'
  },
  icon: {
    color: 'black',
    fontSize: '24px'
  },
  verticalLine: {
    width: '1px',
    height: '32px',
    backgroundColor: '#B2B2B2'
  },
  otherText: {
    color: '#666666'
  },
  nameText: {
    fontWeight: 400,
    fontSize: '1rem',
    color: '#000',
    lineHeight: '24px'
  },
  feedbackText: {
    color: '#0562D2',
    textAlign: 'center',
    fontFamily: 'ford f1 bold, sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px'
  },
  feedbackButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    ':hover': {
      cursor: 'pointer'
    },
    marginTop: '8px'
  },
  impersonateText: {
    fontWeight: 700,
    fontSize: '1rem',
    color: '#000000'
    // marginX: 1
  },
  logoutButton: {
    fontFamily: 'ford f1 regular',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#00095B',
    textTransform: 'none',
    marginLeft: '15px'
  }
};

export default AppHeader;
