import React, {useState} from "react";
import PropTypes from "prop-types";
import {Box, Typography, IconButton, Tooltip} from "@mui/material";
import { Close } from "@mui/icons-material";

//styling
import { styles } from "./IssueDetailHeaderStyles.style";
import { ReactComponent as CopyVin } from '../../../assets/icons/copy-vin.svg';
import {track} from "@amplitude/analytics-browser";
const IssueDetailHeader = ({
  handleDrawerClose,
  customerName,
  vehModelYear,
  vehMake,
  vin,
  vehModel
}) => {

  const [isVinCopied, setIsVinCopied] = useState(false);

    const handleCopyVin = (vin) => {
        track("Copy Vin Clicked");
        setIsVinCopied(true);
        navigator.clipboard.writeText(vin);
    };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingX: 6,
          paddingY: 2,
          fontSize: '0.8rem'
        }}
      >
        <Box sx={styles.titleContainer}>
          {customerName?.length > 1 ? (
            <Typography sx={styles.titleTypography}>{toTitleCase(customerName)}</Typography>
          ) : (
            <Typography sx={styles.titleTypography}>Name Unknown</Typography>
          )}
        </Box>
        <Box>
          <IconButton sx={styles.closeButton} onClick={handleDrawerClose}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ padding: '0 50px', marginBottom: '30px', marginTop: '-15px' }}>
        <Box sx={styles.titleContainer}>
          <Box>
            <Typography sx={styles.subtitleTypography} variant='h6'>
              {vehModelYear ?? '-'} {vehMake?.toUpperCase() ?? '-'} {vehModel?.toUpperCase() ?? '-'}
              {/* Display vehicle model year */}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '2px',
              height: '15px',
              backgroundColor: '#000000',
              display: 'inline-block',
              margin: '3px -2px' // Add some margin for space
            }}
          ></Box>
          <Box>
            <Typography sx={styles.subtitleTypography} variant='h6'>
                {vin ?? '-'} {/* Display VIN */} <Tooltip onClose={()=> setTimeout(() => setIsVinCopied(false), 500)} style={{display: "inline"}} componentsProps={{tooltip: {sx: styles.copyVinTooltip}}} placement={"top"} title={isVinCopied? "Copied": "Copy"} onClick={() => handleCopyVin(vin)}><CopyVin/></Tooltip>
            </Typography>
          </Box>
        </Box>
          <Box>
              <button
                  className='link-btn'
                  onClick={() => {track("PTS Clicked");window.open('https://www.fordtechservice.dealerconnection.com', '_blank', 'noopener');}}
              >
                  Professional Technician System (PTS)
              </button>
          </Box>
      </Box>
    </Box>
  );
};

IssueDetailHeader.propTypes = {
  customerName: PropTypes.string,
  vehModelYear: PropTypes.number,
  veheMake: PropTypes.string,
  veheModel: PropTypes.string,
  vin: PropTypes.string,
  handleDrawerClose: PropTypes.func.isRequired
};

export default IssueDetailHeader;
