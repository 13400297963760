import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Snackbar, Alert } from '@mui/material';
import { Button } from '@ford/ford-ui-components';
import { FileDownloadOutlined } from '@mui/icons-material';
import allCustomerService from '../../services/allCustomerService';
import { exportToExcel } from '../../utils/exportToExcel';
import { track } from '@amplitude/analytics-browser';

const AllCustomersExport = ({
  selectedFilters,
  sortingParams,
  setIsApiError,
  loading
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [exporting, setExporting] = useState(false);

  const handleExport = async () => {
    setExporting(true);
    try {
      track('All Customers Export Clicked', {
        action: 'start'
      });
      // Show snackbar indicating export has started
      setSnackbarMessage('Export is started. The file will be downloaded in the background.');
      setSnackbarSeverity('info');
      setSnackbarOpen(true);

      const allCustomers = await fetchAllCustomersForExport(selectedFilters, sortingParams);
      const formattedCustomers = formatAllCustomersForExport(allCustomers);
      exportToExcel(formattedCustomers, 'AllCustomers');

      // Show snackbar indicating export was successful
      setSnackbarMessage('Export successful. The file has been downloaded.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      track('All Customers Export Clicked', {
        action: 'success'
      });
    } catch (error) {
      console.error('Error exporting customers:', error);
      setSnackbarMessage('Error during export. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setExporting(false);
    }
  };

  
  /* istanbul ignore next */
  const fetchAllCustomersForExport = async (selectedFilters, sortingParams) => {
    const filterParams = selectedFilters ? Object.entries(selectedFilters)
      .filter(([_, values]) => values.length > 0)
      .map(([filterKey, filterValues]) => ({ filterKey, filterValues: filterValues.map(item => item.value) }))
      : [];

    const sortingKeys = {
      priority: '',
      alertDatetime: '',
      status: ''
    };
    const sortingPayload = sortingParams && sortingParams.sortingKey ? {
      defaultSort: sortingParams.direction === '',
      sortingKey: {
        ...sortingKeys,
        [sortingParams.sortingKey]: sortingParams.direction,
      },
    } : {
      defaultSort: true,
      sortingKey: sortingKeys,
    };
    let data;
    try {
      data = await allCustomerService.getAllCustomersForExport(filterParams, sortingPayload);
    } catch (error) {
      console.error("Error fetching all customers export data:", error);
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response.status
      });
      setSnackbarMessage('Error fetching all customers. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return [];
    }
    return data.data.customers || [];
  };

  const formatAllCustomersForExport = (customers) => {
    return customers.map((customer) => ({
      Customer: customer.customerName,
      Email: customer.emailAddress.toLowerCase(),
      VIN: customer.vin.toUpperCase(),
      Make: `${customer.vehicleMake}`,
      Model: `${customer.vehicleModel}`,
      Year: `${customer.vehicleYear}`
    }));
  };

  const handleSnackbarClose = () => {
    /* istanbul ignore next */
    setSnackbarOpen(false);
  };

  return (
    <>
      <Button
        variant='text'
        leftIcon={<FileDownloadOutlined />}
        onClick={handleExport}
        disabled={loading || exporting}
      >
        Export
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

AllCustomersExport.propTypes = {
  selectedFilters: PropTypes.object,
  sortingParams: PropTypes.object,
  setIsApiError: PropTypes.func,
  loading: PropTypes.bool,
};

export default AllCustomersExport;
