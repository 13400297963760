// src/utils/roleUtils.js
import { getToken, parseJwt } from './auth';

export const getRoleAndSiteCode = () => {
  const parsedToken = parseJwt(getToken());
  const dlsEntitlements = parsedToken['DLS-Entitlements'];
  let role = '';
  let siteCode = ''; // Default value

  if (dlsEntitlements) {
    const entitlements = JSON.parse(dlsEntitlements);
    const roleAssignments = entitlements.RoleAssignments || [];

    const carePlusRoles = roleAssignments
      .filter((assignment) => assignment.EntityName === 'CarePlus')
      .map((assignment) => {
        const dataAttributes = assignment.DataAttributes || [];
        dataAttributes.forEach((attribute) => {
          if (attribute.AttributeName === 'SiteCode') {
            siteCode = attribute.AttributeValues[0] || '0';
          }
        });
        return assignment.RoleName;
      });

    if (carePlusRoles.length > 0) {
      role = carePlusRoles[0];
    }
  }

  return { role, siteCode };
};
