import axiosInstance from "./axiosInstance";
import apiEndpoints from "./apiEndpoints";
import { getRoleAndSiteCode } from "../utils/roleUtils";

const getAllCustomers = async (page, pageSize, filterParams, sortingParams) => {
  const { role, siteCode } = getRoleAndSiteCode();

  const customerAlertsURL = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.ALLCUSTOMERS}`;
  const response = await axiosInstance.post(customerAlertsURL, {
    paginationParams: {
      page,
      pageSize,
      filterRequest: {
        filterParams: filterParams,
        filter: filterParams.length > 0 // Set filter to true if there are filters
      },
      sortingParams: sortingParams
    },
    appUserDetails: {
      role,
      siteCode
    }
  });
  return response.data;
}

const getAllCustomersForExport = async (filterParams, sortingParams) => {
  const { role, siteCode } = getRoleAndSiteCode();

  const allCustomersExportUrl = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.ALLCUSTOMERSEXPORT}`;
  const response = await axiosInstance.post(allCustomersExportUrl, {
    paginationParams: {
      filterRequest: {
        filterParams: filterParams,
        filter: filterParams.length > 0 // Set filter to true if there are filters
      },
      sortingParams: sortingParams
    },
    appUserDetails: {
      role,
      siteCode
    }
  });
  return response.data;
}

const allCustomerService = {
    getAllCustomers,
    getAllCustomersForExport
}

export default allCustomerService;