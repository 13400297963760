import MRA1 from '../assets/icons/wrench1.svg';
import MRA2 from '../assets/icons/wrench2.svg';
import MRA3 from '../assets/icons/wrench3.svg';
import MRA4 from '../assets/icons/wrench4.svg';
import MRA5 from '../assets/icons/not available.svg';
import MRA6 from '../assets/icons/tire.svg';
import MRA7 from '../assets/icons/drive assistance.svg';
import unAvailable from '../assets/icons/help.svg';

/**
 * Display MRA icon based on the MRA value
 * @param {String} MRAValue - MRA value
 * @returns {String} - MRA icon
 */
const displayMRAIcon = (MRAValue) => {
    if(MRAValue === 'MRA1') {
        return MRA1;
    } else if(MRAValue === 'MRA2') {
        return MRA2;
    } else if(MRAValue === 'MRA3') {
        return MRA3;
    } else if(MRAValue === 'MRA4') {
        return MRA4;
    } else if(MRAValue === 'MRA5') {
        return MRA5;
    } else if(MRAValue === 'MRA6') {
        return MRA6;
    } else if(MRAValue === 'MRA7') {
        return MRA7;
    } else {
        return unAvailable;
    }
}

export {
    displayMRAIcon
}