import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/ErrorBoundary';
import { init } from '@amplitude/analytics-browser';

const root = ReactDOM.createRoot(document.getElementById('root'));
init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
  defaultTracking: {
    pageViews: true, // Track page views
    sessions: true, // Track sessions
    fileDownloads: true, // Track file downloads
    formInteractions: true // Track form interactions
  }
});
root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
