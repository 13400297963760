import React from "react";
import PropTypes from "prop-types";
import AlertItem from '../IssueDetailService/AlertItem'; // Import the AlertItem component
import InfoBanner from "../../../components/InfoBanner";

import { Box, Grid, Snackbar, Alert } from "@mui/material";
import { useRole } from '../../../context/RoleContext';
import { hasActionAccess } from '../../../utils/permissions';
import { Typography } from '@ford/ford-ui-components';
import { useAlertStatus } from "../../../hooks/useAlertStatus";

const AlertDetailHistory = ({ state, setState, isCustomers, selectedFilters, alertsData, vehicleProfileId }) => {
  const { roleConfig } = useRole();
  const hasAlertHistoryPermission = hasActionAccess(roleConfig, 'customer_detail', 'alert_history');

  const {
    selectedAlertStatuses,
    handleStatusChange,
    snackbarMessage,
    snackbarOpen,
    setSnackbarOpen,
  } = useAlertStatus(roleConfig, isCustomers, state, setState, selectedFilters);

  return (
    <div style={{ padding: "10px" }}>
      {hasAlertHistoryPermission ? (
        <Box>
          <InfoBanner description={
            <Typography displayStyle="body-1-medium" displayColor="text-onlight-moderate-default">
              <Typography displayColor="text-onlight-moderate-default" displayStyle="body-1-bold">All open alerts older than two years, </Typography>
              <Typography displayColor="text-onlight-moderate-default" displayStyle="body-1-bold">completed alerts, </Typography> or
              <Typography displayColor="text-onlight-moderate-default" displayStyle="body-1-bold"> cancelled alerts </Typography>will be automatically moved and displayed in this section.
            </Typography>
          } iconName={"info"} />
          {alertsData?.inActiveAlerts?.length > 0 ? (
            /* istanbul ignore next */
            alertsData.inActiveAlerts.map((alert, index) => (
              /* istanbul ignore next */
              <AlertItem
                key={alert.id}
                alert={alert}
                selectedAlertStatuses={selectedAlertStatuses}
                handleStatusChange={(event) => {
                  /* istanbul ignore next */
                  return handleStatusChange(event, alert.id, alert.status, alert)
                }}
                index={index} // Pass the index here
              />
            ))
          ) : (
            <NoAlertsFound />
          )}
        </Box>
      ) : (
        <Box sx={{ padding: "2rem" }}>
          <Typography variant="h6" color="textSecondary">
            Permission Denied
          </Typography>
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

const NoAlertsFound = () => (
  <Grid container >
    <Grid sx={{
      height: "48px",
      width: "100%",
      padding: "12px 18px",
      margin: "0 45px",
      background: "#FAFAFA"
    }} item>
      No completed or cancelled alerts
    </Grid>
  </Grid>
);

AlertDetailHistory.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
};

export default AlertDetailHistory;
