import React from "react";
import PropTypes from 'prop-types';
import { styles } from "./IssueDetailprofile.style";
import { titleCase } from '../../../utils/stringUtils';
import formatDateWithTimezone from '../../../utils/formatDateWithTimezone';

const getRange = (engineType, fuelRange, batteryRange) => {
    switch (engineType) {
        case 'ICE':
        case 'Hybrid':
            return fuelRange;
        case 'Electric':
            return batteryRange;
        default:
            return null;
    }
};

const formatUpdateStatus = (tmcStatusCode, tmcUpdatedTime) => {
    switch (tmcStatusCode) {
        case 200:
            return formatDateWithTimezone(tmcUpdatedTime);
        case 403:
            return 'Unavailable';
        default:
            return '';
    }
};

const getModemStatus = (tmcStatusCode) => {
    switch (tmcStatusCode) {
        case 200:
            return "Authorized";
        case 403:
            return "Unauthorized, data unavailable";
        default:
            return "-";
    }
};

const getFuelTypeDisplay = (fuelType) => {
    return fuelType && (fuelType === "Electric" ? 'EV' : (fuelType || '-'));
};

const formatValue = (value, suffix = '') => {
    return (value !== undefined && value !== null) ? `${value}${suffix}` : '-';
};

const shouldDisplayFuelLevel = (engineType) => {
    return engineType === 'ICE' || engineType === 'Hybrid' || engineType === null;
};

const shouldDisplayStateOfCharge = (engineType) => {
    return engineType === 'Electric';
};

const VehicleInfo = ({ customerDetails, vehicleData, loading }) => {

    const range = getRange(vehicleData?.engineType, vehicleData?.fuelRange, vehicleData?.batteryRange);
    const updatedOn = formatUpdateStatus(vehicleData?.tmcStatusCode, vehicleData?.tmcUpdatedTime);
    const fuelTypeDisplay = getFuelTypeDisplay(vehicleData?.fuelType);
    const odometerDisplay = formatValue(vehicleData?.odometer, ' miles');
    const fuelLevelDisplay = formatValue(vehicleData?.fuelLevel, '% full');
    const batteryStateOfChargeDisplay = formatValue(vehicleData?.batteryStateOfCharge, '%');
    const engineOilPercentDisplay = formatValue(vehicleData?.engineOilPercent, '%');

    return (
        <div style={{ margin: '5px 50px 0 50px', padding: '15px', background: '#FAFAFA', borderTop: '0.25px solid #B3B3B3', borderBottom: '0.25px solid #B3B3B3' }}>
            <div>
                <div style={styles.profileHeadingContainer}>
                    <div style={styles.profileHeadingText}>Vehicle Information</div>
                    <div style={styles.profileTimestamp}>{!loading && updatedOn}</div>
                </div>
                <table style={{ margin: '20px 0' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '250px', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                <p style={styles.profileTimestamp}>Fuel Type</p>
                                <p style={styles.profileDataText}>{fuelTypeDisplay}</p>
                            </td>
                            <td>
                                <p style={styles.profileTimestamp}>Modem Status</p>
                                <p style={styles.profileDataText}>{vehicleData && getModemStatus(vehicleData?.tmcStatusCode)}</p>
                            </td>
                            <td style={{ width: '250px', padding: '10px 0' }}>
                                <p style={styles.profileTimestamp}>Trim</p>
                                <p style={styles.profileDataText}>
                                    {customerDetails && (customerDetails?.vehicleInfo?.vehicleTrim
                                        ? titleCase(customerDetails?.vehicleInfo?.vehicleTrim)
                                        : '-')}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: '250px', padding: '10px 0' }}>
                                <p style={styles.profileTimestamp}>Odometer</p>
                                <p style={styles.profileDataText}>
                                    {vehicleData && vehicleData?.engineType ? odometerDisplay : '-'}
                                </p>
                            </td>
                            <td style={{ width: '250px', padding: '10px 0' }}>
                                <p style={styles.profileTimestamp}>Color</p>
                                <p style={styles.profileDataText}>
                                    {customerDetails && (customerDetails?.vehicleInfo?.vehicleColor
                                        ? titleCase(customerDetails?.vehicleInfo?.vehicleColor)
                                        : '-')}
                                </p>
                            </td>
                            {shouldDisplayFuelLevel(vehicleData?.engineType) && (
                                <td style={{ width: '250px', padding: '10px 0' }}>
                                    <p style={styles.profileTimestamp}>Fuel Level</p>
                                    <p style={styles.profileDataText}>{fuelLevelDisplay}</p>
                                </td>
                            )}
                            {shouldDisplayStateOfCharge(vehicleData?.engineType) && (
                                <td style={{ width: '250px', padding: '10px 0' }}>
                                    <p style={styles.profileTimestamp}>State of Charge</p>
                                    <p style={styles.profileDataText}>{batteryStateOfChargeDisplay}</p>
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td style={{ width: '250px', padding: '10px 0' }}>
                                <p style={styles.profileTimestamp}>Range</p>
                                <p style={styles.profileDataText}>
                                    {range !== undefined && range !== null ? `${range} miles` : '-'}
                                </p>
                            </td>
                            {shouldDisplayFuelLevel(vehicleData?.engineType) && (
                                <td style={{ width: '250px', padding: '10px 0' }}>
                                    <p style={styles.profileTimestamp}>Engine Oil Life</p>
                                    <p style={styles.profileDataText}>{engineOilPercentDisplay}</p>
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

VehicleInfo.propTypes = {
    customerDetails: PropTypes.shape({
        vehicleInfo: PropTypes.shape({
            vehicleTrim: PropTypes.string,
            vehicleColor: PropTypes.string,
        }),
    }),
    vehicleData: PropTypes.shape({
        engineType: PropTypes.oneOf(['ICE', 'Hybrid', 'Electric']),
        fuelType: PropTypes.string,
        fuelRange: PropTypes.number,
        batteryRange: PropTypes.number,
        tmcStatusCode: PropTypes.number,
        tmcUpdatedTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        odometer: PropTypes.number,
        fuelLevel: PropTypes.number,
        batteryStateOfCharge: PropTypes.number,
        engineOilPercent: PropTypes.number,
    }),
};

export default VehicleInfo;
