import { ErrorOutline } from '@mui/icons-material';
import { Container, Typography } from '@mui/material';
import React from "react";

const ErrorPage = ({ isApiError }) => {
  console.log("isApiError", isApiError)
  return (
    <Container component="main" sx={styles.container}>
      <ErrorOutline style={{ fontSize: '80px', color: '#f44336' }} />
        {isApiError?.code ? <Typography component="h1" variant="h4" sx={styles.title}>
          Status Code: {isApiError?.code}
        </Typography>: ''}
      <Typography component="h1" variant="h4" sx={styles.title}>
        Oops! Something went wrong 
      </Typography>
      <Typography variant="body1" sx={styles.message}>
        There was a problem connecting to the site <br /> Please <b>refresh the page</b> or check your connection.
      </Typography>
    </Container>
  )
}

/** @type {import("@mui/material").SxProps} */
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  title: {
    marginBottom: 2,
  },
  message: {
    marginBottom: 4,
  },
  button: {
    marginTop: 2,
    color: '#fff'
  },
};

export default ErrorPage;