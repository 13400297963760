import { Box } from '@mui/material';
import { styles } from './AIGuide.style';
import React from 'react';

const QuestionBox = (question) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Box sx={styles.questionText}>{question.question}</Box>
    </Box>
  );
};

export default QuestionBox;
