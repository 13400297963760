import { useState, useEffect, useContext } from "react";
import { hasActionAccess } from "../utils/permissions";
import issueService from "../services/issueService";
import { capitalizeFirstWordAndFormat, titleCase } from "../utils/stringUtils";
import { track } from '@amplitude/analytics-browser';
import { GlobalStateContext } from "../context/GlobalStateContext";
import { getImpersonateData } from "../utils/impersonateUtils";

const useAlertStatus = (
  roleConfig,
  isCustomers,
  state,
  setState,
  selectedFilters
) => {
  const [selectedAlertStatuses, setSelectedAlertStatuses] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { customerDetails, setCustomerDetails, setCustomers } = useContext(GlobalStateContext);

  useEffect(() => {
    if (customerDetails?.alerts) {
      const statuses = customerDetails.alerts.reduce((acc, alert) => ({
        ...acc,
        [alert.id]: alert.status
      }), {});
      setSelectedAlertStatuses(statuses);
    }
  }, [customerDetails?.alerts]);

  const updateIssues = (alertId, newStatus, issues) => {
    return issues.map((job) => ({
      ...job,
      alerts: job.alerts ? updateAlertStatus(alertId, newStatus, job.alerts) : [],
    }));
  };

  const checkPermission = (roleConfig) => {
    if (!hasActionAccess(roleConfig, 'customer_detail', 'status_update')) {
      alert('Permission Denied');
      return false;
    }
    return true;
  };

  const formatStatusUpdateText = (alert, originalStatus, newStatus) => {
    const isTransitionFromCompletedOrCancelled = ["Completed", "Cancelled"].includes(originalStatus) && newStatus !== originalStatus;
    const isSpecialStatus =
      (["Completed", "Cancelled"].includes(newStatus) && !["Completed", "Cancelled"].includes(originalStatus)) ||
      (["Completed", "Cancelled"].includes(originalStatus) && !["Completed", "Cancelled"].includes(newStatus));
    const autoHideDuration = isSpecialStatus ? null : 3000;
    const formattedTitle = alert.alertCategory === 'FSA'
      ? capitalizeFirstWordAndFormat(alert.alertTitle)
      : titleCase(alert.alertTitle);
      let statusUpdateText;
      if (isTransitionFromCompletedOrCancelled) {
        statusUpdateText = `Status changed from <strong>${originalStatus}</strong> to <strong>${newStatus}</strong> successfully.`;
      } else if (isSpecialStatus) {
        statusUpdateText = `<strong>'${formattedTitle}' Alert can be found in the Alert History.</strong> Click on the customer and then the Alert History Tab to view the alert details.`;
      } else {
        statusUpdateText = `Status changed from <strong>${originalStatus}</strong> to <strong>${newStatus}</strong> successfully.`;
      }

    return { statusUpdateText, autoHideDuration };
  };

  const updateCustomerAlerts = (alertId, newStatus, setCustomers, selectedFilters) => {
    setCustomers(prevCustomers =>
      prevCustomers.map(customer => ({
        ...customer,
        alerts: customer.alerts.map(alert =>
          alert.id === alertId ? { ...alert, status: newStatus } : alert
        )
      }))
    );
  };

  const updateCustomerDetails = (customerDetails, alertId, newStatus, setCustomerDetails) => {
    const updatedActiveAlerts = (customerDetails.activeAlerts).map(alert =>
      alert.id === alertId ? { ...alert, status: newStatus } : alert
    );
    const updatedInActiveAlerts = (customerDetails.inActiveAlerts).map(alert =>
      alert.id === alertId ? { ...alert, status: newStatus } : alert
    );

    const newActiveAlerts = [
      ...updatedActiveAlerts.filter(alert => !["Completed", "Cancelled"].includes(alert.status)),
      ...updatedInActiveAlerts.filter(alert => !["Completed", "Cancelled"].includes(alert.status))
    ];
    const newInActiveAlerts = [
      ...updatedInActiveAlerts.filter(alert => ["Completed", "Cancelled"].includes(alert.status)),
      ...updatedActiveAlerts.filter(alert => ["Completed", "Cancelled"].includes(alert.status))
    ];

    setCustomerDetails({
      ...customerDetails,
      activeAlerts: newActiveAlerts,
      inActiveAlerts: newInActiveAlerts
    });
  };  


  const handleStatusChange = async (event, alertId, originalStatus, alert) => {
    if (!checkPermission(roleConfig)) return;

    const reason = getImpersonateData() ? JSON.parse(getImpersonateData())?.reason : null;

    try {
      const newStatus = event.value;
      track('Status Clicked', {
        location: 'Customer detail',
        fromStatus: originalStatus,
        toStatus: newStatus,
      });

      setSelectedAlertStatuses((prev) => ({
        ...prev,
        [alertId]: newStatus,
      }));

      await issueService.updateStatusValue(alertId, newStatus, reason);

      const { statusUpdateText, autoHideDuration } = formatStatusUpdateText(alert, originalStatus, newStatus);

      setSnackbarMessage('Status updated successfully');
      setSnackbarOpen(true);

      if (isCustomers) {
        updateCustomerAlerts(alertId, newStatus, setCustomers, selectedFilters);
        updateCustomerDetails(customerDetails, alertId, newStatus, setCustomerDetails);

        setState((prev) => ({
          ...prev,
          statusUpdatePopUp: true,
          autoHideStatus: autoHideDuration,
          originalStatus: newStatus,
          snackbarMessage: statusUpdateText,
        }));
      } else {
        const updatedIssues = updateIssues(alertId, newStatus, state?.issues);
        setState((prev) => ({
          ...prev,
          issues: updatedIssues,
          statusUpdatePopUp: true,
          statusUpdatedData: updatedIssues.find((job) =>
            job.alerts?.some((alert) => alert.id === alertId)
          ),
          issueId: null,
        }));
        if (newStatus) {
          setState((prev) => ({
            ...prev,
            isIssuesLoaded: false,
          }));
        }
      }
    } catch (error) {
      console.error("Error updating status:", error);
      setSnackbarMessage('Failed to update status');
      setSnackbarOpen(true);
    }
  };

  return {
    selectedAlertStatuses,
    snackbarOpen,
    snackbarMessage,
    handleStatusChange,
    setSnackbarOpen
  };
}

export {
  useAlertStatus
}