import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';
import {
  Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography, CircularProgress, Chip, Grid, Select, MenuItem, Snackbar, Alert, Tooltip,
  Button, styled, IconButton
} from '@mui/material';
import moment from 'moment';
import customerService from '../../services/customerService';
import issueService from '../../services/issueService';
import PriorityBadge from '../../components/PriorityBadge';
import { capitalize } from '../../utils/capitalize';
import { getAlertDetails } from '../../utils/getAlertDetails';
import { ArrowDropDown, ArrowDropUp, Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { Icon } from "@ford/ford-ui-components";
import { useRole } from '../../context/RoleContext';
import { getToken, parseJwt } from '../../utils/auth';
import { getUserName } from '../../config/AuthenticatedRoute';
import { hasPageAccess, hasActionAccess } from '../../utils/permissions';
import ErrorPage from '../../components/ErrorPage';
import FilterSection from './FilterSection';
import { truncate } from '../../utils/truncate';
import IssuesDrawer from "../IssueDetail/Drawer";
import CustomPagination from '../../components/CustomPagination';
import CustomSnackbar from './CustomSnackbar';
import CustomerSearch from './CustomerSearch';
import CustomerExport from './CustomerExport';
import { track } from '@amplitude/analytics-browser';
import { GlobalStateContext } from '../../context/GlobalStateContext';
import { displayMRAIcon } from '../../utils/displayMRAIcon';
import { getImpersonateData } from "../../utils/impersonateUtils";

const CustomerList = () => {
  const [loading, setLoading] = useState(true);
  const [hoveredCustomer, setHoveredCustomer] = useState(null);
  const [page, setPage] = useState(() => {
    const savedPage = localStorage.getItem('page');
    return savedPage !== null ? JSON.parse(savedPage) : 0;
  });
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = localStorage.getItem('rowsPerPage');
    return savedRowsPerPage !== null ? JSON.parse(savedRowsPerPage) : 10;
  });
  const [totalItems, setTotalItems] = useState(0);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [isFiltersLoaded, setIsFiltersLoaded] = useState(false);
  const [assigningCustomerId, setAssigningCustomerId] = useState(null);
  const [unassigningCustomerId, setUnassigningCustomerId] = useState(null);

  const globalCount = useRef(parseInt(localStorage.getItem('globalCount')) || 1);
  const filters = useMemo(() => ({
    customerCategory: [],
    priority: [],
    alertType: [],
    alertStatus: [],
    vehicleMake: [],
    mra: [],
    vin: [],
    alertTitle: [],
    assignee: []
  }), []);

  const [selectedFilters, setSelectedFilters] = useState(() => {
    const localFilters = localStorage.getItem('selectedFilters');
    return localFilters ? JSON.parse(localFilters) : filters;
  });

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: '#FFFFFF',
      color: '#000',
      border: '1px solid #808080',
      fontSize: '14px',
    },
  });

  const columnHeaders = useMemo(() => [
    { label: 'Customer', width: "7%" },
    { label: 'VIN', width: "7%" },
    { label: 'Make', width: "5%" },
    { label: 'Alerts', width: "28%" },
    { label: 'Mobile' },
    { label: 'Priority' },
    { label: 'Created On' },
    { label: 'Status' },
    { label: 'Assignee' }
  ], []);

  const [state, setState] = useState({
    drawerOpen: false, isIssuesLoaded: false, statusUpdatePopUp: false,
    snackbarMessage: '', autoHideStatus: null, snackbarSeverity: 'success', originalStatus: ''
  });

  const [isApiError, setIsApiError] = useState(null);
  const [customSnackbarOpen, setCustomSnackbarOpen] = useState(() => {
    const isSnackbarShown = sessionStorage.getItem('snackbarShown');
    return isSnackbarShown ? JSON.parse(isSnackbarShown) : true;
  });

  const [sortingParams, setSortingParams] = useState(() => {
    const savedSortingParams = localStorage.getItem('sortingParams');
    return savedSortingParams ? JSON.parse(savedSortingParams) : {
      sortingKey: '', direction: '',
    };
  });


  const { roleConfig } = useRole();
  const { customers, setCustomers } = useContext(GlobalStateContext);

  const loggedUserName = getUserName();
  const loggedUserId = useMemo(() => {
    const token = getToken();
    if (!token) return null;
    const parsedToken = parseJwt(token);
    return parsedToken?.sub; // Assuming 'sub' is the claim for user ID
  }, []);

  const handleAssignToMe = async (customerId) => {
    setAssigningCustomerId(customerId);
    try {
      await customerService.assignCustomer(customerId, loggedUserId, loggedUserName);

      setCustomers(prevCustomers =>
        prevCustomers.map(customer =>
          customer.vehicleProfileId === customerId
            ? { ...customer, assigneeId: loggedUserId, assigneeName: loggedUserName }
            : customer
        )
      );

      setState(prevState => ({
        ...prevState,
        snackbarMessage: 'Customer successfully assigned to you.',
        snackbarSeverity: 'success',
        statusUpdatePopUp: true,
      }));
      track('Customer Assigned', { vehicleProfileId: customerId, assigned: loggedUserId });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Handle the 409 Conflict error
        setState(prevState => ({
          ...prevState,
          snackbarMessage: 'Already assigned to another user. Someone else just assigned themselves to this customer.',
          snackbarSeverity: 'error',
          statusUpdatePopUp: true,
        }));
      } else {
        // Handle other errors
        setState(prevState => ({
          ...prevState,
          snackbarMessage: 'Failed to assign customer.',
          snackbarSeverity: 'error',
          statusUpdatePopUp: true,
        }));
      }
    } finally {
      setAssigningCustomerId(null); // Reset loading state
    }
  };

  const handleUnassign = async (customerId, assigneeId) => {
    setUnassigningCustomerId(customerId);
    try {
      await customerService.unassignCustomer(customerId, assigneeId);

      setCustomers(prevCustomers =>
        prevCustomers.map(customer =>
          customer.vehicleProfileId === customerId ? { ...customer, assigneeId: null, assigneeName: null } : customer
        )
      );

      setState(prevState => ({
        ...prevState,
        snackbarMessage: 'Customer successfully unassigned.',
        snackbarSeverity: 'success',
        statusUpdatePopUp: true,
      }));
      track('Customer Unassigned', { vehicleProfileId: customerId, assigned: assigneeId });
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        snackbarMessage: 'Failed to unassign customer.',
        snackbarSeverity: 'error',
        statusUpdatePopUp: true,
      }));
    } finally {
      setUnassigningCustomerId(null);
    }
  };

  const fetchCustomerAlerts = useCallback(async (page, pageSize) => {
    try {
      setLoading(true);
      setCustomers([]);
      setTotalItems(0);

      const filterParams = Object.entries(selectedFilters)
        .filter(([_, values]) => values.length > 0)
        .map(([filterKey, filterValues]) => ({
          filterKey,
          filterValues: filterValues.map(item => item.value)
        }));

      const sortingKeys = { priority: '', alertDatetime: '', status: '' };
      const sortingPayload = sortingParams.sortingKey ? {
        defaultSort: sortingParams.direction === '',
        sortingKey: { ...sortingKeys, [sortingParams.sortingKey]: sortingParams.direction },
      } : { defaultSort: true, sortingKey: sortingKeys };

      const response = await customerService.getCustomerAlerts(page + 1, pageSize, filterParams, sortingPayload);
      const totalItems = response.data.pagination.totalItems;
      const totalPages = Math.ceil(totalItems / pageSize);

      if (page >= totalPages) {
        setPage(0);
      } else {
        setCustomers(response.data.customerBasedAlerts);
        setTotalItems(totalItems);
      }
    } catch (error) {
      setIsApiError({ isError: true, message: error?.message, code: error?.response?.status });
    } finally {
      setLoading(false);
    }
  }, [selectedFilters, setPage, sortingParams]);

  useEffect(() => {
    track('User Page Viewed', { page: 'Customer List' });
  }, []);

  useEffect(() => {
    if (isFiltersLoaded) {
      fetchCustomerAlerts(page, rowsPerPage);
    }
  }, [page, rowsPerPage, selectedFilters, fetchCustomerAlerts, sortingParams, isFiltersLoaded]);

  const fetchFilterValues = useCallback(async () => {
    try {
      const response = await customerService.getFilterValues();
      setFilterValues(response.data);

      const savedFilters = localStorage.getItem('selectedFilters');
      if (!savedFilters) {
        setSelectedFilters(prev => ({
          ...prev,
          alertStatus: (response.data.activeAlertStatus || []).map((value) => ({ value, position: 0 }))
        }));
      } else {
        setSelectedFilters(JSON.parse(savedFilters));
      }
      setIsFiltersLoaded(true);
    } catch (error) {
      console.error('Failed to fetch filter values', error);
      setIsApiError({ isError: true, message: error?.message, code: error?.response?.status });
    }
  }, []);

  useEffect(() => {
    fetchFilterValues();
  }, [fetchFilterValues]);

  useEffect(() => {
    localStorage.setItem('sortingParams', JSON.stringify(sortingParams));
    localStorage.setItem('page', JSON.stringify(page));
    localStorage.setItem('rowsPerPage', JSON.stringify(rowsPerPage));
    localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));

  }, [sortingParams, page, rowsPerPage, selectedFilters]);

  const handleCustomSnackbarClose = useCallback((_, reason) => {
    if (reason === 'clickaway') return;
    setCustomSnackbarOpen(false);
    sessionStorage.setItem('snackbarShown', 'false');
  }, []);

  const handleSortChange = useCallback((headerLabel) => {
    const keyMap = { 'Priority': 'priority', 'Created On': 'alertDatetime', 'Status': 'status' };
    const key = keyMap[headerLabel];

    setSortingParams((prev) => {
      const isSameKey = prev.sortingKey === key;
      let newDirection;
      if (isSameKey) {
        newDirection = prev.direction === 'dsc' ? 'asc' : prev.direction === 'asc' ? '' : 'dsc';
      } else {
        newDirection = 'dsc';
      }

      track('Sort Clicked', { column: headerLabel, direction: newDirection || 'default' });

      return { sortingKey: key, direction: newDirection };
    });
  }, []);

  const handlePageChange = useCallback((_, newPage) => {
    track('Pagination Arrow Clicked', { direction: newPage > page ? '>' : '<', pageNumber: newPage + 1 });
    setPage(newPage);
  }, [page]);

  const handleRowsPerPageChange = useCallback((event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    track('Customer Per Page Clicked', { selectedEntries: newRowsPerPage });
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }, []);

  const toggleDropdown = useCallback((key) => setOpenDropdowns(prev => ({ ...prev, [key]: !prev[key] })), []);

  const handleStatusChange = useCallback(async (event, alertId, originalStatus, alertTitle) => {
    if (!hasActionAccess(roleConfig, 'list_alert', 'status_update')) {
      alert('Permission Denied');
      return;
    }
    const newStatus = event?.target?.value;
    const reason = getImpersonateData() ? JSON.parse(getImpersonateData())?.reason : null;
    try {
      track('Status Clicked', { location: 'Customer List', fromStatus: originalStatus, toStatus: newStatus });
      await issueService.updateStatusValue(alertId, newStatus, reason);
      setCustomers(prevCustomers => prevCustomers.map(customer => ({
        ...customer,
        alerts: customer.alerts.map(alert => alert.id === alertId ? { ...alert, status: newStatus } : alert)
      })));

      const isSpecialStatus = ["Completed", "Cancelled"].includes(newStatus) || ["Completed", "Cancelled"].includes(originalStatus);
      const isTransitionFromCompletedOrCancelled = ["Completed", "Cancelled"].includes(originalStatus) && newStatus !== originalStatus;
      let statusUpdateText;

      if (isTransitionFromCompletedOrCancelled) {
        statusUpdateText = `Status changed from <strong>${originalStatus}</strong> to <strong>${newStatus}</strong> successfully.`;
      } else if (isSpecialStatus) {
        statusUpdateText = `<strong>'${getAlertDetails(alertTitle, true, 80)}' Alert can be found in the Alert History.</strong> Click on the customer and then the Alert History Tab to view the alert details.`;
      } else {
        statusUpdateText = `Status changed from <strong>${originalStatus}</strong> to <strong>${newStatus}</strong> successfully.`;
      }

      const autoHideDuration = isSpecialStatus ? null : 3000;

      setState(prev => ({
        ...prev,
        isIssuesLoaded: false,
        statusUpdatePopUp: true,
        autoHideStatus: autoHideDuration,
        originalStatus: newStatus,
        snackbarSeverity: 'success',
        snackbarMessage: statusUpdateText,
      }));
    } catch (error) {
      console.error("Error updating status:", error);
      setIsApiError({ isError: true, message: error?.message, code: error?.response?.status });
      setState(prev => ({
        ...prev,
        isIssuesLoaded: false,
        statusUpdatePopUp: true,
        autoHideStatus: 3000,
        originalStatus: newStatus,
        snackbarSeverity: 'error',
        snackbarMessage: 'Failed to Update Status',
      }));
    }
  }, [roleConfig, setCustomers]);

  const handleMouseEnter = useCallback((vin) => {
    setHoveredCustomer(vin);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredCustomer(null);
  }, []);

  const handleDetailModel = useCallback((alertId, customer) => {
    if (!hasActionAccess(roleConfig, 'list_alert', 'view_alert_detail')) {
      alert('Permission Denied');
      return;
    }
    // Pass alertId directly to the drawer
    setState(prev => ({
      ...prev,
      drawerOpen: true,
      isIssuesLoaded: false,
      alertId, // New addition
      customerName: customer.customerName,
      vehModelYear: customer.vehModelYear,
      vin: customer.vin,
      vehMake: customer.vehMake,
      vehModel: customer.vehModel,
      vehicleProfileId: customer.vehicleProfileId,
      isFromCustomerList: true
    }));
  }, [roleConfig]);

  const handleDrawerClose = useCallback(() => {
    setState(prev => ({ ...prev, drawerOpen: false, isIssuesLoaded: false }));
  }, []);

  const renderCustomerNoneCell = (customer, alert) => (
    <>
      <TableCell onClick={() => handleDetailModel(alert?.id, customer)} style={{ width: '30%' }}>
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Chip label={customer.customerCategory || 'N/A'} style={{ fontSize: "16px", borderRadius: "3px", fontWeight: "400", height: "24px", width: "69px", background: customer.customerCategory === 'Care+' ? '#B8D8FF' : '#E5E5E5' }} />
          <Box title={customer.customerName.length > 16 ? capitalize(customer.customerName) : ''}>
            {truncate(capitalize(customer.customerName), 19)}
          </Box>
        </Box>
      </TableCell>
      <TableCell onClick={() => handleDetailModel(alert?.id, customer)} title={customer?.vin?.length > 20 ? customer?.vin : ''} style={{ width: '10%' }}>
        {customer?.vin?.length > 20 ? customer?.vin.slice(0, 30) + "..." : customer?.vin}
      </TableCell>
      <TableCell onClick={() => handleDetailModel(alert?.id, customer)} style={{ width: '30%' }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Grid item>
              <Box>
                No Alerts
              </Box>
            </Grid>
          </Grid>
        </Box>
      </TableCell>
      <TableCell onClick={() => handleDetailModel(alert?.id, customer)} style={{ width: '15%' }}>
        <PriorityBadge priority={"None"} />
      </TableCell>
      <TableCell onClick={() => handleDetailModel(alert?.id, customer)} style={{ width: '15%' }}></TableCell>
      <TableCell onClick={() => handleDetailModel(alert?.id, customer)} style={{ width: '25%' }}>
      </TableCell>
    </>
  );

  const renderCustomerCell = useCallback((customer, index, alert) => {
    if (index === 0) {
      return (
        <>
          <TableCell style={{ width: '12%' }} onClick={() => handleDetailModel(alert?.id, customer)}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Box title={customer.customerName.length > 16 ? capitalize(customer.customerName) : ''}>
                {truncate(capitalize(customer.customerName), 19)}
              </Box>
            </Box>
          </TableCell>
          <TableCell
            onClick={() => handleDetailModel(alert?.id, customer)} title={customer?.vin?.length > 20 ? customer?.vin : ''}
            style={{ width: '8%' }}
          >
            {customer?.vin?.length > 20 ? customer?.vin.slice(0, 20) + "..." : customer?.vin}
          </TableCell>
          <TableCell style={{ width: "5%" }} onClick={() => handleDetailModel(alert?.id, customer)}>
            {customer?.vehMake || 'N/A'}
          </TableCell>
        </>
      );
    }
    return (
      <>
        <TableCell onClick={() => handleDetailModel(alert?.id, customer)} />
        <TableCell onClick={() => handleDetailModel(alert?.id, customer)} />
        <TableCell onClick={() => handleDetailModel(alert?.id, customer)} />
      </>
    );
  }, [handleDetailModel]);

  const renderAlertCell = useCallback((alert, dropdownKey, customer, hovered) => (
    <>
      <TableCell
        onClick={() => handleDetailModel(alert?.id, customer)}
        title={alert?.alertDetails?.length > 40 ? getAlertDetails(alert, false, 40) : undefined}
        style={{ width: '25%' }}
      >
        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Grid item>
              <Chip
                label={alert?.alertType.includes("Prognostics") ? alert?.alertType.slice(0, 4).toUpperCase() : alert?.alertType}
                sx={{ fontSize: "16px", textTransform: "uppercase", marginRight: 4, borderRadius: "3px", fontWeight: "400", height: "24px", width: "75px", background: `${hovered ? '#e6e6e6' : ''}`, border: `${hovered && '1px solid rgba(0,0,0,0.2)'}` }}
              />
            </Grid>
            <Grid item>
              <Box>
                {getAlertDetails(alert, true, 40)}
                {alert?.vehicleAlertStatus && alert?.vehicleAlertStatus !== "EXISTING_ALERT" && (
                  <span style={{ marginLeft: "5px" }}>
                    ({alert?.vehicleAlertStatus})
                  </span>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </TableCell>
      <TableCell onClick={() => handleDetailModel(alert?.id, customer)}>
        <Box>
          <LightTooltip title={alert?.mra_description} placement='top'>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img
                src={displayMRAIcon(alert?.mra)}
                alt={alert?.mra}

              />
            </div>
          </LightTooltip>
        </Box>
      </TableCell>
      <TableCell onClick={() => handleDetailModel(alert?.id, customer)}>
        <PriorityBadge priority={alert.severity} />
      </TableCell>
      <TableCell onClick={() => handleDetailModel(alert?.id, customer)}>
        {alert?.alertDatetime && alert.alertDatetime !== "NA" ? moment(alert.alertDatetime).format("MM-DD-YYYY") : "NA"}
      </TableCell>
      <TableCell>
        {alert.status !== "None" && (
          <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "2px" }}>
            <Select
              value={alert.status}
              role='button'
              label="Status"
              onOpen={() => toggleDropdown(dropdownKey)}
              onClose={() => toggleDropdown(dropdownKey)}
              sx={{
                marginLeft: '-15px',
                fontSize: '14px',
                fontWeight: 'bold',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                  border: 0,
                  outline: 'none'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  outline: 'none'
                }
              }}
              style={{
                color: "#0562D2", width: "140px",
              }}
              IconComponent={() => null}
            >
              {["Open", "Unreachable", "Contacted", "Scheduled", "In Progress", "On Hold", "Completed", "Cancelled"].map((status) => (
                <MenuItem
                  sx={{ fontWeight: "bold", fontSize: '14px' }}
                  style={{ color: "#0562D2" }}
                  key={status}
                  value={status}
                  onClick={() => handleStatusChange({ target: { value: status } }, alert.id, alert.status, alert)}
                >
                  {status}
                </MenuItem>
              ))}
            </Select>
            <Box sx={{ marginLeft: "-35px" }}>
              {openDropdowns[dropdownKey] ?
                <Icon name="chevronUp" className="chevron-icon" />

                :
                <Icon name="chevronDown" className="chevron-icon" />
              }
            </Box>
          </Box>
        )}
      </TableCell>
    </>
  ), [handleDetailModel, handleStatusChange, openDropdowns, toggleDropdown]);

  const renderAssigneeCell = (customer, index) => {
    if (index !== 0) return <TableCell />;
    if (assigningCustomerId === customer.vehicleProfileId) { // Check if this customer is being assigned
      return (
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold", fontSize: '14px' }} style={{ color: "#0562D2" }}>
            <CircularProgress size={16} sx={{ mr: 1, color: '#0562D2' }} />
            Assigning...
          </Box>
        </TableCell>
      );
    } else if (unassigningCustomerId === customer.vehicleProfileId) {
      return (
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold", fontSize: '14px' }} style={{ color: "#0562D2" }}>
            <CircularProgress size={16} sx={{ mr: 1, color: '#0562D2' }} />
            Unassigning...
          </Box>
        </TableCell>
      );
    } else if (!customer.assigneeId || customer.assigneeId === '') {
      return (
        <TableCell onClick={() => handleAssignToMe(customer.vehicleProfileId)}>
          <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", fontWeight: "bold", fontSize: '14px', marginRight: "50px" }} style={{ color: "#0562D2" }}>

            <IconButton size="small">
              <AddIcon fontSize="small" sx={{ color: '#0562D2', fontWeight: 'bold' }} />
            </IconButton>
            <Box>
              Assign to me
            </Box>
          </Box>
        </TableCell>
      );
    } else if (customer.assigneeId === loggedUserId) {
      return (
        <TableCell onClick={() => handleUnassign(customer.vehicleProfileId, customer.assigneeId)}>
          <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", fontWeight: "bold", fontSize: '14px', marginRight: "50px" }} style={{ color: "#0562D2" }}>
            <IconButton size="small">
              <CloseIcon fontSize="small" sx={{ color: '#0562D2', fontWeight: 'bold' }} />
            </IconButton>
            {customer.assigneeName}
          </Box>
        </TableCell>
      );
    } else {
      return (
        <TableCell>
          <Box sx={{ textAlign: "center"}}>
          {customer.assigneeName}
          </Box>
        </TableCell>
      );
    }
  };

  const handleVinSelect = useCallback((vin) => {
    setSelectedFilters((prev) => {
      const currentVins = prev.vin || [];
      if (!currentVins.some((item) => item.value === vin)) {
        return {
          ...prev,
          vin: [...currentVins, { value: vin, position: globalCount.current++ }]
        };
      }
      return prev;
    });
  }, []);

  const renderLoader = useMemo(() => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        position: 'fixed',
        top: "50%",
        left: "50%",
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 1000
      }}
    >
      <CircularProgress />
    </Box>
  ), []);

  if (isApiError) {
    return <ErrorPage isApiError={isApiError} />;
  }

  return (
    <Container data-testid="jobs-list-container" maxWidth={false}>
      <Box sx={{ padding: "30px 0" }}>
        <Typography variant="h5" sx={{ fontSize: '40px', fontWeight: 400, lineHeight: '42px' }}>
          <b>Active Alerts</b> {totalItems > 0 && `(${totalItems})`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, marginBottom: '20px' }}>
        {hasActionAccess(roleConfig, 'list_customer', 'search_vin') && (
          <CustomerSearch onVinSelect={handleVinSelect} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} setIsApiError={setIsApiError} />
        )}
        {hasActionAccess(roleConfig, 'list_customer', 'export_customer') && (
          <CustomerExport totalItems={totalItems} selectedFilters={selectedFilters} sortingParams={sortingParams} setIsApiError={setIsApiError} loading={loading} />
        )}
      </Box>
      <FilterSection
        filterValues={filterValues}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        globalCount={globalCount}
        roleConfig={roleConfig}
      />
      <TableContainer component={Paper}
        sx={{
          borderLeft: "none",
          borderRight: "none",
          boxShadow: "none",
          borderTop: "1px solid #f6f3f3",
          marginTop: "1rem",
        }}
      >
        {loading && renderLoader}
        <Table>
          <TableHead>
            <TableRow>
              {columnHeaders.map(({ label, width }) => {
                const isSortable = ['Priority', 'Created On', 'Status'].includes(label);
                let sortingKey;

                if (label === 'Created On') {
                  sortingKey = 'alertDatetime';
                } else if (label === 'Status') {
                  sortingKey = 'status';
                } else {
                  sortingKey = 'priority';
                }

                const isCurrentSortKey = sortingParams.sortingKey === sortingKey;
                const iconDirection = sortingParams.direction === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />;
                const iconColor = isCurrentSortKey && sortingParams.direction !== '' ? '#000000' : '#bfbfbf';

                // Determine the alignment style based on the label
                const alignmentStyle = label === 'Mobile Service' ? { textAlign: 'center' } : {};

                return (
                  <TableCell
                    key={label}
                    sx={{
                      color: "#666",
                      width,
                      fontSize: "14px",
                      fontFamily: "ford f1 bold",
                      ...alignmentStyle, // Apply the alignment style conditionally
                    }}
                    onClick={isSortable ? () => handleSortChange(label) : undefined}
                  >
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div>
                        {label}
                      </div>
                      <div>
                        {isSortable && (
                          <span style={{ marginLeft: `${label === 'Status' ? '52px' : '5px'}`, fontSize: '20px', color: iconColor }}>
                            {isCurrentSortKey ? iconDirection : <ArrowDropDown style={{ color: '#bfbfbf' }} />}
                          </span>
                        )}
                      </div>
                    </div>
                  </TableCell>
                );
              })}

            </TableRow>
          </TableHead>

          <TableBody>
            {!loading && customers.length === 0 && (
              <TableRow>
                <TableCell colSpan={columnHeaders.length} sx={{ padding: "20px", textAlign: "center" }}>
                  <Typography variant="body1" sx={{ color: "#666666" }}>
                    No customer alerts found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {customers.map((customer, customerIndex) => {
              const rowColor = customerIndex % 2 === 0 ? '#ffffff' : '#fafafa';

              if (customer.alerts.length === 0) {
                return (
                  <TableRow
                    sx={{
                      backgroundColor: hoveredCustomer === customer.vin ? 'rgba(0, 0, 0, 0.1)' : rowColor,
                      transition: 'background-color 0.3s ease',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      },
                    }}
                    key={customer.vin}
                    onMouseEnter={() => handleMouseEnter(customer.vin)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {renderCustomerNoneCell(customer, null)}
                  </TableRow>
                );
              }
              return customer.alerts.map((alert, index) => {
                const dropdownKey = `${customer.vin}-${alert.id || index}`;
                const isLastAlert = index === customer.alerts.length - 1;

                return (
                  <TableRow sx={{
                    backgroundColor: hoveredCustomer === customer.vin ? 'rgba(0, 0, 0, 0.1)' : rowColor,
                    transition: 'background-color 0.3s ease',
                    '&:not(:last-child) .MuiTableCell-root': {
                      borderBottom: isLastAlert ? '1px solid rgba(224, 224, 224, 1)' : 'none',
                    }, cursor: 'pointer',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
                  }} key={dropdownKey}
                    onMouseEnter={() => handleMouseEnter(customer.vin)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {renderCustomerCell(customer, index, alert)}
                    {renderAlertCell(alert, dropdownKey, customer, (hoveredCustomer === customer.vin))}
                    {renderAssigneeCell(customer, index)}
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
        {!loading && <CustomPagination
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />}
      </TableContainer>

      <Snackbar
        open={state.statusUpdatePopUp}
        autoHideDuration={state.autoHideStatus}
        onClose={() => setState(prevState => ({ ...prevState, statusUpdatePopUp: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          width: '400px',
          '& .MuiAlert-root': {
            alignItems: 'flex-start',
          },
        }}
      >
        <Alert onClose={() => setState(prevState => ({ ...prevState, statusUpdatePopUp: false }))} severity={state.snackbarSeverity}
          sx={{
            width: '100%',
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: state.snackbarMessage }} />
        </Alert>
      </Snackbar>
      <IssuesDrawer
        state={state}
        setState={setState}
        handleDrawerClose={handleDrawerClose}
        handleStatusChange={handleStatusChange}
        isCustomers={true}
        setIsApiError={setIsApiError}
        selectedFilters={selectedFilters}
        customerName={state.customerName}
        vehModelYear={state.vehModelYear}
        vin={state.vin}
        vehMake={state.vehMake}
        vehModel={state.vehModel}
        vehicleProfileId={state.vehicleProfileId}
      />
      {hasPageAccess(roleConfig, 'bottom_message', 'read') ? (
        <CustomSnackbar
          open={customSnackbarOpen}
          onClose={handleCustomSnackbarClose}
          title="Important Update: The Alerts page will be discontinued on December 20th!"
          description="To improve your experience, we have migrated to a new vehicle alerts table on the homepage of the Vehicle Care Portal. Your data is safe! Contact vcare@ford.com if you have questions."
        />
      ) : null}
    </Container>
  );
};

export default CustomerList;
