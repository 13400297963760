import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function AlertTypeDonutChart({ data, width = 580, height = 580, innerRadius = 70, outerRadius = 150, margin = 50 }) {
  const svgRef = useRef();
  const totalAlerts = data.alertCategoryMetrics.reduce((acc, alert) => acc + alert.count, 0);
  const pieData = data.alertCategoryMetrics.map(({ alertCategory, count }) => ({
    status: alertCategory,
    count,
    percentage: ((count / totalAlerts) * 100).toFixed(1)
  }));

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const colorMapping = {
      VHA: '#1f77b4',
      Prognostics: '#ff7f0e',
      FSA: '#2ca02c'
    };

    const pie = d3.pie().value(d => d.count);
    const arc = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    const labelArc = d3.arc()
      .innerRadius(outerRadius * 1.3)
      .outerRadius(outerRadius * 1.3);

    const arcs = svg.selectAll('.arc')
      .data(pie(pieData))
      .enter()
      .append('g')
      .attr('class', 'arc')
      .attr('transform', `translate(${width / 2 + margin}, ${height / 2 + margin})`);

    arcs.append('path')
      .attr('d', arc)
      .attr('fill', d => colorMapping[d.data.status])
      .attr('stroke', 'white')
      .attr('stroke-width', '2px')
      .style('cursor', 'pointer')
      .transition()
      .duration(750)
      .attrTween('d', function(d) {
        const i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function(t) {
          d.endAngle = i(t);
          return arc(d);
        };
      });

    arcs.append('title')
      .text(d => `Alert Category: ${d.data.status}, Count: ${d.data.count}, percentage: ${d.data.percentage}`);

    // Add percentage labels outside the donut
    arcs.append('text')
    .attr('transform', d => `translate(${labelArc.centroid(d)})`)
    .attr('dy', '0.35em')
    .attr('text-anchor', 'middle')
    .style('fill', 'black')
    .style('font-size', '12px')
    .text(d => `${d.data.status} (${d.data.count}, ${d.data.percentage}%)`);

  }, [pieData, width, height, innerRadius, outerRadius, margin]);

  return (
    <div style={{ margin: `${margin}px` }}>
      <svg ref={svgRef} width={width + 2 * margin} height={height + 2 * margin}></svg>
    </div>
  );
}

export default AlertTypeDonutChart;