// src/containers/IssueDetail/IssueDetailHeader/IssueDetailHeader.jsx
import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const IssueDetailHeader = ({ headingStyle, issue, handleDrawerClose }) => {
  return (
    <Box sx={headingStyle}>
      <Box>
        <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold", wordBreak: "break-word" }} variant="h5" gutterBottom>
          VIN: {issue?.vehicleInfo?.vin}
        </Typography>
        <Typography
          sx={{
            marginTop: "-2px",
            fontWeight: "800",
            fontSize: "0.8rem",
            letterSpacing: "3px",
            wordBreak: "break-word"
          }}
          variant="h6"
        >
          {`${issue?.vehicleInfo?.vehicleModelYear ?? "-"}-${issue?.vehicleInfo?.vehicleMake ?? "-"}-${issue?.vehicleInfo?.vehicleModel ?? "-"}`}
        </Typography>
      </Box>
      <IconButton sx={{ marginRight: "-30px" }} onClick={handleDrawerClose}>
        <Close />
      </IconButton>
    </Box>
  );
};

IssueDetailHeader.propTypes = {
  headingStyle: PropTypes.object,
  issue: PropTypes.object,
  handleDrawerClose: PropTypes.func,
};

export default IssueDetailHeader;
