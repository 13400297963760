import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  TextField,
  Grid,
  IconButton,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from "moment";
import { Typography, Button } from "@ford/ford-ui-components";
import { getUserName } from "../../../config/AuthenticatedRoute";
import ErrorPage from "../../../components/ErrorPage";
import {track} from "@amplitude/analytics-browser";
import { hasActionAccess } from '../../../utils/permissions';
import { useRole } from '../../../context/RoleContext';

const DEFAULT_NOTE_TEXT =
  "Add notes for your colleagues to understand the alerts and statuses above. For example, was the Hold status because of parts or the customer?";

const ServiceNote = ({
  vehicleProfileId,
  issueService
}) => {
  const [showTextarea, setShowTextarea] = useState(false);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [notesLoaded, setNotesLoaded] = useState(false); // New state
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editContent, setEditContent] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [isApiError, setIsApiError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const { roleConfig } = useRole();
  const canViewServiceNotes = hasActionAccess(roleConfig, 'customer_detail', 'service_notes_view');
  const canAddServiceNotes = hasActionAccess(roleConfig, 'customer_detail', 'service_notes_add');
  const canEditServiceNotes = hasActionAccess(roleConfig, 'customer_detail', 'service_notes_edit');
  const canDeleteServiceNotes = hasActionAccess(roleConfig, 'customer_detail', 'service_notes_delete');
  

  useEffect(() => {
    if (vehicleProfileId) {
      fetchNotes(vehicleProfileId);
    }
  }, [vehicleProfileId]);

  const fetchNotes = async (vehicleProfileId) => {
    try {
      const response = await issueService.getNotesByVehicleProfileId(vehicleProfileId);
      setNotes(response.data);
    } catch (error) {
      /* istanbul ignore next */
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response ? error.response.status : null,
      });
    } finally {
      setNotesLoaded(true); // Mark notes as loaded
    }
  };

  const handleAddNoteClick = () => {
    track('Service Note Add Note Button Clicked');
    setShowTextarea(true);
    setEditingNoteId(null); // Close editing if adding a note
  };

  const handleCancelAddNote = () => {
    track('Service Note Add Note Cancelled');
    setShowTextarea(false);
    setNote("");
  };

  const handleSaveNote = async () => {
    track('Service Note Added');
    if (!note.trim() || isSaving) return; // Prevent multiple saves or empty notes

    setIsSaving(true);
    const username = getUserName();
    const noteData = [
      {
        vehicleProfileId,
        content: note,
        createdBy: username,
      },
    ];
    try {
      await issueService.createNote(noteData);
      await fetchNotes(vehicleProfileId);
      setNote("");
      setShowTextarea(false);
      setSnackbarMessage("Note added successfully");
      setSnackbarOpen(true);
    } catch (error) {
      /* istanbul ignore next */
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response ? error.response.status : null,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditClick = async (note) => {
    try {
      track('Service Note Edit Button Clicked');
      const latestNote = await issueService.getNotesByNoteId(note.noteId);

      if (latestNote.data.deleted) {
        setSnackbarMessage("Note has already been deleted");
        setNotes((prevNotes) => prevNotes.filter(n => n.noteId !== note.noteId));
        setSnackbarOpen(true);
        return;
      }
      setEditingNoteId(note.noteId);
      setEditContent(latestNote.data.content);
      setShowTextarea(false); // Close add note if editing a note
    } catch (error) {
      /* istanbul ignore next */
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response ? error.response.status : null,
      });
    }
  };

  /* istanbul ignore next */
  const handleEditSave = async () => {
    track('Service Note Edited');
    if (!editContent.trim() || isSaving) return; // Prevent multiple saves or empty edits
    if (editContent === notes.find((n) => n.noteId === editingNoteId).content) {
      handleCancelEdit(); // Cancel if no changes
      return;
    }

    setIsSaving(true);
    try {
      await issueService.editNote(editingNoteId, editContent);
      await fetchNotes(vehicleProfileId);
      setEditingNoteId(null);
      setEditContent("");
      setSnackbarMessage("Note edited successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error editing note:", error);
      setSnackbarMessage("Failed to edit note");
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response ? error.response.status : null,
      });
      setSnackbarOpen(true);
    } finally {
      setIsSaving(false);
    }
  };

  /* istanbul ignore next */
  const handleCancelEdit = () => {
    track('Service Note Edit Cancelled');
    setEditingNoteId(null);
    setEditContent("");
    fetchNotes(vehicleProfileId);
  };

  const handleDeleteClick = (noteId) => {
    track('Service Note Delete Button Clicked');
    setNoteToDelete(noteId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    track('Service Note Deleted');
    try {
      const noteResponse = await issueService.getNotesByNoteId(noteToDelete);

      if (noteResponse.data.deleted) {
        setSnackbarMessage("Note has already been deleted");
        setNotes((prevNotes) => prevNotes.filter(note => note.noteId !== noteToDelete));
      } else {
        await issueService.deleteNote(noteToDelete);
        setSnackbarMessage("Note deleted successfully");
        await fetchNotes(vehicleProfileId); // Refresh the notes
      }

      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to delete note");
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response ? error.response.status : null,
      });
      setSnackbarOpen(true);
    } finally {
      setDeleteDialogOpen(false);
      setNoteToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    track('Service Note Delete Cancelled');
    setDeleteDialogOpen(false);
    setNoteToDelete(null);
  };

  const characterCount = (text) => text.length;

  if (isApiError) {
    /* istanbul ignore next */
    return <ErrorPage isApiError={isApiError} />;
  }

  // Check if the user can view service notes
  if (!canViewServiceNotes) {
    return null;
  }

  return (
    <Box sx={{ paddingX: "3rem", marginLeft: 1, marginTop: "2rem" }}>
      <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: "1rem" }}>
        <Grid item>
          <Typography
            displayColor="text-onlight-strong"
            displayStyle="subtitle-semibold"
          >
            Service Notes
          </Typography>
        </Grid>
        {canAddServiceNotes && !showTextarea && !editingNoteId && (
          <Grid item>
            <Button variant="outlined" onClick={handleAddNoteClick}>
              Add Note
            </Button>
          </Grid>
        )}
      </Grid>
      {!showTextarea && notesLoaded && notes.length === 0 && (
        <Box sx={{ width: "80%" }}>
          <Typography
            displayColor="text-onlight-moderate-default"
            displayStyle="body-1-regular"
            spanProps={{ className: 'text-custom-color' }}
          >
            {DEFAULT_NOTE_TEXT}
          </Typography>
        </Box>
      )}
      {showTextarea && canAddServiceNotes && (
        <Box sx={{ width: "80%" }} mt={2}>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={note}
            placeholder={notes.length === 0 ? DEFAULT_NOTE_TEXT : ""}
            onChange={(e) => setNote(e.target.value)}
            helperText={`${characterCount(note)}/500`}
            inputProps={{ maxLength: 500 }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: "1rem" }}>
            <Button
              variant="outlined"
              onClick={handleCancelAddNote}
              sx={{ ml: 1, color: "#808080" }}
            >
              Cancel
            </Button>

            <Button
              variant={note.trim() ? "filled" : "outlined"}
              onClick={handleSaveNote}
              sx={{ ml: 1, color: "#808080" }}
              disabled={isSaving}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
      <Box mt={2}>
        {canViewServiceNotes &&
          notes?.map((note) => (
            <Box
              key={note.noteId}
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
              mb={1}
              sx={{ width: "80%" }}
            >
              <Box display="flex" flexDirection="column" flexGrow={1} mb={1}>
                {editingNoteId === note.noteId ? (
                  <>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      value={editContent}
                      onChange={(e) => setEditContent(e.target.value)}
                      helperText={`${characterCount(editContent)}/500`}
                      inputProps={{ maxLength: 500 }}
                    />
                    <Box
                      sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: "1rem" }}
                    >
                      <Button
                        variant="outlined"
                        onClick={handleCancelEdit}
                        sx={{ mt: 2, ml: 1, color: "#808080" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant={editContent.trim() ? "filled" : "outlined"}
                        onClick={handleEditSave}
                        sx={{ mt: 2, ml: 1, color: "#808080" }}
                        disabled={isSaving}
                      >
                        Save
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography
                      displayColor="text-onlight-moderate-default"
                      displayStyle="body-2-regular"
                    >
                      {note.content}
                    </Typography>
                    <Box sx={{ marginTop: '2px' }}>
                      <Typography
                        displayColor="text-onlight-moderate-default"
                        displayStyle="caption-regular"
                        spanProps={{ className: 'text-custom-color' }}
                      >
                        {note.updatedBy ? `Updated on ${moment(note.updatedAt).format("MMMM DD, YYYY")} by ${note.updatedBy}` : `Added on ${moment(note.createdAt).format("MMMM DD, YYYY")} by ${note.createdBy}`}
                      </Typography>
                    </Box>
                  </>
                )}

                <Box sx={{ display: "flex", mt: 1, gap: "1rem" }}>
                {canEditServiceNotes && (
                  <IconButton onClick={() => handleEditClick(note)} data-testid={`edit-button-${note.noteId}`}>
                    <EditOutlinedIcon sx={{ color: "#0562D2" }} />
                  </IconButton>
                  )}
                  {canDeleteServiceNotes && (
                  <IconButton onClick={() => handleDeleteClick(note.noteId)} data-testid={`delete-button-${note.noteId}`}>
                    <DeleteOutlineOutlinedIcon sx={{ color: "#0562D2" }} />
                  </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle style={{ fontSize: "24px" }} id="alert-dialog-title">{"Are you sure you want to delete this note?"}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: "16px" }} id="alert-dialog-description">
            This action can't be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDeleteCancel} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ServiceNote.propTypes = {
  vehicleProfileId: PropTypes.number.isRequired,
  issueService: PropTypes.object.isRequired
};

export default ServiceNote;
