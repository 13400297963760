//src/utils/formatDateWithTimezone.js
import moment from 'moment-timezone';

// Utility function to format date with timezone
const formatDateWithTimezone = (date) => {
  const timezone = localStorage.getItem('timezone') || 'UTC'; // Default to 'UTC' if timezone not found
  if (!date) return '';
  const utcDate = moment.utc(date);
  return `Updated on ${utcDate.tz(timezone).format('MM-DD-YYYY')} at ${utcDate.tz(timezone).format('hh:mm A')}`;
};

export default formatDateWithTimezone;
