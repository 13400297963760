// src/context/RoleContext.js
import React, { createContext, useContext, useEffect, useState, useRef, useMemo } from 'react';
import roleService from '../services/roleService';
import { getRoleAndSiteCode } from '../utils/roleUtils';

const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
  const [roleConfig, setRoleConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchRoleConfigCalled = useRef(false); // Ref to ensure role config is only fetched once

  useEffect(() => {
    const fetchRoleConfig = async () => {
      try {
        const { role, siteCode } = getRoleAndSiteCode();
        const response = await roleService.getRoleConfig(role, siteCode);
        setRoleConfig(response.data);
      } catch (error) {
        console.error('Failed to fetch role config:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!fetchRoleConfigCalled.current) {
      fetchRoleConfigCalled.current = true;
      fetchRoleConfig();
    }
  }, []);

  const contextValue = useMemo(() => ({ roleConfig, loading }), [roleConfig, loading]);

  return <RoleContext.Provider value={contextValue}>{children}</RoleContext.Provider>;
};

export const useRole = () => useContext(RoleContext);
