//src/containers/Impersonate/ImpersonateRole.jsx
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PopupDialog from '../../components/PopupDialog'; // Use the existing PopupDialog component

const ImpersonateRole = () => {
  const [showPopup, setShowPopup] = useState(true);
  const navigate = useNavigate();

  const handlePopupSelect = (option) => {
    setShowPopup(false);
    navigate('/'); // Redirect to the home page after popup confirmation
  };

  return (
    <Box sx={{ height: '100vh', bgcolor: '#FFFFFF' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 64px)' }}>
        <PopupDialog open={showPopup} onClose={() => setShowPopup(false)} onSelect={handlePopupSelect } showCloseIcon={false}  />
      </Box>
    </Box>
  );
};

export default ImpersonateRole;
