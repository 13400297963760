import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN, ID_TOKEN, EXPIRES_IN } from '../utils/constants';

function LogoutCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear any remaining session or local storage data if needed
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove(ACCESS_TOKEN);
    Cookies.remove(ID_TOKEN);
    Cookies.remove(EXPIRES_IN);
    // Redirect to login page
    navigate('/login');
  }, [navigate]);

  return null; // You can return a loading spinner or message if you want
}

export default LogoutCallback;
