import React from 'react';
import { Box, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { capitalizeFirstWordAndFormat, titleCase } from "../../../utils/stringUtils";
import { SelectDropdown } from '@ford/ford-ui-components';

const AlertItem = ({ alert, selectedAlertStatuses, handleStatusChange, index }) => {
  const alertStatusList = [
    "Open", "Unreachable", "Contacted", "Scheduled",
    "In Progress", "On Hold", "Completed", "Cancelled"
  ];
  const defaultStatus = {
    label: selectedAlertStatuses[alert.id] || alert.status,
    value: selectedAlertStatuses[alert.id] || alert.status
  };
  const backgroundColor = index % 2 !== 0 ? "#FAFAFA" : "#ffffff";
  const borderColor = index % 2 !== 0 ? "#B3B3B3" : "#ffffff";
  const handleSeverityColor = (severity) => {
    const severityColors = {
      Urgent: { backgroundColor: '#F9E8EA' },
      High: { backgroundColor: '#F9E8EA' },
      Medium: { backgroundColor: '#FCF2E6' },
      Low: { backgroundColor: '#E5F3E6' }
    };
    return severityColors[severity] || {};
  };
  const severityStyle = handleSeverityColor(alert.severity);

  return (
    <Box key={alert.id} sx={{ ...styles.detailsCard, backgroundColor, borderTop: `0.25px solid ${borderColor}`, borderBottom: `0.25px solid ${borderColor}` }}>
      <Box sx={styles.title}>
        <Typography component="span" sx={styles.typography}>
          <Box sx={styles.categoryContainer}>
            <span style={styles.categoryBadge}>
              {alert.alertCategory === "Prognostics" ? alert.alertCategory.slice(0, 4).toUpperCase() : alert.alertCategory}
            </span>
            <Box sx={{ ...styles.alertStyle, background: severityStyle.backgroundColor }}>
              <Typography component="span" sx={styles.severityText}>
                {alert.severity}
              </Typography>
            </Box>
          </Box>
          <Typography sx={styles.alertTitle}>
            {alert.alertCategory === "FSA"
              ? capitalizeFirstWordAndFormat(alert?.alertTitle)
              : titleCase(alert?.alertTitle)}
          </Typography>
        </Typography>
        <Box sx={{ marginTop: "-10px"}}>
          <SelectDropdown
            placeholder='Select Status'
            value={defaultStatus}
            onValueChange={(event) => handleStatusChange(event, alert.id, alert.status, alert)}
            options={alertStatusList.map((status) => ({
              label: status,
              value: status
            }))}
            showChevron
            className="border-xs border-solid border-[#333333] !rounded-full custom-width"
          />
        </Box>
      </Box>
      <Typography sx={styles.alertDescription} gutterBottom>
        {alert.alertTitleDesc}
      </Typography>
      {renderAlertCategoryDetails(alert)}
    </Box>
  );
};

const renderAlertCategoryDetails = (alert) => {
  switch (alert.alertCategory) {
    case "VHA":
      return renderVHADetails(alert);
    case "Prognostics":
      return renderPrognosticsDetails(alert);
    case "FSA":
      return renderFSADetails(alert);
    default:
      return null;
  }
};

const renderCommonDetails = (alert, additionalDetails = []) => (
  <Typography sx={styles.alertDescription} variant="body3">
    {additionalDetails.map((detail) => (
      <Box sx={styles.detailBox} key={detail}>{detail}</Box>
    ))}
    <Box sx={styles.detailBox}>System Alert: {alert.alertStatusCode ?? "N/A"}</Box>
    <Box sx={styles.detailBox}>Alert Creation Date: {alert.creationAlertTime ? moment(alert.creationAlertTime).format("MM-DD-YYYY") : "N/A"}</Box>
    {renderOptionalAlertDates(alert)}
  </Typography>
);

const renderVHADetails = (alert) => renderCommonDetails(alert, [
  `DTC: ${alert.alertCode ?? "N/A"}`,
  `Customer Action: ${alert.alertCustomerAction ?? "N/A"}`
]);

const renderPrognosticsDetails = (alert) => {
  const mobileRepairStatus = alert.mobileRepair ?? 'N/A';
  const mobileRepairDetails = alert.mobileRepair === "Yes" ? `, ${alert?.mra_description ?? ''}` : '';
  const mobileRepairInfo = mobileRepairStatus + mobileRepairDetails;
  
  return renderCommonDetails(alert, [
  `Mobile Service: ${mobileRepairInfo}`
])};

const renderFSADetails = (alert) => {
  const alertCode = alert.alertCode ?? "N/A";
  const mobileRepairStatus = alert.mobileRepair ?? 'N/A';
  const mobileRepairDetails = alert.mobileRepair === "Yes" ? `, ${alert?.mra_description ?? ''}` : '';
  const mobileRepairInfo = mobileRepairStatus + mobileRepairDetails;

  return renderCommonDetails(alert, [
    `Local FSA: ${alertCode}`,
    `Mobile Service: ${mobileRepairInfo}`
  ]);
};

const renderOptionalAlertDates = (alert) => (
  <>
    {alert.updationAlertTime && (
      <Box sx={styles.detailBox}>
        Alert Updated Date: {moment(alert.updationAlertTime).format("MM-DD-YYYY")}
      </Box>
    )}
    {alert.completionAlertTime && (
      <Box sx={styles.detailBox}>
        Alert Cleared Date: {moment(alert.completionAlertTime).format("MM-DD-YYYY")}
      </Box>
    )}
  </>
);

const styles = {
  detailsCard: {
    border: 1,
    borderColor: "#FFFFFF",
    boxShadow: "none",
    padding: "25px",
    margin: "0px 32px"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  typography: {
    fontSize: "12px",
    color: "#000",
    fontWeight: "400"
  },
  categoryContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  categoryBadge: {
    background: "#E5E5E5",
    borderRadius: '3px',
    padding: "4px"
  },
  alertStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 4px",
    borderRadius: "3px",
    background: '#F9E8EA',
    marginLeft: '8px'
  },
  severityText: {
    fontSize: "12px",
    textTransform: "Capitalize",
    padding: "4px"
  },
  alertTitle: {
    fontWeight: '600',
    width: '100%',
    marginTop: "8px",
  },
  alertDescription: {
    marginTop: "14px",
    color: "#333333",
    fontSize: '0.875rem',
    fontWeight: '300',
    lineHeight: '20px'
  },
  detailBox: {
    marginTop: "5px"
  }
};

AlertItem.propTypes = {
  alert: PropTypes.object.isRequired,
  selectedAlertStatuses: PropTypes.object.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default AlertItem;