// src/services/apiEndpoints.js
const apiEndpoints = {
  ISSUELIST: '/leads',
  SEARCHVIN: '/vins',
  FILTERVALUES: '/distinctFilterLeadsValues',
  UPDATEISSUESSTATUS: '/alert',
  ISSUES: '/alertDetails',
  ROLECONFIG: '/roleConfig',
  JOBLIST: '/jobs',
  ISSUELISTBYJOB: '/issuesByJobId',
  GETALLJOBSTATUS: '/getAllStatus',
  UPDATEJOBSSTATUS: 'updateJobStatus',
  JOBS: '/jobDetailInfo',
  EXPORT: '/alertsExport',
  METRICS: '/metrics',
  DISTINCTROLES: '/distinctRoles',
  CUSTOMERLIST: '/customer/alerts',
  IMPERSONATE: '/distinctRoles',
  LOGUSERACTIVITY: '/logUserActivity',
  CUSTOMERFILTERVALUES: '/customer/distinctFilterValues',
  CUSTOMERSEARCH: '/customer/vins',
  CUSTOMEREXPORT: '/customer/export',
  LISTSERVICENOTES: '/notes/getByVehicleProfileId',
  FETCHSERVICENOTES: '/notes/getNoteById',
  CREATESERVICENOTE: '/notes/createAll',
  EDITSERVICENOTE: 'notes/edit',
  DELETESERVICENOTE: 'notes/delete',
  GETCUSTOMERNOTE: '/notes/customerNotes',
  UPDATECUSTOMERNOTE: '/notes/customerNotes/update',
  CUSTOMERPROFILE: '/customer/detail/profile',
  CUSTOMERALERTDETAIL: '/customer/detail/activeInActiveAlerts',
  CUSTOMERCONNECTEDVEHICLE: '/customer/detail/tmcConnectedVehicle',
  CUSTOMERFORDPASS: '/customer/detail/fordPassRewards',
  OMAAIASSISTANT: '/oma/aiAssistant',
  OMAFEEDBACK: '/oma/feedback',
  OMAMODELYEAR: '/oma/modelYear',
  ALLCUSTOMERS: '/allCustomers/list',
  ALLCUSTOMERSEXPORT: '/allCustomers/export',
  ACTIVEALERTSASSIGNEE: '/customer/assign',
  ACTIVEALERTSUNASSIGNEE: '/customer/unassign',
  ACTIVEALERTSEARCH:'/customer/search'
};

export default apiEndpoints;
