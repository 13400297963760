import React, { useEffect, useRef, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import {
  Box,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import { Typography } from '@ford/ford-ui-components';
import { styles } from './AIGuide.style';
import { ReactComponent as InformationIcon } from '../../assets/icons/information-icon.svg';
import { ReactComponent as UnavailableErrorIcon } from '../../assets/icons/unavailable-error-icon.svg';
import { ReactComponent as AiChatIcon } from '../../assets/icons/ai-chat.svg';
import FordSupportBox from './FordSupportBox';
import apiEndpoints from '../../services/apiEndpoints';
import { getRoleAndSiteCode } from '../../utils/roleUtils';
import { getUniqueName } from '../../config/AuthenticatedRoute';
import QuestionBox from './QuestionBox';
import SkeletonBox from './SkeletonBox';
import axiosInstance from '../../services/axiosInstance';
import AnswerBox from './AnswerBox';
import VinErrorMessage from './VinErrorMessage';
import { AnswerFeedback } from './FeedbackControls';

const AIGuide = () => {
  const vinRegex = /^[a-zA-Z0-9]{17}$/;
  const [selectedTab, setSelectedTab] = useState(0);
  const [model, setModel] = useState(sessionStorage.getItem('model'));
  const [year, setYear] = useState(sessionStorage.getItem('year'));
  const [vin, setVin] = useState();
  const [vinLabel, setVinLabel] = useState('Enter 17-digit VIN');
  const [isInvalidVin, setIsInvalidVin] = useState(false);
  const [modelLabel, setModelLabel] = useState('Select Model');
  const [yearLabel, setYearLabel] = useState('Select Year');
  const [isQuestionFieldEnabled, setIsQuestionFieldEnabled] = useState(false);
  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [omaModelYear, setOmaModelYear] = useState({});
  const [dialog, setDialog] = useState(
    sessionStorage.getItem('dialog') !== null
      ? JSON.parse(sessionStorage.dialog)
      : { questions: [], answers: [] }
  );
  const [isAIGuideUnavailable, setIsAIGuideUnavailable] = useState(false);
  const [isSearchByVinUnavailable, setIsSearchByVinUnavailable] = useState(false);
  const [isVinNotSupported, setIsVinNotSupported] = useState(false);
  const [isDialogWindowOpen, setIsDialogWindowOpen] = useState(
    sessionStorage.getItem('dialog') !== null
  );
  const [sessionId, setSessionId] = useState(sessionStorage.getItem('sessionId'));
  const [requestId, setRequestId] = useState(sessionStorage.getItem('requestId'));

  const scrollContainerRef = useRef(null);

  const showVinNotSupportedMessage = isVinNotSupported ? (
    <VinErrorMessage message={'The entered VIN is not supported by the AI Guide'} />
  ) : (
    <></>
  );

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  };

  const setup = async () => {
    track('User Page Viewed', { page: 'AI Guide' });
    const omaModelYearURL = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.OMAMODELYEAR}`;
    const response = await axiosInstance.get(omaModelYearURL);
    setOmaModelYear(response.data.data);
    if (dialog.questions.length > dialog.answers.length) {
      const answers = [...dialog.answers, ''];
      setDialog({ questions: dialog.questions, answers: answers });
    }
  };
  useEffect(() => {
    setup();
  }, []);
  useEffect(() => {
    if (dialog.answers.length > 0) {
      sessionStorage.setItem('dialog', JSON.stringify(dialog));
    } else {
      sessionStorage.removeItem('dialog');
    }
  }, [dialog]);
  useEffect(() => {
    sessionStorage.setItem('model', model);
  }, [model]);
  useEffect(() => {
    sessionStorage.setItem('year', year);
  }, [year]);
  useEffect(() => {
    sessionStorage.setItem('sessionId', sessionId);
  }, [sessionId]);
  useEffect(() => {
    setIsQuestionFieldEnabled(!!((vin && !isInvalidVin) || (model && year)));
  }, [vin, isInvalidVin, model, year]);
  useEffect(() => {
    // Scroll when questions change, loading changes, or answers change
    setTimeout(() => {
      scrollToBottom();
    }, 100); // Small delay to ensure DOM has updated
  }, [dialog.questions, dialog.answers, loading]);

  const handleTabChange = (event, newValue) => {
    setVin(null);
    setModel(null);
    setYear(null);
    setSelectedTab(newValue);
  };
  const handleModelChange = (event, newValue) => {
    setModel(newValue.props.value);
  };
  const handleYearChange = (event, newValue) => {
    setYear(newValue.props.value);
  };
  const callOMA = async (event) => {
    if (event.key === 'Enter' && question.length > 0) {
      setLoading(true);
      const questions = [...dialog.questions, question];
      setDialog({ questions: questions, answers: dialog.answers });
      setQuestion('');
      track('AI Guide question submitted', { vin: vin, model: model, year: year });
      const { currentRole, currentSiteCode } = getRoleAndSiteCode();
      const omaURL = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.OMAAIASSISTANT}`;
      const userId = getUniqueName();
      const payload = {
        sessionId,
        question,
        vin,
        modelYear: year,
        model,
        role: currentRole,
        dealerCode: currentSiteCode,
        userId
      };
      const response = await axiosInstance.post(omaURL, payload);
      const data = response.data;
      if (data['code'] === 200) {
        handleOMASuccessResponse(data, questions);
      } else if (data['code'] === 404) {
        setDialog({ questions: [], answers: [] });
        if (
          data['data']['message'] === 'The entered VIN/Model-Year is not supported by the AI Guide'
        ) {
          setIsVinNotSupported(true);
        }
      } else {
        setDialog({ questions: [], answers: [] });
        if (data['data']['message'] === 'AI guide is currently down!') {
          setIsAIGuideUnavailable(true);
        } else {
          setIsSearchByVinUnavailable(true);
        }
      }
      setLoading(false);
    }
  };
  const handleOMASuccessResponse = (data, questions) => {
    setIsDialogWindowOpen(true);
    setModel(data.data.model);
    setYear(data.data.modelYear);
    setRequestId(data.data.requestId);
    setSessionId(data.data.sessionId);
    setIsAIGuideUnavailable(false);
    setIsSearchByVinUnavailable(false);
    setIsVinNotSupported(false);

    let answer = data.data.answer;
    let references = '';

    // Extract references separately
    if (data.data.sources && data.data.sources.length > 0) {
      let sources = data.data.sources[0];
      sources = sources.replaceAll('<a ', '<a target="_blank" rel="noopener noreferrer" ');
      sources = sources.replaceAll('<ol>', '<ol style="list-style-type: decimal;">');
      sources = sources.replaceAll(
        '<li>',
        '<li style="margin-left: 20px;text-decoration: underline;">'
      );
      references = 'References:' + sources;
    }

    // Store answer, references, and requestId together
    const answers = [...dialog.answers, {
      content: answer,
      references: references,
      requestId: data.data.requestId // Store the requestId with each answer
    }];

    setDialog({ questions: questions, answers: answers });
  };

  const handleSubmit = () => {
    if (question.length > 0 && !loading && isQuestionFieldEnabled) {
      const synthEvent = { key: 'Enter' };
      callOMA(synthEvent).then();
    }
  };

  const handleFeedbackSubmit = async (feedbackData) => {
    try {
      // Extract question index from the answerId (format: qa-questiontext-index)
      const questionIndex = parseInt(feedbackData.answerId.split('-').pop());
      const question = dialog.questions[questionIndex];

      // Get the requestId associated with this specific answer
      const answerRequestId = dialog.answers[questionIndex].requestId || requestId;

      // Prepare payload for the backend API
      const payload = {
        requestId: answerRequestId, // Use the answer-specific requestId
        sessionId: sessionId,       // SessionId remains the same for the session
        question: question,
        helpful: feedbackData.rating === 'positive',
        feedbackNotes: feedbackData.feedback || ""
      };

      // Call the feedback API
      const feedbackURL = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.OMAFEEDBACK}`;
      await axiosInstance.post(feedbackURL, payload);

      return true;
    } catch (error) {
      console.error('Error submitting feedback:', error);
      return false;
    }
  };

  return (
    <div style={{ marginTop: '20px', marginBottom: '50px' }}>
      {/* Page header */}
      <Box sx={styles.headerText}>
        <Box sx={{ padding: '20px 0' }}>
          <Typography
            displayStyle='display-3-small-semibold'
            displayColor='text-onlight-moderate-default'
          >
            AI Guide
          </Typography>
        </Box>
      </Box>

      {/* Content start */}
      <Box sx={{ width: '100%', display: 'grid', justifyContent: 'center' }}>
        <Container maxWidth='md'>
          {/* Service unavailable message */}
          {isAIGuideUnavailable && (
            <Box
              sx={{
                ...styles.rowFlex,
                borderRadius: 0,
                backgroundColor: '#F9E8EA',
                padding: '16px'
              }}
            >
              <UnavailableErrorIcon />
              <Box sx={{ paddingLeft: '16px' }}>
                <Box
                  sx={{
                    color: '#333',
                    fordFamily: 'ford f1 bold',
                    fordSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '24px'
                  }}
                >
                  AI Guide is temporarily unavailable.
                </Box>
                <Box sx={{ ...styles.rowFlex, lineHeight: '20px' }}>
                  Please refer to your vehicle's owner manual by visiting&nbsp;
                  <FordSupportBox />.
                </Box>
              </Box>
            </Box>
          )}

          {/* Main container with fixed height */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              minHeight: isDialogWindowOpen ? '70vh' : 'auto' // Dynamic minHeight
            }}
          >
            {/* Sticky conversation header */}
            {isDialogWindowOpen && (
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  background: 'white',
                  zIndex: 10,
                  paddingBottom: '16px'
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ ...styles.modelHeadText }}>
                    {year} {model}
                  </Box>
                  <button
                    style={{ ...styles.changeVehicleText }}
                    onClick={() => {
                      setIsDialogWindowOpen(false);
                      setDialog({ questions: [], answers: [] });
                      sessionStorage.removeItem('dialog');
                      sessionStorage.removeItem('sessionId');
                      setSessionId(null);
                    }}
                  >
                    Change vehicle
                  </button>
                </Box>
                <Divider sx={{ paddingTop: '10px' }} />
              </Box>
            )}

            {/* Scrollable conversation history */}
            <Box
              ref={scrollContainerRef}
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                maxHeight: isDialogWindowOpen ? '55vh' : 'auto'
              }}
            >
              {/* Vehicle selection form */}
              {!isDialogWindowOpen && (
                <Box>
                  <Box sx={styles.stepText}>1. Select a vehicle</Box>
                  {/* Tab navigation */}
                  <Box sx={{ paddingTop: '16px' }}>
                    <Tabs
                      TabIndicatorProps={{
                        sx: { backgroundColor: '#0562D2', border: '2px solid #0562D2' }
                      }}
                      value={selectedTab}
                      onChange={handleTabChange}
                    >
                      <Tab iconPosition='start' sx={styles.tabHighlight} label='By VIN' />
                      <Tab
                        iconPosition='start'
                        sx={styles.tabHighlight}
                        label='By Model and Year'
                      />
                    </Tabs>

                    {/* VIN input tab */}
                    {!selectedTab ? (
                      <>
                        {isSearchByVinUnavailable && (
                          <Box
                            sx={{
                              ...styles.rowFlex,
                              borderRadius: 0,
                              backgroundColor: '#F9E8EA',
                              padding: '16px'
                            }}
                          >
                            <UnavailableErrorIcon />
                            <Box sx={{ paddingLeft: '16px' }}>
                              <Box
                                sx={{
                                  color: '#333',
                                  fordFamily: 'ford f1 bold',
                                  fordSize: '16px',
                                  fontStyle: 'normal',
                                  fontWeight: 700,
                                  lineHeight: '24px'
                                }}
                              >
                                Search by VIN is temporarily unavailable.
                              </Box>
                              <Box sx={{ ...styles.rowFlex, lineHeight: '20px' }}>
                                Please search for your vehicle by model and year instead or
                                visit&nbsp;
                                <FordSupportBox />.
                              </Box>
                            </Box>
                          </Box>
                        )}

                        <TextField
                          error={isInvalidVin}
                          sx={styles.textField}
                          inputProps={{ maxLength: 17 }}
                          onFocus={() => setVinLabel('VIN')}
                          onBlur={() =>
                            !vin
                              ? setVinLabel('Enter 17-digit VIN')
                              : setIsInvalidVin(!vinRegex.test(vin))
                          }
                          onChange={(e) => setVin(e.target.value)}
                          label={vinLabel}
                          variant='outlined'
                          helperText={
                            isInvalidVin ? (
                              <VinErrorMessage message={'Please Enter 17-digit VIN'} />
                            ) : (
                              showVinNotSupportedMessage
                            )
                          }
                        />
                      </>
                    ) : (
                      /* Model and year selection tab */
                      <Box>
                        <FormControl sx={styles.modelInput}>
                          <InputLabel id='model-label'>{modelLabel}</InputLabel>
                          <Select
                            labelId='model-label'
                            id='model-select'
                            value={model}
                            label={modelLabel}
                            onFocus={() => setModelLabel('Model')}
                            onBlur={() => !model && setModelLabel('Select Model')}
                            onChange={handleModelChange}
                            variant='outlined'
                          >
                            {Object.keys(omaModelYear).map((key) => (
                              <MenuItem key={key} value={key}>
                                {key}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl sx={styles.yearInput}>
                          <InputLabel id='year-label'>{yearLabel}</InputLabel>
                          <Select
                            disabled={!model}
                            labelId='year-label'
                            id='year-select'
                            value={year}
                            label={yearLabel}
                            onFocus={() => setYearLabel('Year')}
                            onBlur={() => !year && setYearLabel('Select Year')}
                            onChange={handleYearChange}
                            variant='outlined'
                          >
                            {model &&
                              omaModelYear[model].map((year) => (
                                <MenuItem key={year} value={year}>
                                  {year}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                  {/* Ask section header */}
                  <Box
                    sx={{
                      ...styles.stepText,
                      paddingTop: '30px',
                      marginBottom: '5px'
                    }}
                  >
                    2. Ask away
                  </Box>
                </Box>
              )}

              {/* Q&A history display */}
              {isDialogWindowOpen &&
                dialog.questions.map((question, index) => {
                  const answer = dialog.answers[index];
                  const setDivider = index > 0;
                  const uniqueKey = `qa-${question.substring(0, 10).replace(/\s/g, '')}-${index}`;

                  return (
                    <React.Fragment key={uniqueKey}>
                      {setDivider && <Divider sx={{ paddingTop: '30px', marginBottom: '30px' }} />}
                      <QuestionBox question={question} />

                      {/* Render main answer content */}
                      {answer && typeof answer === 'object' ? (
                        <>
                          <AnswerBox answer={answer.content} />

                          {/* Feedback controls between answer and references */}
                          {answer.content && answer.content.trim() !== '' && (
                            <AnswerFeedback
                              answerId={uniqueKey}
                              onFeedbackSubmit={handleFeedbackSubmit}
                              scrollToBottom={scrollToBottom}
                            />
                          )}

                          {/* References section */}
                          {answer.references && (
                            <Box sx={{ marginTop: '30px' }} dangerouslySetInnerHTML={{ __html: answer.references }} />
                          )}
                        </>
                      ) : (
                        // Handle legacy format if needed
                        <AnswerBox answer={answer} />
                      )}
                    </React.Fragment>
                  );
                })}

              {/* Loading skeleton */}
              {loading && <SkeletonBox />}
            </Box>

            {/* Sticky input field at bottom - reduced padding */}
            <Box
              sx={{
                position: 'sticky',
                bottom: 0,
                background: 'white',
                zIndex: 10,
                paddingTop: '5px',
                marginTop: 'auto'
              }}
            >
              <FormControl sx={styles.rowFlex}>
                <TextField
                  sx={styles.textField}
                  placeholder='Ask anything'
                  variant='outlined'
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  disabled={!isQuestionFieldEnabled || loading}
                  onKeyDown={callOMA}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleSubmit}
                          disabled={!isQuestionFieldEnabled || loading || question.length === 0}
                          edge='end'
                          aria-label='submit question'
                        >
                          <AiChatIcon
                            style={{
                              fill: question.length > 0 ? '#000000' : '#808080'
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>

              {/* Information footer */}
              <Box
                sx={{
                  ...styles.aiGuideAvailability,
                  ...styles.rowFlex,
                  paddingTop: '16px',
                  paddingBottom: '16px'
                }}
              >
                <InformationIcon style={{ marginTop: '1px', marginRight: '5px' }} />
                {!isDialogWindowOpen ? (
                  <>
                    The AI Guide is not yet available for all models. If your vehicle isn't
                    supported, visit&nbsp;
                    <FordSupportBox />.
                  </>
                ) : (
                  <>This is an AI search and not a replacement for an owner's manual.</>
                )}
              </Box>
            </Box>

            {/* Help text for first-time users - outside scrollable area */}
            {!isDialogWindowOpen && (
              <Box sx={{ ...styles.askText, marginTop: '114px' }}>
                <Box>Ask anything. Ford's artificial intelligence will</Box>
                <Box> scan the owner manuals to find the answer.</Box>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default AIGuide;
