import React, { useState, useEffect } from "react";
import { Box, Typography, FormControl, Select, MenuItem, Grid } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { useRole } from '../../../context/RoleContext';
import { hasActionAccess } from '../../../utils/permissions';
import issueService from "../../../services/issueService";
import jobService from "../../../services/jobService";
import { MoreHoriz } from "@mui/icons-material";
import { capitalizeFirstWordAndFormat, titleCase } from "../../../utils/stringUtils";

const IssueDetailService = ({ state, setState, issue, reloadData, getAllJobStatus }) => {
  const { roleConfig } = useRole();
  const hasProfileServicePermission = hasActionAccess(roleConfig, 'alert_detail', 'service_history');
  const [selectedAlertStatuses, setSelectedAlertStatuses] = useState({});

  useEffect(() => {
    if (issue?.alerts) {
      const statuses = issue.alerts.reduce((acc, alert) => ({
        ...acc,
        [alert.id]: alert.status
      }), {});
      setSelectedAlertStatuses(statuses);
    }
  }, [issue?.alerts]);

  const handleStatusChange = (event, alertId) => {
    const newStatus = event.target.value;
    setSelectedAlertStatuses((prev) => ({
      ...prev,
      [alertId]: newStatus,
    }));

    issueService.updateStatusValue(alertId, newStatus)
      .then((res) => {
        const updatedIssues = state.issues.map((job) => {
          return {
            ...job,
            alerts: job.alerts ? job.alerts.map((alert) => {
              if (alert.id === alertId) {
                return { ...alert, status: newStatus };
              }
               /* istanbul ignore next */
              return alert;
            }) :  /* istanbul ignore next */ []
          };
        });

        setState((prev) => ({
          ...prev,
          issues: updatedIssues,
          statusUpdatePopUp: true,
          statusUpdatedData: updatedIssues.find((job) =>  {
            /* istanbul ignore next */
            return job.alerts?.some((alert) => alert.id === alertId)
          }),
          issueId: null,
        }));

        // Call reloadData to refresh the list
        reloadData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSeverityColor = (severity) => {
    const severityColors = {
      Urgent: { color: "#BF152C", backgroundColor: "#FFF1F1" },
      Medium: { color: "#FF5AF8", backgroundColor: "#FFE3FE" },
      Low: { color: "#793FFF", backgroundColor: "#CAC7FF" },
      High: { color: "#FDA74C", backgroundColor: "#FFF1E1" }
    };
    return severityColors[severity] || {};
  };

  const handleStatusJobChange = async (jobId, newStatus, newStatusId) => {
    /* istanbul ignore next */
    try {
      /* istanbul ignore next */
      await jobService.updateJobStatus(jobId, newStatusId);
      /* istanbul ignore next */
      setState((prevState) => ({
        ...prevState,
        statusUpdatePopUp: true,
        jobs: prevState.jobs.map((job) => job.jobId === jobId ? { ...job, status: newStatus } : job),
        jobDetailsData: {
          ...prevState.jobDetailsData,
          jobs: prevState.jobDetailsData.jobs.map((job) => job.jobId === jobId ? { ...job, status: newStatus } : job)
        }
      }));
    } catch (error) {
      /* istanbul ignore next */
      console.error('Error updating job status:', error);
    }
  };

  const renderAlertDetails = (alert) => {
    const severityStyle = handleSeverityColor(alert.severity);
    return (
      <Box key={alert.id} sx={styles.detailsCard}>
        <Box sx={styles.title}>
          <Typography sx={{ fontSize: "14px", color: "#000", fontWeight: "bold" }} variant="h6" gutterBottom>
            <span style={{ border: "1px solid #000", padding: "3px" }}>
              {alert.alertCategory === "Prognostics" ? alert.alertCategory.slice(0, 4).toUpperCase() : alert.alertCategory}
            </span>
            <span style={{ marginLeft: "10px" }}>
              {alert.alertCategory === "FSA"
                ? capitalizeFirstWordAndFormat(alert?.alertTitle)
                : titleCase(alert?.alertTitle)}
            </span>
          </Typography>
          <Box sx={{ ...styles.alertStyle, border: `1px solid ${severityStyle.color}`, background: severityStyle.backgroundColor }}>
            <Typography>
              <span style={{ width: "10px", height: "10px", background: severityStyle.color, borderRadius: "50%", display: "inline-block" }}></span>
            </Typography>
            <Typography>
              <span style={{ marginTop: "2px", marginLeft: "4px", fontSize: "12px", textTransform: "uppercase" }}>{alert.severity}</span>
            </Typography>
          </Box>
        </Box>
        <Typography sx={{ marginTop: "14px", color: "#808080" }} variant="body2" gutterBottom>
          {alert.alertTitleDesc}
        </Typography>
        {renderAlertCategoryDetails(alert)}
        <Box sx={{ marginTop: "0.5rem" }}>
          <FormControl variant="outlined" size="small">
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {/* Circle with conditional color */}
              <Box
                style={{
                  width: "15px",
                  height: "15px",
                  background: (selectedAlertStatuses[alert.id] || alert.status) === "Complete" ? "#29A429" : "#044EA7",
                  borderRadius: "50%",
                  marginTop: "-2px",
                  marginRight: "-7px"
                }}
              ></Box>

              <Box>
                <Select
                  value={selectedAlertStatuses[alert.id] || alert.status}
                  onChange={(event) => handleStatusChange(event, alert.id)}
                  label="Status"
                  data-testid="status-select"
                  sx={{
                    fontWeight: 'bold',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                      outline: 'none'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      outline: 'none'
                    }
                  }}
                  // Conditional styling for the selected value
                  style={{
                    color: (selectedAlertStatuses[alert.id] || alert.status) === "Complete" ? "#29A429" : "#044EA7"
                  }}
                >
                  {[
                    "Open",
                    "Unreachable",
                    "Contacted",
                    "Scheduled",
                    "In Progress",
                    "On Hold",
                    "Completed",
                    "Cancelled",
                  ].map((status) => (
                    <MenuItem
                      sx={{ fontWeight: "bold" }}
                      // Conditional styling for each menu item
                      style={{
                        color: status === "Complete" ? "#29A429" : "#044EA7"
                      }}
                      key={status}
                      value={status}
                      data-testid={status}
                    >
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </FormControl>
        </Box>
      </Box>
    );
  };

  const renderAlertCategoryDetails = (alert) => {
    if (alert.alertCategory === "VHA") {
      return renderVHADetails(alert);
    } else if (alert.alertCategory === "Prognostics") {
      return renderPrognosticsDetails(alert);
    } else if (alert.alertCategory === "FSA") {
      return renderFSADetails(alert);
    }
  };

  const renderVHADetails = (alert) => (
    <Typography sx={{ fontSize: "0.8rem", color: "grey" }} variant="body3">
      <Box sx={{ marginTop: "5px" }}>DTC: {alert.alertCode ?? "N/A"}</Box>
      <Box sx={{ marginTop: "5px" }}>Customer Action: {alert.alertCustomerAction ?? "N/A"}</Box>
      <Box sx={{ marginTop: "5px" }}>System Alert: {alert.alertStatusCode ?? "N/A"}</Box>
      <Box sx={{ marginTop: "5px" }}>Alert Creation Date: {alert.creationAlertTime ? moment(alert.creationAlertTime).format("MM-DD-YYYY") : "N/A"}</Box>
      {renderOptionalAlertDates(alert)}
    </Typography>
  );

  const renderPrognosticsDetails = (alert) => (
    <Typography sx={{ fontSize: "0.8rem", color: "grey" }} variant="body3">
      <Box sx={{ marginTop: "5px" }}>System Alert: {alert.alertStatusCode ?? "N/A"}</Box>
      <Box sx={{ marginTop: "5px" }}>Alert Creation Date: {alert.creationAlertTime ? moment(alert.creationAlertTime).format("MM-DD-YYYY") : "N/A"}</Box>
      {renderOptionalAlertDates(alert)}
    </Typography>
  );

  const renderFSADetails = (alert) => (
    <Typography sx={{ fontSize: "0.8rem", color: "grey" }} variant="body3">
      <Box sx={{ marginTop: "5px", fontWeight: "bold" }}>Local FSA: {alert.alertCode ?? "N/A"}</Box>
      <Box sx={{ marginTop: "5px" }}>System Alert: {alert.alertStatusCode ?? "N/A"}</Box>
      <Box sx={{ marginTop: "5px" }}>Mobile Repair Possible: {alert.mobileRepair ?? 'N/A'}{alert.mobileRepair === "Yes" && `, ${alert.mra}`}</Box>
      <Box sx={{ marginTop: "5px" }}>Launch Date: {alert.fsaLaunchDate ? moment(alert.fsaLaunchDate).format("MM-DD-YYYY") : "N/A"}</Box>
      {renderOptionalAlertDates(alert)}
    </Typography>
  );

  const renderOptionalAlertDates = (alert) => (
    <>
      <Box sx={{ marginTop: "5px" }}>{alert.updationAlertTime && `Alert Updated Date: ${moment(alert.updationAlertTime).format("MM-DD-YYYY")}`}</Box>
      <Box sx={{ marginTop: "5px" }}>{alert.completionAlertTime && `Alert Cleared Date: ${moment(alert.completionAlertTime).format("MM-DD-YYYY")}`}</Box>
    </>
  );

  /* istanbul ignore next */
  const getStatusColor = (status) => status === "Complete" ? "#29A429" : "#044EA7";

  return (
    <div style={{ padding: "10px" }}>
      {hasProfileServicePermission && (
        <Box>
          {issue?.alerts?.map(renderAlertDetails)}
          {issue?.jobs?.map((job) => (
            <Box key={job.id} sx={{ padding: "15px" }}>
              <Grid container sx={{ background: "#FFDFDF" }}>
                <Grid item xs={3} sx={styles.gridItem}>
                  <Box sx={styles.statusContainer}>
                    <Box>
                      <Box sx={{ fontSize: "12px" }}>STATUS</Box>
                      <Box sx={{ marginLeft: "-12px" }}>
                        <FormControl variant="outlined" size="small">
                          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box>
                              <Select
                                value={job.status}
                                /* istanbul ignore next */
                                onChange={(event) => {
                                  /* istanbul ignore next */
                                  const newStatus = event.target.value;
                                  /* istanbul ignore next */
                                  const selectedStatus = getAllJobStatus.find(status => status.status === newStatus && status.jobTypeId === job.jobTypeId);
                                  /* istanbul ignore next */
                                  handleStatusJobChange(job.jobId, newStatus, selectedStatus?.statusId);
                                }}
                                label="Status"
                                data-testid="status-select-job"
                                sx={{
                                  fontWeight: 'bold',
                                  boxShadow: 'none',
                                  '.MuiOutlinedInput-notchedOutline': {
                                    border: 0,
                                    outline: 'none'
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    outline: 'none'
                                  }
                                }}
                              >
                                {getAllJobStatus?.filter(jobStatus => jobStatus.jobTypeId === job.jobTypeId).map((status) => (
                                  <MenuItem key={status.status} value={status.status}>
                                    {status.status}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Box>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3} sx={styles.gridItem}>
                  <Box sx={styles.statusContainer}>
                    <Box>
                      <Box sx={{ fontSize: "12px" }}>JOB ID#</Box>
                      <Box>
                        <Typography sx={{ fontWeight: "700", marginTop: "5px" }}>{job.jobId}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} sx={styles.gridItem}>
                  <Box sx={styles.priorityGrid}>
                    <Typography fontSize={"14px"}>
                      <Box sx={{ ...styles.alertStyle }}>
                        <Typography>
                          <span style={{ width: "10px", height: "10px", background: handleSeverityColor(job.priority).color, borderRadius: "50%", display: "inline-block" }}></span>
                        </Typography>
                        <Typography>
                          <span style={{ marginTop: "2px", marginLeft: "4px", fontSize: "12px", textTransform: "uppercase" }}>{job.priority}</span>
                        </Typography>
                      </Box>
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px" }}>
                      <Typography sx={{ marginRight: "15px", fontSize: "14px" }}>{job.timeElapsed}</Typography>
                      <MoreHoriz />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sx={styles.gridHeader}>
                  <Grid item xs={12} padding={"15px 30px"}>
                    <Typography fontWeight={"700"}>Schedule Appointment</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                {job.alerts?.length > 0 ? job?.alerts?.map((issue) => (
                  <React.Fragment key={issue?.id}>
                    <Grid item xs={1} sx={styles.gridIssue}>
                      <Typography></Typography>
                    </Grid>
                    <Grid item xs={11} sx={styles.gridIssueContent}>
                      <Grid sx={styles.issueContainer} container>
                        <Grid xs={6}>
                          <span style={{ border: "1px solid black", padding: "4px", fontSize: "14px" }}>
                            {issue.alertCategory === "Prognostics" ? issue.alertCategory.slice(0, 4).toUpperCase() : issue.alertCategory}
                          </span>
                          <span style={{ marginLeft: "10px", fontSize: "14px", fontWeight: "bold" }}>
                            {issue.alertCategory === "FSA"
                              ? capitalizeFirstWordAndFormat(issue?.alertTitle)
                              : titleCase(issue.alertTitle)}
                          </span>
                        </Grid>
                        <Grid sx={styles.issueSeverityContainer} xs={6}>
                          <Grid sx={{ marginLeft: "80px" }} fontSize={"14px"}>
                            <Box sx={{ ...styles.alertStyle, border: `1px solid ${handleSeverityColor(issue.severity).color}`, background: `${handleSeverityColor(issue.severity).backgroundColor}` }}>
                              <Typography>
                                <span style={{ width: "10px", height: "10px", background: handleSeverityColor(issue.severity).color, borderRadius: "50%", display: "inline-block" }}></span>
                              </Typography>
                              <Typography>
                                <span style={{ marginTop: "2px", marginLeft: "4px", fontSize: "12px", textTransform: "uppercase" }}>{issue.severity}</span>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid sx={{ marginTop: "10px" }}>
                            <MoreHoriz />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container padding={"8px 15px"}>
                        <Grid xs={12}>
                          <Typography sx={{ fontWeight: "400" }} variant="body2" gutterBottom>
                            {issue.alertTitleDesc}
                          </Typography>
                          {renderAlertCategoryDetails(issue)}
                          <Typography style={{ display: "flex", alignItems: "center", marginTop: "24px" }}>
                            <Box
                              style={{
                                width: "15px",
                                height: "15px",
                                background: getStatusColor(selectedAlertStatuses[issue.id] || issue.status),
                                borderRadius: "50%",
                                marginTop: "-2px",
                                marginRight: "5px"
                              }}
                            ></Box>
                            <Typography>
                              <Box style={{
                                marginLeft: "5px",
                                fontSize: "14px", fontWeight: "bold",
                                color: getStatusColor(selectedAlertStatuses[issue.id] || issue.status)
                              }}
                              >
                                {selectedAlertStatuses[issue.id] || issue.status}
                              </Box>
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )) : (
                  <NoAlertsFound />
                )}
              </Grid>
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
};

const NoAlertsFound = () => (
  <>
    <Grid item xs={1} sx={styles.gridIssue}>
      <Typography></Typography>
    </Grid>
    <Grid item xs={11} sx={styles.gridIssueContent}>
      <Grid container sx={{ height: "200px" }} justifyContent="center" alignItems="center">
        <Grid item>No alerts found</Grid>
      </Grid>
    </Grid>
  </>
);

const styles = {
  detailsCard: {
    border: 1,
    borderColor: "#FFFFFF",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
    margin: "30px",
    padding: "35px",
    marginTop: "50px"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  alertStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 5px",
    borderRadius: "50px",
  },
  gridItem: {
    borderBottom: "1px solid black",
    borderLeft: "1px solid Black",
    borderRight: "1px solid black",
    borderTop: "1px solid black"
  },
  statusContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    marginTop: "12px"
  },
  priorityGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px",
    marginTop: "12px"
  },
  gridHeader: {
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
    borderRight: "1px solid Black"
  },
  gridIssue: {
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
    borderRight: "1px solid Black"
  },
  gridIssueContent: {
    borderBottom: "1px solid black",
    borderRight: "1px solid Black",
    padding: "15px"
  },
  issueContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "13px"
  },
  issueSeverityContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
};

IssueDetailService.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
  issue: PropTypes.object,
  reloadData: PropTypes.func,
  getAllJobStatus: PropTypes.array
};

export default IssueDetailService;
