import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import formatDateWithTimezone from '../../../utils/formatDateWithTimezone';
import { useRole } from '../../../context/RoleContext';
import { hasActionAccess } from '../../../utils/permissions';
import { Typography } from "@ford/ford-ui-components";

// Styling
import { styles } from "./IssueDetailprofile.style";
import CustomerNote from "./CustomerNote";
import VehicleInfo from "./VehicleInfo";
import { track } from "@amplitude/analytics-browser";
/**
 * Get rewards title based on vehicle make.
 * @param {string} make
 * @returns {string} Rewards title
 */
export const rewardsTitle = (make) => {
  const rewardsMap = {
    ford: 'FordPass Rewards',
    lincoln: 'Lincoln Access Rewards'
  };
  const lowerCaseMake = make?.toLowerCase();
  return rewardsMap[lowerCaseMake] || 'FordPass Rewards';
};

const canViewSection = (roleConfig, section, action) => {
  return hasActionAccess(roleConfig, section, action);
};

const renderCustomerInformation = (canView, customerDetails) => {
  if (!canView) return null;
  return (
    <div style={{ margin: '10px' }}>
      <div style={{ margin: '10px 50px' }}>
        <div style={styles.profileHeadingContainer}>
          <div style={styles.profileHeadingText}>Customer Information</div>
        </div>
        <table style={{ margin: '20px 0' }}>
          <tbody>
            <tr>
              <td style={{ width: '250px' }}>
                <p style={styles.profileTimestamp}>Email</p>
                <p style={styles.profileDataText}>
                  {customerDetails && (customerDetails?.customerInfo?.emailAddress
                    ? customerDetails.customerInfo.emailAddress.toLowerCase()
                    : '-')}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const renderRewardsInformation = (canView, rewardsData, vehMake) => {
  if (!canView) return null;
  return (
    <div style={{ margin: '5px 50px 0 50px', padding: '15px', background: '#FAFAFA', borderTop: '0.25px solid #B3B3B3', borderBottom: '0.25px solid #B3B3B3' }}>
      <div>
        <div style={styles.profileHeadingContainer}>
          <div style={styles.profileHeadingText}>
            {rewardsTitle(vehMake)}
          </div>
          <div style={styles.profileTimestamp}>
            {rewardsData?.pointsLastFetchedTime
              ? formatDateWithTimezone(rewardsData.pointsLastFetchedTime)
              : ''}
          </div>
        </div>
        <table style={{ marginTop: '20px' }}>
          <tbody>
            <tr>
              <td style={{ width: '250px' }}>
                <p style={styles.profileTimestamp}>Points</p>
                <p style={styles.profileDataText}>
                  {rewardsData && (rewardsData?.fordPassRewardsPoints != null ? rewardsData.fordPassRewardsPoints : '-')}
                </p>
              </td>
              <td style={{ width: '250px' }}>
                <p style={styles.profileTimestamp}>Dollar Value</p>
                <p style={styles.profileDataText}>
                  {rewardsData && (rewardsData?.dollarValue != null ? `$${rewardsData.dollarValue}` : '-')}
                </p>
              </td>
              <td style={{ width: '250px' }}>
                <p style={styles.profileTimestamp}>Expiry Date</p>
                <p style={styles.profileDataText}>
                  {rewardsData && (rewardsData?.pointsExpirationDate
                    ? moment(rewardsData.pointsExpirationDate).format('MM-DD-YYYY')
                    : '-'
                  )}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          className='link-btn'
          onClick={() => {
            track('Rewards Member Portal Clicked');
            window.open('https://www.rewardsmemberportal.ford.com', '_blank', 'noopener');
          }}
        >
          Rewards Member Portal
        </button>
      </div>
    </div>
  );
};

const IssueDetailProfile = ({
  setIsApiError,
  customerDetails,
  rewardsData,
  connectedVehicleData,
  vehMake
}) => {
  const { roleConfig } = useRole();
  const canReadNotes = canViewSection(roleConfig, 'customer_detail', 'customer_notes_view');
  const canWriteNotes = canViewSection(roleConfig, 'customer_detail', 'customer_notes_update');

  const canViewCustomerDetail = canViewSection(roleConfig, 'customer_detail', 'profile_customer_detail');
  const canViewRewardDetail = canViewSection(roleConfig, 'customer_detail', 'profile_access_reward');
  const canViewVehicleInformation = canViewSection(roleConfig, 'customer_detail', 'profile_vehicle_information');

  const noSectionsViewable = !canViewCustomerDetail && !canViewRewardDetail && !canViewVehicleInformation && !canReadNotes;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mx: 'auto' }}>
      {noSectionsViewable ? (
        <Box sx={{ padding: '2rem', textAlign: 'center' }}>
          <Typography variant='h6' color='textSecondary'>
            Permission Denied
          </Typography>
        </Box>
      ) : (
        <>
          {renderCustomerInformation(canViewCustomerDetail, customerDetails)}
          {renderRewardsInformation(canViewRewardDetail, rewardsData, vehMake)}
          {canReadNotes && (
            <CustomerNote
              customerDetails={customerDetails}
              canReadNotes={canReadNotes}
              canWriteNotes={canWriteNotes}
              setIsApiError={setIsApiError}
            />
          )}
          {canViewVehicleInformation && (
            <VehicleInfo
              customerDetails={customerDetails}
              vehicleData={connectedVehicleData}
              loading={false}
            />
          )}
        </>
      )}
    </Box>
  );
};

IssueDetailProfile.propTypes = {
  setIsApiError: PropTypes.func.isRequired,
  customerDetails: PropTypes.object,
  rewardsData: PropTypes.object,
  connectedVehicleData: PropTypes.object
};

export default IssueDetailProfile;
