import { Box } from '@mui/material';
import React from 'react';
import { styles } from './AIGuide.style';
import { ReactComponent as VinErrorIcon } from '../../assets/icons/vin-error-icon.svg';

const VinErrorMessage = (message) => {
  return (
    <Box sx={Object.assign({}, styles.rowFlex, { color: '#d32f2f', lineHeight: '18px' })}>
      <VinErrorIcon />
      &nbsp; {message.message}
    </Box>
  );
};

export default VinErrorMessage;
