import { Box } from '@mui/material';
import { styles } from './AIGuide.style';
import React from 'react';

const FordSupportBox = () => {
  return (
    <Box
      sx={styles.fordSupport}
      onClick={() =>
        window.open('https://www.ford.com/support/owner-manuals', '_blank', 'noopener')
      }
    >
      Ford Support
    </Box>
  );
};

export default FordSupportBox;
