import React from 'react';
import PropTypes from 'prop-types';
import { styles } from '../AIGuide.style';

/**
 * ThumbButton component - Renders a thumb up or down button with active/inactive states
 */
const ThumbButton = ({
                       icon,                // The SVG path data
                       isUp = true,         // Whether this is a thumbs up (true) or down (false)
                       isActive = false,    // Whether the button is in active state (clicked)
                       onClick = () => {},  // Click handler function
                       disabled = false,    // Whether the button is disabled
                     }) => {
  // Define colors based on active state
  const fillColor = isActive ? '#0073EA' : '#808080';

  // Combine styles
  const buttonStyle = {
    ...styles.thumbButton,
    ...(isActive ? { backgroundColor: 'rgba(0, 115, 234, 0.1)' } : {}),
    ...(disabled ? { opacity: 0.5, cursor: 'not-allowed' } : {})
  };

  return (
    <button
      style={buttonStyle}
      onClick={onClick}
      disabled={disabled}
      aria-label={isUp ? 'Like' : 'Dislike'}
      type="button"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon">
          <path
            id="Vector Path"
            d={icon}
            fill={fillColor}
          />
        </g>
      </svg>
    </button>
  );
};

ThumbButton.propTypes = {
  icon: PropTypes.string.isRequired,
  isUp: PropTypes.bool,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default ThumbButton;
