import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Container,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  InputAdornment,
  Menu,
  MenuItem,
  Checkbox,
  FormControl,
  Select,
  Snackbar,
  Alert,
  AlertTitle,
  CircularProgress
} from '@mui/material';
import { Search as SearchIcon, FileDownloadOutlined, DescriptionOutlined, FilterAltOutlined } from '@mui/icons-material';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import jobService from '../../services/jobService';
import Loader from '../../components/Loader';
import IssuesDrawer from '../IssueDetail/Drawer';
import PaginationControls from "../../components/PaginationControls";
import UnauthorizedPage from '../Auth/UnauthorizedPage';
import ErrorPage from "../../components/ErrorPage";

import { useRole } from '../../context/RoleContext';
import { hasPageAccess } from '../../utils/permissions';
import { capitalizeFirstWordAndFormat, titleCase, trimAndAppend } from '../../utils/stringUtils';
import PriorityBadge from '../../components/PriorityBadge';

import '../../App.css';

const StyledTableCell = ({ children, isLastIssue, noBorder, ...props }) => {
  let borderBottomStyle;

  if (isLastIssue) {
    borderBottomStyle = '';
  } else if (noBorder) {
    borderBottomStyle = 'none';
  } else {
    borderBottomStyle = '1px dashed rgba(163, 163, 163, 0.462)';
  }

  return (
    <TableCell {...props} style={{ borderBottom: borderBottomStyle }}>
      {children}
    </TableCell>
  );
};

const AlertDetails = ({ job }) => {
  if (!job || !job.alertTitle) return "";

  const details = job.alertCategory === "FSA"
    ? capitalizeFirstWordAndFormat(job.alertTitle)
    : titleCase(job.alertTitle);

  return trimAndAppend(details, job.alertCategory === "FSA" ? 120 : 50);
};

const IssueTableRow = ({ issue, job, isLastIssue }) => {
  const vinDisplay = job?.vin.length > 25 ? `${job?.vin.slice(0, 20)}...` : job?.vin;

  return (
    <TableRow key={issue.issueId} className={`issue-row ${!isLastIssue && ''}`}>
      <StyledTableCell colSpan={1} className="vertical-line" isLastIssue={isLastIssue} noBorder={true} />
      <StyledTableCell isLastIssue={isLastIssue}>{job.customer}</StyledTableCell>
      <StyledTableCell title={job?.vin} isLastIssue={isLastIssue}>{vinDisplay}</StyledTableCell>
      <StyledTableCell isLastIssue={isLastIssue}>{job.year}</StyledTableCell>
      <StyledTableCell isLastIssue={isLastIssue}>{titleCase(job.model)}</StyledTableCell>
      <StyledTableCell isLastIssue={isLastIssue}>{titleCase(job.trim)}</StyledTableCell>
      <StyledTableCell title={issue.alertCategory === "FSA"
        ? capitalizeFirstWordAndFormat(issue.alertTitle)
        : titleCase(issue.alertTitle)} sx={{ textTransform: "capitalize", width: "30%" }} isLastIssue={isLastIssue}>
        <Box sx={{ display: "flex" }}>
          <Box>
            <Typography
              sx={{ fontSize: "12px", color: "#000000", fontWeight: "bold" }}
              variant="h6"
              gutterBottom
            >
              <span style={{ border: "1px solid #000000", padding: "3px", borderRadius: "5px" }}>
                {issue?.alertCategory === "Prognostics" ? issue?.alertCategory.slice(0, 4).toUpperCase() : issue?.alertCategory}
              </span>
            </Typography>
          </Box>
          <Box sx={{ marginLeft: "8px" }}>
            <AlertDetails job={issue} />
          </Box>
        </Box>
      </StyledTableCell>
      <StyledTableCell isLastIssue={isLastIssue}>
        <PriorityBadge priority={issue.severity} />
      </StyledTableCell>
      <StyledTableCell isLastIssue={isLastIssue}>{job.assignment}</StyledTableCell>
      <StyledTableCell isLastIssue={isLastIssue}>{job.assignment}</StyledTableCell>
      <StyledTableCell isLastIssue={isLastIssue}>{job.timeElapsed}</StyledTableCell>
      <StyledTableCell isLastIssue={isLastIssue}>{job.assignment}</StyledTableCell>
    </TableRow>
  );
};

const JobTableRow = ({ job, expandedRows, handleExpandClick, issues, handleStatusChange, getAllJobStatus, handleJobDetails }) => {
  const vinDisplay = job?.vin.length > 25 ? `${job?.vin.slice(0, 20)}...` : job?.vin;

  return (
    <>
      <TableRow sx={{
        borderTop: expandedRows.includes(job.jobId) ? '1px dashed black' : 'none',
        background: expandedRows.includes(job.jobId) ? "#DDEAF8" : "transparent"
      }}>
        <TableCell style={{ width: "10%", padding: "0", borderTop: "none" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <button className="expand-btn" style={{ fontSize: '12px' }} onClick={() => handleExpandClick(job.jobId)}>
                {expandedRows.includes(job.jobId) ? '▼' : '▶'}
              </button>
            </Box>
            <Box marginLeft={"10px"}>
              <FormControl variant="outlined" size="small">
                <Select
                  value={job?.status}
                  onChange={(event) => {
                    const newStatus = event.target.value;
                    const selectedStatus = getAllJobStatus.find(status =>
                      status.status === newStatus && status.jobTypeId === job?.jobTypeId
                    );
                    handleStatusChange(job?.jobId, newStatus, selectedStatus?.statusId);
                  }}
                  label="Status"
                  sx={{
                    fontSize: '12.8px',
                    marginLeft: '-15px',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                      outline: 'none'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      outline: 'none'
                    }
                  }}
                >
                  {getAllJobStatus?.length && getAllJobStatus.filter((jobStatus) => jobStatus?.jobTypeId === job?.jobTypeId)?.map((status) => (
                    <MenuItem key={status?.status} value={status?.status}>
                      {status?.status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </TableCell>
        <TableCell>{job.customer}</TableCell>
        <TableCell title={job?.vin}>{vinDisplay}</TableCell>
        <TableCell>{job.year}</TableCell>
        <TableCell sx={{ textTransform: "lowercase", ':first-letter': { textTransform: "capitalize" } }}>{job.model}</TableCell>
        <TableCell sx={{ textTransform: "lowercase", ':first-letter': { textTransform: "capitalize" } }}>{job.trim ?? '-'}</TableCell>
        <TableCell sx={{ width: "30%" }}>{job.cta}</TableCell>
        <TableCell>
          <PriorityBadge priority={job.priority} />
        </TableCell>
        <TableCell>{job?.assignment}</TableCell>
        <TableCell>
          <DescriptionOutlined />
        </TableCell>
        <TableCell>{job?.timeElapsed}</TableCell>
        <TableCell onClick={() => handleJobDetails(job?.jobId)} sx={{ color: "#2480F1", minWidth: '140px', cursor: "pointer" }}>
          view full details
        </TableCell>
      </TableRow>
      {expandedRows.includes(job.jobId) && issues[job.jobId] && (
        issues[job.jobId].length > 0 ? (
          issues[job?.jobId].map((issue, index) => (
            <IssueTableRow
              key={`${job.jobId}-${issue.id}`}
              issue={issue}
              job={job}
              isLastIssue={index === issues[job.jobId].length - 1}
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={11} align="center">
              No issues found
            </TableCell>
          </TableRow>
        )
      )}
    </>
  );
};

const JobList = () => {
  const [state, setState] = useState({
    currentPage: 1,
    totalPages: 0,
    dataSize: 0,
    loading: false,
    jobs: [],
    error: null,
    rowsPerPage: 10,
    sortCriteria: { status: '', priority: '' },
    defaultSort: true,
    drawerOpen: false,
    isIssuesLoaded: false,
    jobId: null,
    jobDetailsData: {},
    statusUpdatePopUp: false,
  });

  const [expandedRows, setExpandedRows] = useState([]);
  const [issues, setIssues] = useState({});
  const [getAllStatus, setGetAllStatus] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isApiError, setIsApiError] = useState(null);

  const handleFilterClose = () => {
    setAnchorEl(null);
    setSelectedFilter(null);
  };

  const handleCheckboxToggle = (filter, option) => {
    setState((prev) => ({
      ...prev,
      selectedFilters: {
        ...prev.selectedFilters,
        [filter]: prev.selectedFilters[filter].includes(option)
          ? prev.selectedFilters[filter].filter((item) => item !== option)
          : [...prev.selectedFilters[filter], option]
      }
    }));
  };

  const { roleConfig, loading: roleLoading } = useRole();
  const filterOptions = {
    STATUS: ['Open', 'Scheduled', 'Closed'],
    CUSTOMER: ['Customer 1', 'Customer 2', 'Customer 3'],
    JOB: ['Job 1', 'Job 2', 'Job 3'],
    PRIORITY: ['Urgent', 'High', 'Medium', 'Low'],
    ASSIGNMENT: ['Christine V.', 'James Jacobs', 'Henry Dodge']
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = event.target.value;
    setState(prev => ({ ...prev, rowsPerPage: newRowsPerPage, currentPage: 1 }));
    fetchJobs(1, true, null, newRowsPerPage);
  };

  const handlePageChange = async (event, page) => {
    setState(prev => ({ ...prev, currentPage: page }));
  };

  const fetchJobs = useCallback(async (page, isFilter = false, vinFilter = null, totalItems = null) => {
    setState((prev) => ({ ...prev, loading: true, error: null }));
    let filters = [];
    try {
      const data = await jobService.getJobs(
        page,
        isFilter,
        filters,
        totalItems || state.rowsPerPage,
        state.sortCriteria,
        state.defaultSort
      );
      setState((prev) => ({
        ...prev,
        jobs: data.data.jobs,
        currentPage: data.data.pagination.currentPage,
        totalPages: data.data.pagination.totalPages,
        loading: false,
        dataSize: data.data.pagination.totalItems
      }));
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setState((prev) => ({
          ...prev,
          jobs: [],
          error: "User is not authorized to view data",
          loading: false
        }));
      } else {
        setState((prev) => ({ ...prev, jobs: [], error: "Failed to fetch Jobs", loading: false }));
      }
      setIsApiError({
        isError: true,
        message: error.message
      })
    }
  }, [state.rowsPerPage, state.sortCriteria, state.defaultSort]);

  useEffect(() => {
    fetchJobs(state.currentPage);
  }, [state.currentPage, state.rowsPerPage, fetchJobs]);

  useEffect(() => {
    const fetchIssues = async (jobId) => {
      try {
        const response = await jobService.getIssuesByJobId(jobId);
        setIssues(prevIssues => ({
          ...prevIssues,
          [jobId]: response.data
        }));
      } catch (error) {
        console.error('Error fetching issues for job ID', jobId, error);
        setIssues(prevIssues => ({
          ...prevIssues,
          [jobId]: []
        }));
        setIsApiError({
          isError: true,
          message: error.message
        })
      }
    };

    expandedRows.forEach(jobId => {
      if (!issues[jobId]) {
        fetchIssues(jobId);
      }
    });
  }, [expandedRows, issues]);

  useEffect(() => {
    const fetchAllStatus = async () => {
      try {
        const response = await jobService.getAllJobStatus();
        setGetAllStatus(response.data);
      } catch (error) {
        console.error('Error fetching all jobs for job ID', error);
        setIsApiError({
          isError: true,
          message: error.message
        })
      }
    };

    fetchAllStatus();
  }, []);

  const handleExpandClick = (id) => {
    setExpandedRows(expandedRows.includes(id) ? [] : [id]);
  };

  const handleStatusChange = async (jobId, newStatus, newStatusId) => {
    try {
      await jobService.updateJobStatus(jobId, newStatusId);
      setState((prevState) => ({
        ...prevState,
        jobs: prevState.jobs.map((job) =>
          job.jobId === jobId ? { ...job, status: newStatus } : job
        ),
        statusUpdatePopUp: true,
      }));
    } catch (error) {
      console.error('Error updating job status:', error);
      setIsApiError({
        isError: true,
        message: error.message
      })
    }
  };

  const hasAccess = hasPageAccess(roleConfig, 'list_job', 'read');

  if (roleLoading) {
    return <Loader />;
  }

  if (!hasAccess) {
    return <UnauthorizedPage />;
  }

  const handleDrawerClose = () => {
    setState((prev) => ({
      ...prev,
      drawerOpen: false,
      isIssuesLoaded: false,
      jobDetailsData: [],
      issueId: null,
    }))
  }

  const handleJobDetails = async (id) => {
    console.log("handleJobDetails>>>id", id);

    setState((prev) => ({
      ...prev,
      drawerOpen: true,
      jobId: id,
      isIssuesLoaded: false,
      jobDetailsData: {}
    }));

    if (!id) {
      console.warn("Job ID is missing. Cannot fetch job details.");
      setState(prev => ({ ...prev, isIssuesLoaded: true }));
      return;
    }

    try {
      const data = await jobService.getJobsDetail(id);
      const jobData = data?.data || {};
      setState((prev) => ({
        ...prev,
        jobDetailsData: jobData,
        isIssuesLoaded: true
      }));
    } catch (error) {
      console.error('Failed to fetch job details:', error);
      setState((prev) => ({
        ...prev,
        isIssuesLoaded: true,
      }));
      setIsApiError({
        isError: true,
        message: error.message
      })
    }
  };
  
  if(isApiError){
    return <ErrorPage />
  }

  return (
    <Container data-testid="jobs-list-container" maxWidth={false}>
      <Box sx={{ padding: "20px 0" }}>
        <Typography variant="h5" sx={{ fontSize: '20px', fontWeight: 400, lineHeight: '24px' }}><b>Jobs</b> ({state?.dataSize})</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search a job, customer, VIN ..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ fontSize: '14px' }} />
                </InputAdornment>
              ),
            }}
            sx={{ width: '300px', fontSize: '14px' }}
          />
          <Button variant="contained" sx={{ ml: 1, border: '1px solid #D1D5D9', boxShadow: 'none', fontSize: '14px', fontWeight: 500, lineHeight: '24px', color: '#000000', textTransform: 'capitalize', background: '#D1D5D9', '&:hover': { background: '#fff', color: '#000' } }}>Search</Button>
          <Button
            variant="contained"
            startIcon={<FilterAltOutlined />}
            data-testid="filter-button"
            sx={{
              backgroundColor: "white",
              color: "black",
              '&:hover': {
                backgroundColor: "white",
                color: "black",
              },
              marginRight: 1,
              marginLeft: 1,
              textTransform: "capitalize"
            }}
          >
            Filters
          </Button>
        </Box>
        <Button
          variant="contained"
          startIcon={<FileDownloadOutlined />}
          sx={{
            border: '1px solid #D1D5D9',
            boxShadow: 'none',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '24px',
            color: '#000000',
            background: '#fff',
            '&:hover': { background: '#d1d5d9' },
            textTransform: 'capitalize',

          }}
        >
          Export
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ border: "none", boxShadow: "none" }}>
        {state.loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              zIndex: 1000,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Table>
          <TableHead>
            <TableRow>
              {['Status', 'Customer', 'VIN', 'Year', 'Model', 'Trim', 'Job', 'Priority', 'Assignment', 'Notes', 'Time Elapsed', ''].map((header) => (
                <TableCell key={header}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 6 }}>{header}</span>
                    {header && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <KeyboardArrowUpOutlinedIcon sx={{ fontSize: '12px' }} />
                      <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '12px', marginTop: "-6px" }} />
                    </div>}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.jobs.map(job => (
              <JobTableRow
                key={job?.jobId}
                job={job}
                expandedRows={expandedRows}
                handleExpandClick={handleExpandClick}
                issues={issues}
                handleStatusChange={handleStatusChange}
                getAllJobStatus={getAllStatus}
                setState={setState}
                handleJobDetails={handleJobDetails}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!state.loading && <PaginationControls
        rowsPerPage={state.rowsPerPage}
        handleRowsPerPageChange={handleRowsPerPageChange}
        totalPages={state.totalPages}
        currentPage={state.currentPage}
        handlePageChange={handlePageChange}
      />}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
        sx={{ '& .MuiPaper-root': { borderRadius: '8px', minWidth: '150px' } }}
      >
        {selectedFilter && filterOptions[selectedFilter].map((option) => (
          <MenuItem key={option} onClick={() => handleCheckboxToggle(selectedFilter, option)}>
            <Checkbox
              checked={state.selectedFilters[selectedFilter].includes(option)}
              sx={{
                '&.Mui-checked': {
                  color: '#00095B',
                },
                '& .MuiSvgIcon-root': {
                  borderRadius: '50%',
                }
              }}
            />
            {option}
          </MenuItem>
        ))}
      </Menu>

      <Snackbar
        open={state.statusUpdatePopUp}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() => setState(prev => ({ ...prev, statusUpdatePopUp: false }))}
      >
        <Alert severity="success" onClose={() => setState(prev => ({ ...prev, statusUpdatePopUp: false }))}>
          <AlertTitle>Success</AlertTitle>
          Status Updated Successfully
        </Alert>
      </Snackbar>
      <IssuesDrawer
        state={state}
        setState={setState}
        handleDrawerClose={handleDrawerClose}
        issueDetail={state.jobDetailsData}
        isIssuesLoaded={state.isIssuesLoaded}
        getAllJobStatus={getAllStatus}
      />
    </Container>
  );
}

export default JobList;