import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function StatusDonutChart({ data, width = 680, height = 600, innerRadius = 70, outerRadius = 150, margin = 20 }) {
  const svgRef = useRef();
  const totalAlerts = data?.statusMetrics.reduce((acc, booking) => acc + booking.count, 0);
  const pieData = data?.statusMetrics.map(({ statusName, count }) => ({
    status: statusName,
    count,
    percentage: ((count / totalAlerts) * 100).toFixed(1),
  }));

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const width = svgRef.current.clientWidth;
    const height = svgRef.current.clientHeight;

    const colorMapping = {
      Open: '#1f77b4',
      'In Progress': '#ff7f0e',
      'On Hold': '#2ca02c',
      Contacted: '#d62728',
      Scheduled: 'yellow',
      Unreachable: 'pink',
    };

    const pie = d3.pie()?.value(d => d.count);
    const arc = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    const outerArc = d3.arc()
      .innerRadius(outerRadius * 1.1)
      .outerRadius(outerRadius * 1.1);

    const arcs = svg.selectAll('.arc')
      .data(pie(pieData))
      .enter()
      .append('g')
      .attr('class', 'arc')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    arcs.append('path')
      .attr('d', arc)
      .attr('fill', d => colorMapping[d.data.status])
      .attr('stroke', 'white')
      .attr('stroke-width', '2px')
      .style('cursor', 'pointer')
      .transition()
      .duration(750)
      .attrTween('d', function (d) {
        const i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function (t) {
          d.endAngle = i(t);
          return arc(d);
        };
      });

    arcs.append('title')
      .text(d => `Status: ${d.data.status}, Count: ${d.data.count}, Percentage: ${d.data.percentage}`);

    const labelPositions = arcs.data().map(d => {
      const pos = outerArc.centroid(d);
      pos[0] = outerRadius * 1.25 * (d.endAngle + d.startAngle < Math.PI ? 1 : -1);
      return { data: d.data, position: pos, anchor: (d.endAngle + d.startAngle) < Math.PI ? 'start' : 'end' };
    });

    labelPositions.sort((a, b) => a.position[1] - b.position[1]);
    const padding = 20;
    for (let i = 1; i < labelPositions.length; i++) {
      if (labelPositions[i].position[1] - labelPositions[i - 1].position[1] < padding) {
        labelPositions[i].position[1] = labelPositions[i - 1].position[1] + padding;
      }
    }

    arcs.append('polyline')
      .attr('points', function (d, i) {
        const pos = labelPositions[i].position;
        return [arc.centroid(d), outerArc.centroid(d), pos];
      })
      .style('fill', 'none')
      .style('stroke', 'gray')
      .style('stroke-width', '1px');

    arcs.append('text')
      .attr('transform', (d, i) => `translate(${labelPositions[i].position})`)
      .attr('text-anchor', d => labelPositions.find(lp => lp.data === d.data).anchor)
      .style('fill', 'black')
      .style('font-size', '12px')
      .text(d => `${d.data.status} (${d.data.count}, ${d.data.percentage}%)`);

  }, [pieData, innerRadius, outerRadius]);

  return (
    <div>
      <svg ref={svgRef} width={width} height={height}></svg>
    </div>
  );
}

export default StatusDonutChart;
