// src/services/customerService.js
import axiosInstance from './axiosInstance';
import apiEndpoints from './apiEndpoints';
import { getRoleAndSiteCode } from '../utils/roleUtils';

const getCustomerAlerts = async (page, pageSize, filterParams, sortingParams) => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    paginationParams: {
      page,
      pageSize,
      filterRequest: {
        filterParams,
        filter: filterParams.length > 0 // Set filter to true if there are filters
      },
      sortingParams: sortingParams
    },
    appUserDetails: {
      role,
      siteCode
    }
  };

  const customerAlertsURL = `${process.env.REACT_APP_API_BASE_URL_V2_1}${apiEndpoints.CUSTOMERLIST}`;
  const response = await axiosInstance.post(customerAlertsURL, payload);
  return response.data;
};

const getFilterValues = async () => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    role,
    siteCode
  };

  const filterValuesURL = `${process.env.REACT_APP_API_BASE_URL_V2}${apiEndpoints.CUSTOMERFILTERVALUES}`;
  const response = await axiosInstance.post(filterValuesURL, payload);
  return response.data;
};

const getCustomerSuggestions = async (searchParam, filterParams) => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    searchParam,
    filterRequest: {
      filterParams,
      filter: filterParams.length > 0
    },
    appUserDetails: {
      role,
      siteCode
    }
  };

  const searchURL = `${process.env.REACT_APP_API_BASE_URL_V2_1}${apiEndpoints.CUSTOMERSEARCH}`;
  const response = await axiosInstance.post(searchURL, payload);
  return response.data;
};

const exportCustomers = async (page, pageSize, filterParams, sortingParams) => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    paginationParams: {
      page,
      pageSize,
      filterRequest: {
        filterParams,
        filter: filterParams.length > 0 // Set filter to true if there are filters
      },
      sortingParams: sortingParams
    },
    appUserDetails: {
      role,
      siteCode
    }
  };

  const customerExportURL = `${process.env.REACT_APP_API_BASE_URL_V2_1}${apiEndpoints.CUSTOMEREXPORT}`;
  const response = await axiosInstance.post(customerExportURL, payload);
  return response.data;
};

const getProfileDetails = async (vehicleProfileId) => {
  const response = await axiosInstance.get(`${apiEndpoints.CUSTOMERPROFILE}/${vehicleProfileId}`);
  return response.data;
};

const getAlertsDetails = async (id, isFromCustomerList) => {
  const payload = {
    activeAlertsTab: isFromCustomerList,
    id
  };

  const alertsDetailsURL = `${apiEndpoints.CUSTOMERALERTDETAIL}`;

  const response = await axiosInstance.post(alertsDetailsURL, payload);
  return response.data;
};

const getConnectedVehicleInfo = async (vehicleProfileId) => {
  const response = await axiosInstance.get(`${apiEndpoints.CUSTOMERCONNECTEDVEHICLE}/${vehicleProfileId}`);
  return response.data;
};

const getFordPassRewards = async (vehicleProfileId) => {
  const response = await axiosInstance.get(`${apiEndpoints.CUSTOMERFORDPASS}/${vehicleProfileId}`);
  return response.data;
};

const assignCustomer = async (customerId, assigneeId, assigneeName) => {
  const payload = {
    assigneeId,
    assigneeName
  };
  const response = await axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL_V2}${apiEndpoints.ACTIVEALERTSASSIGNEE}/${customerId}`, payload);
  return response.data;
};

const unassignCustomer = async (customerId, assigneeId) => {
  const payload = {
    assigneeId
  };
  const response = await axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL_V2}${apiEndpoints.ACTIVEALERTSUNASSIGNEE}/${customerId}`, payload);
  return response.data;
};

const getCustomerSearch = async (searchParam, filterParams) => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    searchParam,
    filterRequest: {
      filterParams,
      filter: filterParams.length > 0
    },
    appUserDetails: {
      role,
      siteCode
    }
  };

  const searchURL = `${process.env.REACT_APP_API_BASE_URL_V2_1}${apiEndpoints.ACTIVEALERTSEARCH}`;
  const response = await axiosInstance.post(searchURL, payload);
  return response.data;
};

const customerService = {
  getCustomerAlerts,
  getFilterValues,
  getCustomerSuggestions,
  exportCustomers,
  getProfileDetails,
  getAlertsDetails,
  getConnectedVehicleInfo,
  getFordPassRewards,
  assignCustomer,
  unassignCustomer,
  getCustomerSearch
};

export default customerService;
