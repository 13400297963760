import React, { useCallback } from "react";
import { Box, Button } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import Chip from "../../../components/Chip";
import { track } from "@amplitude/analytics-browser";

const CustomerFilterChips = ({ selectedFilters, setSelectedFilters, filterValues, globalCount }) => {
  const clearFilters = useCallback(() => {
    const valuesOnly = Object.fromEntries(
      Object.entries(selectedFilters).map(([key, value]) => [
        key,
        value.map(item => item.value),
      ])
    );

    track('Cleared All Filters', valuesOnly);

    setSelectedFilters({
      customerCategory: [],
      priority: [],
      alertType: [],
      alertStatus: [],
      vehicleMake: [],
      mra: [],
      vin: [],
      alertTitle: [],
      assignee: [],
    });

    globalCount.current = 1;
    localStorage.setItem('globalCount', globalCount.current);
  }, [selectedFilters, setSelectedFilters]);

  const handleFilterRemove = (filterKey, filterValue) => {
    track('Filter Removed', {
      filterKey: filterKey,
      value: filterValue
    });
    setSelectedFilters(prev => {
      if (filterKey === 'alertStatus') {
        let newValues;
        if (filterValue === 'allActive') {
          newValues = prev[filterKey].filter(item => !filterValues?.activeAlertStatus?.includes(item.value));
        } else if (filterValue === 'allInactive') {
          newValues = prev[filterKey].filter(item => !filterValues?.inActiveAlertStatus?.includes(item.value));
        } else {
          newValues = prev[filterKey].filter(item => item.value !== filterValue);
        }
        return { ...prev, [filterKey]: newValues };
      }
      return {
        ...prev,
        [filterKey]: prev[filterKey].filter(item => item.value !== filterValue)
      };
    });
  };

  const generateAllChips = () => {
    const allValuesWithPositions = Object.entries(selectedFilters).flatMap(([filterKey, values]) =>
      values.map(item => ({ ...item, filterKey }))
    );
    const sortedValues = allValuesWithPositions.sort((a, b) => a.position - b.position);
    const chips = [];
    sortedValues.forEach(({ filterKey, value }) => {
      let displayValue = value;

      if (filterKey === 'assignee') {
        const assignee = filterValues?.assignee?.find(a => a.assigneeId === value);
        displayValue = assignee ? assignee?.assigneeName : ''
      }else {
        displayValue = value;
      }
      chips.push({
        filterKey,
        value,
        displayValue,
      });
    });

    return chips;
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text?.slice(0, maxLength) + '...';
    }
    return text;
  };

  const allChips = generateAllChips();

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {allChips.map((chip) => (
        <Chip
          key={chip.filterKey + chip.value}
          value={truncateText(chip.displayValue, 25)} // Use displayValue for the chip text
          onDelete={() => handleFilterRemove(chip.filterKey, chip.value)}
        />
      ))}
      {allChips?.length >= 2 && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={clearFilters}
          style={{ border: "none", padding: "0", margin: "0 12px" }}
        >
          <CloseSharp style={{ color: "#0562D2", marginTop: "6px" }} />
          <span style={{ marginLeft: '2px', color: "#0562D2", fontWeight: "700", marginTop: "6px" }}>Clear All</span>
        </Button>
      )}
    </Box>
  );
};

export default CustomerFilterChips;
