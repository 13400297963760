// src/components/PriorityBadge.js
import React from 'react';

const getPriorityStyles = (priority) => {
  const styles = {
    Urgent: { dotColor: '#BF152C', bgColor: '#FFF1F1', borderColor: '#BF152C' },
    High: { dotColor: '#FDA74C', bgColor: '#FFF1E1', borderColor: '#FDA74C' },
    Medium: { dotColor: '#FF5AF8', bgColor: '#FFE3FE', borderColor: '#FF5AF8' },
    Low: { dotColor: '#793FFF', bgColor: '#CAC7FF', borderColor: '#793FFF' }
  };
  return styles[priority] || { dotColor: '#000', bgColor: '#f9f9f9', borderColor: '#8B0000' };
};

const PriorityBadge = ({ priority }) => {
  const { dotColor, bgColor, borderColor } = getPriorityStyles(priority);

  return (
    <span
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        padding: '2px 8px',
        backgroundColor: bgColor,
        borderRadius: '12px',
        border: `1px solid ${borderColor}`,
        fontFamily: 'Arial, sans-serif'
      }}
    >
      <span
        style={{
          display: 'inline-block',
          width: '8px',
          height: '8px',
          backgroundColor: dotColor,
          borderRadius: '50%',
          marginRight: '8px'
        }}
      ></span>
      {priority}
    </span>
  );
};

export default PriorityBadge;
