// src/utils/csrf.js
import axios from 'axios';
import { getToken } from '../utils/auth';

let csrfToken = null; // In-memory cache for CSRF token
let csrfTokenPromise = null; // To handle concurrent requests

export const getCsrfToken = async () => {
  if (csrfToken) {
    return csrfToken;
  }

  // If a request is already in progress, return the existing promise
  if (csrfTokenPromise) {
    /* istanbul ignore next */
    return csrfTokenPromise;
  }

  csrfTokenPromise = (async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/csrf-token`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      csrfToken = response.headers['x-csrf-token'];
      return csrfToken;
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
      return null;
    } finally {
      csrfTokenPromise = null; // Reset the promise
    }
  })();

  return csrfTokenPromise;
};
