import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import issueService from '../../../services/issueService';
import formatDateWithTimezone from '../../../utils/formatDateWithTimezone';

import { Button, Typography } from '@ford/ford-ui-components';
import { EditOutlined } from '@mui/icons-material';
import { TextField, Snackbar, Alert } from '@mui/material';
import { track } from "@amplitude/analytics-browser";

const DEFAULT_NOTE_TEXT = "Add a personal touch to your customer experience by keeping notes about this customer.";

const CustomerNote = ({
  customerDetails,
  canReadNotes,
  canWriteNotes,
  setIsApiError
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [customerNotes, setCustomerNotes] = useState({});
  const [notes, setNotes] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [customerUpdatedDate, setCustomerUpdatedDate] = useState('');
  const [notesLoaded, setNotesLoaded] = useState(false);

  const fetchCustomerNotes = useCallback( async() => {
    const vehicleProfileId = customerDetails?.vehicleInfo?.vehicleProfileId;
    if (!vehicleProfileId) return; // Ensure vehicleProfileId is defined

    try {
        const response = await issueService.getCustomerNote(vehicleProfileId);
        setCustomerNotes(response.data);
        setCustomerUpdatedDate(response.data.updatedAt);
        if (response.data.customerNotes) {
            setNotes(response.data.customerNotes);
        }
    } catch (error) {
      console.error(error);
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response ? error.response.status : null,
      });
    }
    finally {
      setNotesLoaded(true);
    }
  }, [customerDetails?.vehicleInfo?.vehicleProfileId]);

  /* istanbul ignore next */
  const handleEditClick = () => {
    track('Customer Note Edit Button Clicked');
    fetchCustomerNotes();
    setIsEditing(!isEditing);
    setNotes(customerNotes.customerNotes || '');
    setHasChanges(false);
  };

  /* istanbul ignore next */
  const handleCancel = () => {
    track('Customer Note Edit Cancelled');
    fetchCustomerNotes();
    setIsEditing(false);
    setNotes(customerNotes.customerNotes || '');
    setHasChanges(false);
  };

  /* istanbul ignore next */
  const handleNotesChange = (e) => {
    setNotes(e.target.value);
    setHasChanges(e.target.value !== customerNotes.customerNotes);
  };

  useEffect(() => {
    if (customerDetails?.vehicleInfo?.vehicleProfileId) {
      fetchCustomerNotes();
    }
  }, [customerDetails]);

  /* istanbul ignore next */
  const handleSaveNote = async () => {
    track('Customer Note Edited');
    setIsSaving(true);
    try {
      const noteData = {
        customerNotesId: customerDetails?.vehicleInfo?.vehicleProfileId,
        customerNotes: notes
      };
      const response = await issueService.updateCustomerNote(noteData);
      setCustomerNotes(noteData);
      setCustomerUpdatedDate(response.data);
      setIsEditing(false);
      setSnackbarMessage('Note saved successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error(error);
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response ? error.response.status : null,
      });
      setSnackbarMessage('Failed to save the note.');
      setSnackbarSeverity('error');
    } finally {
      setIsSaving(false);
      setSnackbarOpen(true);
    }
  };

  /* istanbul ignore next */
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      {canReadNotes && <div style={{ margin: "5px 50px 0 50px", padding: "15px" }}>
        <div className='customer-notes-heading-container'>
          <div>
            <Typography
              displayStyle='subtitle-regular'
              displayColor='text-onlight-moderate-default'
              spanProps={{ className: "customer-notes-heading" }}
            >
              Customer Notes
            </Typography>
            {!isEditing && (
              <div style={{ marginTop: "10px", width: "450px" }}>
                <Typography
                  displayColor="text-onlight-moderate-default"
                  displayStyle="body-1-regular"
                  spanProps={customerNotes.customerNotes ? { className: 'text-custom-color-default' } : { className: 'text-custom-color' }}
                >
                  {notesLoaded ? (customerNotes?.customerNotes || DEFAULT_NOTE_TEXT) : ''}
                </Typography>
              </div>
            )}
            {isEditing && (
              <div style={{ width: "450px", marginTop: "10px" }} mt={2}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={notes}
                  onChange={handleNotesChange}
                  placeholder={DEFAULT_NOTE_TEXT}
                  helperText={`${notes.length}/1000 characters`}
                  inputProps={{ maxLength: 1000 }}
                />
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem", gap: "1rem" }}>
                  <Button
                    variant="outlined"
                    sx={{ ml: 1, color: "#808080" }}
                    onClick={handleCancel}
                    disabled={isSaving}
                  >
                    Cancel
                  </Button>
                  {(customerDetails?.vehicleInfo?.vehicleProfileId !== null && customerDetails?.vehicleInfo?.vehicleProfileId !== undefined) && <Button
                    variant={hasChanges ? "filled" : "outlined"}
                    sx={{ ml: 1, color: "#808080" }}
                    onClick={handleSaveNote}
                    disabled={!hasChanges || isSaving}
                  >
                    Save
                  </Button>}
                </div>
              </div>
            )}
          </div>
          <div>
            {notes &&
              <div style={{
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '16px',
                color: '#808080',
              }}>
                {customerUpdatedDate ? formatDateWithTimezone(customerUpdatedDate): ''}
              </div>}
            {canWriteNotes && <Button
              className='customer-notes-edit'
              variant='outlined'
              onClick={handleEditClick}
            >
              <EditOutlined sx={{ color: "#0562D2" }} />
            </Button>}
          </div>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>}
    </>
  );
};

CustomerNote.propTypes = {
  customerDetails: PropTypes.object,
  canReadNotes: PropTypes.bool.isRequired,
  canWriteNotes: PropTypes.bool.isRequired,
};

export default CustomerNote;