import React from "react";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

const DetailItem = ({ label, value, fontWeight = "500" }) => (
  <>
    <Grid item sx={{ textAlign: "right", padding: "0px 15px" }} xs={4}>
      <Typography fontWeight={fontWeight} fontSize={"14px"} variant="body1">{label}</Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography sx={{ marginLeft: "5px" }} fontSize={"14px"} variant="body1">{value}</Typography>
    </Grid>
  </>
);

DetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  fontWeight: PropTypes.string.isRequired,
};

export default DetailItem;
