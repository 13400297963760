import { useState } from 'react';
import PropTypes from 'prop-types';
import { styles } from '../AIGuide.style';
import ThumbButton from './ThumbButton';
import FeedbackMessage from './FeedbackMessage';
import FeedbackForm from './FeedbackForm';
import { THUMB_DOWN_PATH, THUMB_UP_PATH } from '../../../utils/svgConstants';
import { track } from '@amplitude/analytics-browser';

/**
 * AnswerFeedback component - Main component that handles the like/dislike UI and logic
 */
const AnswerFeedback = ({
  answerId, // Unique ID for the answer being rated
  onFeedbackSubmit, // Function to handle feedback submission
  scrollToBottom = () => {} // Function to scroll to bottom of container
}) => {
  // State for tracking which thumb is active (if any)
  const [activeThumb, setActiveThumb] = useState(null); // 'up', 'down', or null

  // State for showing success message after positive feedback
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // State for showing feedback form after negative feedback
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  // Handle feedback form dismissal
  const handleDismiss = () => {
    setShowFeedbackForm(false);
    track('AI Query Feedback Button Clicked', {
      answerId,
      feedbackType: 'Thumbs Down Ignored'
    });
  };

  // Handle thumb button clicks
  const handleThumbClick = (type) => {
    // If clicking the same thumb, toggle it off
    if (activeThumb === type) {
      setActiveThumb(null);
      setShowSuccessMessage(false);
      setShowFeedbackForm(false);
      track('AI Query Feedback Button Clicked', {
        answerId,
        feedbackType: `Thumbs ${type.charAt(0).toUpperCase() + type.slice(1)} Toggled Off`
      });
      return;
    }

    setActiveThumb(type);

    if (type === 'up') {
      // Handle like
      setShowSuccessMessage(true);
      setShowFeedbackForm(false);

      track('AI Query Feedback Button Clicked', {
        answerId,
        feedbackType: 'Thumbs Up'
      });

      // Submit the positive feedback
      onFeedbackSubmit({
        answerId,
        rating: 'positive',
        feedback: null
      });
    } else {
      // Handle dislike - show feedback form
      setShowSuccessMessage(false);
      setShowFeedbackForm(true);

      // Track thumbs down immediately
      track('AI Query Feedback Button Clicked', {
        answerId,
        feedbackType: 'Thumbs Down'
      });

      // Scroll to bottom to make feedback form visible
      setTimeout(() => {
        scrollToBottom();
      }, 100); // Small delay to ensure DOM has updated
    }
  };

  // Handle feedback form submission
  const handleFeedbackSubmit = async (feedback) => {
    // Track when user submits text feedback
    track('AI Query Feedback Button Clicked', {
      answerId,
      feedbackType: 'Thumbs Down with Feedback'
    });

    await onFeedbackSubmit({
      answerId,
      rating: 'negative',
      feedback
    });

    // Show success message and hide form after submission
    setShowFeedbackForm(false);
    setShowSuccessMessage(true);
  };

  return (
    <div style={styles.feedbackControlsContainer}>
      <div style={styles.answerFeedbackButtonsContainer}>
        <ThumbButton
          icon={THUMB_UP_PATH}
          isUp={true}
          isActive={activeThumb === 'up'}
          onClick={() => handleThumbClick('up')}
        />
        <ThumbButton
          icon={THUMB_DOWN_PATH}
          isUp={false}
          isActive={activeThumb === 'down'}
          onClick={() => handleThumbClick('down')}
        />
      </div>

      {showSuccessMessage && <FeedbackMessage message='Thanks for your feedback!' />}

      {showFeedbackForm && (
        <FeedbackForm onSubmit={handleFeedbackSubmit} onDismiss={handleDismiss} />
      )}
    </div>
  );
};

AnswerFeedback.propTypes = {
  answerId: PropTypes.string.isRequired,
  onFeedbackSubmit: PropTypes.func.isRequired,
  scrollToBottom: PropTypes.func
};

export default AnswerFeedback;
