import React from "react";
import PropTypes from 'prop-types';
import { Icon } from "@ford/ford-ui-components";

const InfoBanner = ({ description, iconName }) => {
    return (
        <div className="info-banner">
            <div className="info-banner-icon">
                <Icon name={iconName} className="info" />
            </div>
            <div className="info-banner-description">
                {description}
            </div>
        </div>
    );
}

InfoBanner.propTypes = {
    description: PropTypes.node,
    iconName: PropTypes.string
}

export default InfoBanner;