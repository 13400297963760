// src/services/activityService.js
import axiosInstance from './axiosInstance';
import apiEndpoints from './apiEndpoints';
import { getUniqueName } from '../config/AuthenticatedRoute';

const getImpersonateData = async () => {
  const response = await axiosInstance.get(`${apiEndpoints.IMPERSONATE}`);
  return response.data;
};

const logActivity = async (role, action, impersonatedUserSiteCode, reason, isDealerImpersonate) => {
  const username = getUniqueName();
  const payload = {
    userId: username,
    role: role,
    action: action,
    reasonForLogin: reason,
    impersonatedUserSiteCode: impersonatedUserSiteCode,
    isDealerImpersonate: isDealerImpersonate,
    loginActivityTime: new Date().toISOString() // Set to current timestamp
  };
  const response = await axiosInstance.post(`${apiEndpoints.LOGUSERACTIVITY}`, payload);
  return response.data;
};

const activityService = {
  getImpersonateData,
  logActivity
};

export default activityService;
