import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styles } from '../AIGuide.style';
import { Button, Textarea } from '@ford/ford-ui-components';

/**
 * FeedbackForm component - Provides a text area for users to submit feedback
 */
const FeedbackForm = ({
  onSubmit,
  onDismiss = () => {},
  label = "What didn't you like about the response?",
  buttonText = 'Submit',
  dismissButtonText = 'Dismiss',
  placeholder = 'Please provide your feedback...'
}) => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!feedback.trim()) return;

    setIsSubmitting(true);
    try {
      await onSubmit(feedback);
      setFeedback('');
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add focus styles manually with an inline event handler
  const handleFocus = (e) => {
    e.target.style.outline = 'none';
    e.target.style.borderColor = '#0073EA';
    e.target.style.boxShadow = '0 0 0 2px rgba(0, 115, 234, 0.2)';
  };

  const handleBlur = (e) => {
    e.target.style.boxShadow = 'none';
    e.target.style.borderColor = '#ccc';
  };

  return (
    <div style={styles.feedbackFormContainer}>
      <label htmlFor='feedback-textarea' style={styles.textAreaLabel}>
        {label}
      </label>
      <Textarea
        id='feedback-textarea'
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        placeholder={placeholder}
        disabled={isSubmitting}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <div style={styles.feedbackButtonsContainer}>
        <Button onClick={onDismiss} variant="outlined">
          {dismissButtonText}
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!feedback.trim() || isSubmitting}
          variant="filled"
        >
          {isSubmitting ? 'Submitting...' : buttonText}
        </Button>
      </div>
    </div>
  );
};

FeedbackForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  dismissButtonText: PropTypes.string,
  placeholder: PropTypes.string
};

export default FeedbackForm;
