import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Box, Button, Menu, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CustomerFilterChips from './CustomerFilterChips/CustomerFilterChips';
import { track } from '@amplitude/analytics-browser';
import { hasActionAccess } from '../../utils/permissions';

const FilterSection = ({ filterValues, selectedFilters, setSelectedFilters, globalCount, roleConfig }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFilterKey, setCurrentFilterKey] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    return () => {
      localStorage.setItem('globalCount', globalCount.current);
    };
  }, []);

  const handleFilterMenuOpen = (event, filterKey) => {
    setAnchorEl(event.currentTarget);
    setCurrentFilterKey(filterKey);
    setIsMenuOpen(true);
  };

  /* istanbul ignore next */
  const handleFilterMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  /* istanbul ignore next */
  const handleFilterChange = useCallback((filterKey, value) => {
    setSelectedFilters(prev => {
      const currentIndex = prev[filterKey].findIndex(item => item.value === value);
      const newSelected = [...prev[filterKey]];

      if (currentIndex === -1) {
        newSelected.push({ value, position: globalCount.current++ });
        track('Filter Clicked', {
          filterKey: filterKey === 'mra' ? 'Mobile Service' : filterKey,
          value,
          action: 'selected',
        });
      } else {
        newSelected.splice(currentIndex, 1);
        if (globalCount.current > 1) {
          globalCount.current--;
        }
        track('Filter Clicked', {
          filterKey: filterKey === 'mra' ? 'Mobile Service' : filterKey,
          value,
          action: 'deselected',
        });
      }
      localStorage.setItem('selectedFilters', JSON.stringify({ ...prev, [filterKey]: newSelected }));
      localStorage.setItem('globalCount', globalCount.current);

      return { ...prev, [filterKey]: newSelected };
    });
  }, [setSelectedFilters]);
  
  const renderFilterDropdown = (label, filterKey, options) => {
    const hasFilterAccess = hasActionAccess(roleConfig, 'list_customer', `filter_by_${filterKey}`.toLowerCase());
    if (!hasFilterAccess) return null;

    const isOpen = isMenuOpen && currentFilterKey === filterKey;
    let filterCount = selectedFilters[filterKey]?.length > 0 ? `(${selectedFilters[filterKey].length})` : '';
    let menuHeadLabel = label + ' ' + filterCount;
    return (
      <Box sx={{ m: 1 }}>
        <Button
          variant="outlined"
          onClick={(event) => handleFilterMenuOpen(event, filterKey)}
          endIcon={isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          sx={{
            borderRadius: '50px',
            textTransform: 'capitalize',
            fontWeight: '700',
            border: "1px solid #000000"
          }}
        >
          {menuHeadLabel}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleFilterMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{
            borderRadius: '50px', // Reduce the border radius here
            width: 'auto', 
            minWidth: '200px', 
            maxWidth: '600px', 
          }}
        >
          {options.map((option) => (
            <MenuItem
              sx={{
                minWidth: '150px',
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                color: "#333333",
                margin: 0,
                padding: "4px 10px",
              }}
              key={option}
              value={option}
              onClick={() => handleFilterChange(filterKey, option)}
            >
              <Checkbox
                checked={selectedFilters[filterKey].some(item => item.value === option)}
                sx={{
                  color: "#808080",
                  '&.Mui-checked': {
                    color: "#0562D2",
                  },
                  margin:"2px",
                  padding: "4px",
                }}
              />
              <ListItemText
                primary={option}
                primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }}
              />
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  const renderAssigneeDropdown = (label, filterKey, options) => {
    const hasFilterAccess = hasActionAccess(roleConfig, 'list_customer', `filter_by_${filterKey}`.toLowerCase());
    if (!hasFilterAccess) return null;
  
    const isOpen = isMenuOpen && currentFilterKey === filterKey;
    let filterCount = selectedFilters[filterKey]?.length > 0 ? `(${selectedFilters[filterKey].length})` : '';
    let menuHeadLabel = label + ' ' + filterCount;
  
    return (
      <Box sx={{ m: 1 }}>
        <Button
          variant="outlined"
          onClick={(event) => handleFilterMenuOpen(event, filterKey)}
          endIcon={isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          sx={{
            borderRadius: '50px',
            textTransform: 'capitalize',
            fontWeight: '700',
            border: "1px solid #000000"
          }}
        >
          {menuHeadLabel}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleFilterMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{
            borderRadius: '50px',
            width: 'auto',
            minWidth: '200px',
            maxWidth: '600px',
          }}
        >
          {options.map((option) => (
            <MenuItem
              sx={{
                minWidth: '150px',
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                color: "#333333",
                margin: 0,
                padding: "4px 10px",
              }}
              key={option.assigneeId}
              value={option.assigneeId}
              onClick={() => handleFilterChange(filterKey, option.assigneeId)}
            >
              <Checkbox
                checked={selectedFilters[filterKey].some(item => item.value === option.assigneeId)}
                sx={{
                  color: "#808080",
                  '&.Mui-checked': {
                    color: "#0562D2",
                  },
                  margin: "2px",
                  padding: "4px",
                }}
              />
              <ListItemText
                primary={option.assigneeName}
                primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }}
              />
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  return (
    <Box sx={{ marginLeft: "-10px" }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {renderFilterDropdown('Make', 'vehicleMake', filterValues.vehicleMake || [])}
        {renderFilterDropdown('Mobile Service', 'mra', filterValues.mra || [])}
        {renderFilterDropdown('Priority', 'priority', filterValues.priority || [])}
        {renderFilterDropdown('Alert Type', 'alertType', filterValues.alertType || [])}
        {renderFilterDropdown('Alert Status', 'alertStatus', filterValues.activeAlertStatus || [])}
        {renderAssigneeDropdown('Assignee', 'assignee', filterValues.assignee || [])}
      </Box>
      <CustomerFilterChips
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        filterValues={filterValues}
        globalCount={globalCount}
        currentFilterKey={currentFilterKey}
      />
    </Box>
  );
};

export default FilterSection;