import React, { useState, useEffect } from 'react';
import moment from "moment";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Chip,
  TablePagination
} from '@mui/material';
import customerService from '../../services/customerService';
import PriorityBadge from '../../components/PriorityBadge';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  
  const fetchCustomerAlerts = async (page, pageSize) => {
    try {
      const response = await customerService.getCustomerAlerts(page + 1, pageSize); // Adjust for zero-based index
      setCustomers(response.data.customerBasedAlerts);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      setError('Failed to fetch customer alerts');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerAlerts(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container data-testid="jobs-list-container" maxWidth={false}>
      <Box sx={{ padding: "20px 0" }}>
        <Typography variant="h5" sx={{ fontSize: '20px', fontWeight: 400, lineHeight: '24px' }}><b>Customers</b> ({totalItems})</Typography>
      </Box>
      
      <TableContainer component={Paper}>
            <Table>
            <TableHead>
                <TableRow>
                <TableCell>Customer</TableCell>
                <TableCell>VIN</TableCell>
                <TableCell>Alerts</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {customers.map((customer) =>
                customer.alerts.map((alert, index) => (
                    <TableRow key={`${customer.vin}-${alert.id || index}`}>
                    {index === 0 ? (
                        <>
                        <TableCell>
                            <Chip label={customer.customerCategory || 'N/A'} />
                            {customer.customerName}
                        </TableCell>
                        <TableCell>{customer.vin}</TableCell>
                        </>
                    ) : (
                        <>
                        <TableCell />
                        <TableCell />
                        </>
                    )}
                    <TableCell>
                        <Chip label={alert.alertType} />
                        {alert.alertDetails}
                    </TableCell>
                    <TableCell>
                        <PriorityBadge priority={alert.severity} />
                    </TableCell>
                    <TableCell>{alert?.alertDatetime && alert.alertDatetime !== "NA" ? moment(alert.alertDatetime).format("MM-DD-YYYY") : "-"}</TableCell>
                    <TableCell>{alert.status}</TableCell>
                    </TableRow>
                ))
                )}
            </TableBody>
            </Table>
            <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            />
        </TableContainer>
    </Container>
  );
};

export default CustomerList;