// src/services/issueService.js
import axiosInstance from './axiosInstance';
import apiEndpoints from './apiEndpoints';
import { getRoleAndSiteCode } from '../utils/roleUtils';
import { getUniqueName } from '../config/AuthenticatedRoute';
import { getImpersonateData } from '../utils/impersonateUtils'; // Import the utility function
const impersonateData = getImpersonateData();

const getIssues = async (page, isFilter, filters, pageSize, sortCriteria, defaultSort) => {
  let { role, siteCode } = getRoleAndSiteCode();
  // Check for impersonate data
  if (impersonateData && impersonateData.isDealerImpersonate) {
    role = impersonateData.role;
    siteCode = impersonateData.siteCode;
  }
  const payload = {
    paginationParams: {
      page,
      pageSize,
      filterRequest: {
        filterParams: filters,
        filter: isFilter
      },
      sortingParams: {
        defaultSort,
        sortingKey: sortCriteria
      }
    },
    appUserDetails: {
      role,
      siteCode
    }
  };

  const response = await axiosInstance.post(apiEndpoints.ISSUELIST, payload);
  return response.data;
};

const getSearchVins = async (isFilter, filters, searchInput) => {
  let { role, siteCode } = getRoleAndSiteCode();
  // Check for impersonate data
  if (impersonateData && impersonateData.isDealerImpersonate) {
    role = impersonateData.role;
    siteCode = impersonateData.siteCode;
  }

  const payload = {
    searchParam: searchInput,
    filterRequest: {
      filterParams: filters,
      filter: isFilter
    },
    appUserDetails: {
      role,
      siteCode
    }
  };
  const response = await axiosInstance.post(apiEndpoints.SEARCHVIN, payload);
  return response.data;
};

const getExportData = async (page, pageSize, isFilter, filters, sortCriteria, defaultSort) => {
  let { role, siteCode } = getRoleAndSiteCode();
  // Check for impersonate data

  if (impersonateData && impersonateData.isDealerImpersonate) {
    role = impersonateData.role;
    siteCode = impersonateData.siteCode;
  }
  const payload = {
    paginationParams: {
      page: page,
      pageSize: pageSize,
      filterRequest: {
        filterParams: filters,
        filter: isFilter
      },
      sortingParams: {
        defaultSort,
        sortingKey: sortCriteria
      }
    },
    appUserDetails: {
      role: role,
      siteCode: siteCode
    }
  };

  const response = await axiosInstance.post(apiEndpoints.EXPORT, payload);
  return response.data;
};

const getFilterValues = async () => {
  let { role, siteCode } = getRoleAndSiteCode();
  // Check for impersonate data

  if (impersonateData && impersonateData.isDealerImpersonate) {
    role = impersonateData.role;
    siteCode = impersonateData.siteCode;
  }
  const payload = {
    role,
    siteCode
  };
  const response = await axiosInstance.post(apiEndpoints.FILTERVALUES, payload);
  return response.data;
};

const updateStatusValue = async (id, newStatus) => {
  const username = getUniqueName();
  const payload = {
    status: newStatus,
    updatedBy: username
  };
  const response = await axiosInstance.post(`${apiEndpoints.UPDATEISSUESSTATUS}/${id}`, payload);
  return response.data;
};

const getIssueDetail = async (id) => {
  const response = await axiosInstance.post(`${apiEndpoints.ISSUES}/${id}`);
  return response.data;
};

const getAllMetrics = async (siteCodes) => {
  const payload = {
    siteCodes
  };
  const response = await axiosInstance.post(`${apiEndpoints.METRICS}/getAll`, payload);
  return response.data;
};

const getAllSiteCodes = async () => {
  const response = await axiosInstance.get(`${apiEndpoints.DISTINCTROLES}`);
  return response.data;
};

const issueService = {
  getIssues,
  getSearchVins,
  getFilterValues,
  updateStatusValue,
  getIssueDetail,
  getExportData,
  getAllMetrics,
  getAllSiteCodes
};

export default issueService;
