// src/App.js
import React from 'react';
import theme from './config/theme';
import AppHeader from './components/AppHeader';
import GlobalStateProvider from './context/GlobalStateContext';
import AccessDenied from './components/AccessDenied';
import AppRoutes from './router/AppRoutes';
import LoginPage from './containers/Auth/LoginPage';
import UnauthorizedPage from './containers/Auth/UnauthorizedPage';
import { Box, ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RoleProvider } from './context/RoleContext';
import { Typography } from '@ford/ford-ui-components';
import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <RoleProvider>
          <GlobalStateProvider>
            <Routes>
              <Route path='/login' element={<LoginPage />} />
              <Route path='/cannot-determine-your-dealership' element={<AccessDenied
                title={`Can't Determine Your Dealership`}
                description={
                  <Typography displayStyle="body-1-regular'" displayColor="text-onlight-moderate-default">
                    It looks like you belong to multiple dealerships, and we’re unable to determine which dealership’s data to display. Please contact <Typography displayStyle='body-1-bold' displayColor='text-onlight-interactive'>vcare@ford.com</Typography> for assistance.
                  </Typography>
                }
              />} />
              <Route path='/dealership-access-required' element={<AccessDenied
                title={`Dealership Access Required`}
                description={
                  <Typography displayStyle="body-1-regular'" displayColor="text-onlight-moderate-default">
                    The Vehicle Care Portal centralizes your service customers’ vehicle data. Connect with your customers more effectively, identify service needs, and boost service efficiency — all in one place. Please contact <Typography displayStyle='body-1-bold' displayColor='text-onlight-interactive'>vcare@ford.com</Typography> for access.
                  </Typography>
                }
              />} />
              <Route path='/no-access' element={<AccessDenied
                title={`No Access`}
                description={
                  <Typography displayStyle="body-1-regular'" displayColor="text-onlight-moderate-default">
                    You need additional permissions to access the portal. Please contact <Typography displayStyle='body-1-bold' displayColor='text-onlight-interactive'>vcare@ford.com</Typography> for getting access.
                  </Typography>
                }
              />} />
              <Route path='/unauthorized' element={<UnauthorizedPage />} />
              <Route
                path='*'
                element={
                  <>
                    <AppHeader />
                    <Box sx={styles.container}>
                      <Box component={'main'} sx={styles.mainSection}>
                        <AppRoutes />
                      </Box>
                    </Box>
                  </>
                }
              />
            </Routes>
          </GlobalStateProvider>
        </RoleProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

/** @type {import("@mui/material").SxProps} */
const styles = {
  container: {
    display: 'flex',
    bgcolor: '#fff',
    height: '100vh'
  },
  mainSection: {
    p: 1,
    width: '100%',
    height: '100%',
    overflow: 'auto'
  }
};

export default App;
