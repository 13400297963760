import React from 'react';
import PropTypes from 'prop-types';
import { styles } from '../AIGuide.style';

/**
 * FeedbackMessage component - Displays a success message with a checkmark icon
 */
const FeedbackMessage = ({ message = 'Thanks for your feedback!' }) => {
  return (
    <div style={styles.feedbackMessageContainer}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="16" rx="8" fill="#008200"/>
        <path d="M12.7992 5.36078L12.2392 4.80078L6.39922 10.6408L3.75922 8.00078L3.19922 8.56078L6.39922 11.7608L12.7992 5.36078Z" fill="white" stroke="white"/>
      </svg>
      <span>{message}</span>
    </div>
  );
};

FeedbackMessage.propTypes = {
  message: PropTypes.string,
};

export default FeedbackMessage;
