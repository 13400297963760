import React, { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { Button } from '@ford/ford-ui-components';
import { FileDownloadOutlined } from '@mui/icons-material';
import customerService from '../../services/customerService';
import { exportToExcel } from '../../utils/exportToExcel';
import { capitalizeFirstWordAndFormat, titleCase } from '../../utils/stringUtils';
import moment from "moment";
import { track } from '@amplitude/analytics-browser';

const CustomerExport = ({ totalItems, selectedFilters, sortingParams, setIsApiError, loading }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [exporting, setExporting] = useState(false);

  const handleExport = async () => {
    setExporting(true);
    try {
      track('Export Clicked', {
        action: 'start',
      });
      // Show snackbar indicating export has started
      setSnackbarMessage('Export is started. The file will be downloaded in the background.');
      setSnackbarSeverity('info');
      setSnackbarOpen(true);

      const allCustomers = await fetchAllCustomers(totalItems, selectedFilters, sortingParams);
      const formattedCustomers = formatCustomersForExport(allCustomers);
      exportToExcel(formattedCustomers, 'CustomerAlertList');

      // Show snackbar indicating export was successful
      setSnackbarMessage('Export successful. The file has been downloaded.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      track('Export Clicked', {
        action: 'success',
      });
    } catch (error) {
      console.error("Error exporting customers:", error);
      setSnackbarMessage('Error during export. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setExporting(false);
    }
  };

  /* istanbul ignore next */
  const fetchAllCustomers = async (totalItems, selectedFilters, sortingParams) => {
    const filterParams = Object.entries(selectedFilters)
      .filter(([_, values]) => values.length > 0)
      .map(([filterKey, filterValues]) => ({ filterKey, filterValues: filterValues.map(item => item.value) }));

    const sortingKeys = {
      priority: '',
      alertDatetime: '',
      status: ''
    };
    const sortingPayload = sortingParams.sortingKey ? {
      defaultSort: sortingParams.direction === '',
      sortingKey: {
        ...sortingKeys,
        [sortingParams.sortingKey]: sortingParams.direction,
      },
    } : {
      defaultSort: true,
      sortingKey: sortingKeys,
    };
    let data;
    try {
      data = await customerService.exportCustomers(1, totalItems, filterParams, sortingPayload);
    } catch (error) {
      console.error("Error fetching customers:", error);
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response.status
      });
      setSnackbarMessage('Error fetching customers. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return [];
    }
    return data.data || [];
  };

  const formatCustomersForExport = (customers) => {
    return customers.map(customer => ({
      'Customer Name': customer.customerName,
      'Email Address': customer.emailAddress.toLowerCase(),
      'VIN': customer.vin.toUpperCase(),
      'Vehicle': `${customer.vehicle}`,
      'Alert Type': customer.alertType ? customer.alertType.toUpperCase() : '-',
      'Alert Description': customer.alertType === 'FSA'
        ? capitalizeFirstWordAndFormat(customer.alertDescription)
        : titleCase(customer.alertDescription),
      'System Status': customer.systemStatus,
      'Priority': customer.priority,
      'Mobile Service Assessment': customer?.mobileServiceAssessment,
      'Created On': moment(customer.createdOn).format('MM-DD-YYYY'),
      'Status': customer.status
    }));
  };

  const handleSnackbarClose = () => {
    /* istanbul ignore next */
    setSnackbarOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        leftIcon={<FileDownloadOutlined />}
        onClick={handleExport}
        disabled={loading || exporting}
      >
        Export
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomerExport;
