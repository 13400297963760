// src/utils/permissions.js
export const hasPageAccess = (roleConfig, page, accessType) => {
  const pagePermission = roleConfig?.permissions?.find((permission) => permission.page === page);
  if (!pagePermission) return false;
  return pagePermission.access.includes(accessType);
};

export const hasActionAccess = (roleConfig, page, action) => {
  const pagePermission = roleConfig?.permissions?.find((permission) => permission.page === page);
  if (!pagePermission) return false;
  const actionPermission = pagePermission.actions?.find((a) => a.action === action);
  return actionPermission && actionPermission.value === 'true';
};
