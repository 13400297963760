// src/services/roleService.js
import axiosInstance from './axiosInstance';
import apiEndpoints from './apiEndpoints';
import { getImpersonateData } from '../utils/impersonateUtils';
import { getCsrfToken } from '../utils/csrf'; 

const getRoleConfig = async (role, siteCode) => {
  // Check for impersonate data
  const impersonateData = JSON.parse(getImpersonateData());
  
  /* istanbul ignore next */
  if (impersonateData && impersonateData.isDealerImpersonate) {
    role = impersonateData.role;
    siteCode = impersonateData.siteCode;
  }

  const csrfToken = await getCsrfToken();
  if (!csrfToken) {
    console.error('CSRF token is not available. Aborting request to get role configuration.');
    return null;
  }
  const payload = {
    role: role,
    siteCode: siteCode
  };
  const response = await axiosInstance.post(`${apiEndpoints.ROLECONFIG}`, payload);
  return response.data;
};

const roleService = {
  getRoleConfig
};

export default roleService;
