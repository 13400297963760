import { createTheme } from '@mui/material';
import { green, grey } from '@mui/material/colors';

let theme = createTheme({
  palette: {
    primary: {
      main: grey[800],
      normal: grey[300]
    },
    secondary: {
      main: green[500]
    },
    neutral: {
      light: grey[50],
      medium: grey[200],
      normal: grey[700],
      main: grey[900]
    },
    green: {
      main: green[800]
    },
    sidenav: {
      main: '#4d4d4d'
    },
    background: {
      default: '#FFFFFF' // Set background to white
    }
  },
  typography: {
    fontFamily: [
      'ford f1 regular',
      'ford f1 bold',
      'ford f1 semibold',
      'ford f1 medium',
      'ford f1 light',
      // Fallback fonts
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    allVariants: {
      fontFamily: 'ford f1 regular', // Default to regular font
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: '0.8rem',
          fontWeight: 700,
          color: '#7C838D',
          fontFamily: 'ford f1 semibold', // Example: use semibold for headers
        },
        body: {
          fontSize: '0.8rem',
          fontWeight: 400,
          lineHeight: '20px',
          color: '#000000',
          fontFamily: 'ford f1 regular',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'ford f1 medium',
          color: '#212121'
        }
      }
    },
    // Add more component overrides as needed
  }
});

export default theme;
