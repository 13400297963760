/**
 * Converts a string to title case.
 * @param {string} str - The string to be converted.
 * @returns {string} - The converted title case string.
 */
export const titleCase = (str) => {
  if (!str) return '';
  return str
    ?.split(' ')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(' ');
};

export const capitalizeFirstWordAndFormat = (details) => {
  if (!details) return '';
  const words = details.split(' ');
  const firstWord = words[0].toUpperCase();
  const restOfWords = words
    .slice(1)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  return `${firstWord} ${restOfWords}`;
};

export const trimAndAppend = (details, maxLength) => {
  if (!details) return '';
  return details.length > maxLength ? details.slice(0, maxLength) + '...' : details;
};
