import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import roleService from '../services/roleService';
import { getRoleAndSiteCode } from '../utils/roleUtils';
import { isTokenValid } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';
const RoleContext = createContext();

export const encryptData = (data) => {
  // Implement your encryption logic here
  return btoa(JSON.stringify(data)); // Simple base64 encoding for illustration
};

export const decryptData = (data) => {
  // Implement your decryption logic here
  return JSON.parse(atob(data)); // Simple base64 decoding for illustration
};

export const RoleProvider = ({ children }) => {
  const [roleConfig, setRoleConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isApiError, setIsApiError] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchRoleConfig = async () => {
      const isAuthenticated = await isTokenValid(); 
      if (!isAuthenticated) {
        setLoading(false);
        return; // Exit early if not authenticated
      }
      // Check local storage for role config
      const storedRoleConfig = localStorage.getItem('role_config');
      if (storedRoleConfig) {
        const decryptedConfig = decryptData(storedRoleConfig);
        setRoleConfig(decryptedConfig);
        setLoading(false);
        return;
      }

      try {
        const { role, siteCode } = getRoleAndSiteCode();
        const response = await roleService.getRoleConfig(role, siteCode);

        if (response && response.data) {
          setRoleConfig(response.data);

          // Encrypt and store the role config in local storage
          const encryptedConfig = encryptData(response.data);
          localStorage.setItem('role_config', encryptedConfig);
        } else {
          console.error('No role config data received.');
        }
      } catch (error) {
        console.error('Failed to fetch role config:', error);
        setIsApiError({
          isError: true,
          message: error?.message,
          code: error?.response?.status
      })
      } finally {
        setLoading(false);
      }
    };

    fetchRoleConfig();
  }, []);

  const contextValue = useMemo(() => ({ roleConfig, loading }), [roleConfig, loading]);
  if (isApiError?.code === 403) {
    navigate(`/dealership-access-required`);
  }

  if (isApiError?.code === 500 || isApiError?.code === 400) {
    return <ErrorPage statusCode={isApiError.code} message={isApiError.message} />;
  }

  return <RoleContext.Provider value={contextValue}>{children}</RoleContext.Provider>;
};

export const useRole = () => useContext(RoleContext);