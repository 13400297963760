// src/services/customerService.js
import axiosInstance from './axiosInstance';
import apiEndpoints from './apiEndpoints';
import { getRoleAndSiteCode } from '../utils/roleUtils';
import { getImpersonateData } from '../utils/impersonateUtils';

const impersonateData = getImpersonateData();

const getCustomerAlerts = async (page, pageSize) => {
  let { role, siteCode } = getRoleAndSiteCode();
  if (impersonateData && impersonateData.isDealerImpersonate) {
    role = impersonateData.role;
    siteCode = impersonateData.siteCode;
  }
  const payload = {
    paginationParams: {
      page,
      pageSize,
      filterRequest: {
        filterParams: [],
        filter: false
      },
      sortingParams: {
        defaultSort: true,
        sortingKey: {
          additionalProp1: 'string',
          additionalProp2: 'string',
          additionalProp3: 'string'
        }
      }
    },
    appUserDetails: {
      role,
      siteCode
    }
  };

  const customerAlertsURL = `${process.env.REACT_APP_API_BASE_URL_V2}${apiEndpoints.CUSTOMERLIST}`;
  const response = await axiosInstance.post(customerAlertsURL, payload);
  return response.data;
};

const customerService = {
  getCustomerAlerts
};

export default customerService;
