export const styles = {
  headerText: {
    flex: 1,
    alignItems: 'self-start',
    width: '320px',
    padding: '0 30px'
  },
  stepText: {
    color: '#333',
    fontFamily: 'ford f1 regular',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '36px'
  },
  tabHighlight: {
    paddingLeft: '4px',
    justifyContent: 'start',
    width: '360px',
    overflow: 'hidden',
    textTransform: 'none',
    color: '#666',
    textOverflow: 'ellipsis',
    fontFamily: 'ford f1 bold',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    '&.Mui-selected': {
      color: '#0562D2'
    }
  },
  textField: {
    marginTop: '16px',
    width: '100%'
  },
  aiChatSVG: {
    backgroundImage: `url('ai-chat.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '96% 50%'
  },
  modelInput: {
    marginRight: 1,
    minWidth: 350,
    marginTop: '16px'
  },
  yearInput: {
    marginLeft: 1,
    minWidth: 350,
    marginTop: '16px'
  },
  aiGuideAvailability: {
    color: '#333',
    fontFamily: 'ford f1 regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px'
  },
  fordSupport: {
    color: '#0562D2',
    fontFamily: 'ford f1 semibold',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationSkipInk: 'auto',
    textDecorationThickness: 'auto',
    textUnderlineOffset: 'auto',
    textUnderlinePosition: 'from-font',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  questionText: {
    color: '#333',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '20px',
    fontFamily: 'ford f1 regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    padding: '10px 20px'
  },
  askText: {
    color: '#666',
    textAlign: 'center',
    fontFamily: 'ford f1 regular',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px'
  },
  rowFlex: {
    display: 'flex',
    flexDirection: 'row'
  },
  modelHeadText: {
    color: '#333',
    fontFamily: 'ford f1 regular',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '36px'
  },
  changeVehicleText: {
    color: '#0562D2',
    textAlign: 'center',
    fontFamily: 'ford f1 bold',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    marginTop: '2px'
  },

  // ThumbButton styles
  thumbButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  // FeedbackControls styles
  feedbackControlsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },

  feedbackButtonsContainer: {
    display: 'flex',
    marginTop: '10px',
    gap: '16px'
  },

  answerFeedbackButtonsContainer: {
    display: 'flex',
    marginTop: '10px',
  },

  // Feedback message styles
  feedbackMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '8px',
    color: '#0073EA',
    fontSize: '14px'
  },

  // FeedbackForm styles
  feedbackFormContainer: {
    marginTop: '10px'
  },

  textAreaLabel: {
    display: 'block',
    marginBottom: '8px',
    fontSize: '14px',
    color: '#333'
  },

  // Additional hover styles need to be applied via JavaScript since inline styles
  // don't support pseudo-classes
  thumbButtonHover: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)'
  }
};
