import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function LogoutCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear any remaining session or local storage data if needed
    localStorage.clear();
    sessionStorage.clear();

    // Redirect to login page
    navigate('/login');
  }, [navigate]);

  return null; // You can return a loading spinner or message if you want
}

export default LogoutCallback;
